import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Col, Row, Badge } from "react-bootstrap";
import { Link } from 'react-router-dom';
import styles from "./CausaContext.module.css";
import { useKeycloak } from "@react-keycloak/web";
import { useParams } from 'react-router-dom';
import Information from "./Information/Information";
import UnidadFiscal from "./UnidadFiscal/UnidadFiscal";
import { CauseStatus } from "../../../constants/CauseStatus";
import { Complexity } from "../../../constants/Complexity";
import History from "./History";
import Disassociate from "./Disassociate";

const CausaContext = () => {

    const { keycloak } = useKeycloak();

    let { id } = useParams();

    const [ cause, setCause ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ menu, setMenu ] = useState("information");

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }

    const findCause = () => {
        setLoading(true);
        const URL = `${process.env.REACT_APP_API_URL}/causes/${id}`;
        axios.get(URL, prepareHeaders())
        .then(res => {
            setCause(res.status == 200 ? res.data : undefined);
            setLoading(false);
        })
        .catch(err => {
            setCause(undefined);
            setLoading(false);
        });
    }

    useEffect(() => { if(id) findCause() }, [id]);
    
    return cause ? (
        <>
            <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
                <Col md={2}>
                    <div className={styles.sideBar}>
                        <Form className={styles.filterContainer}>
                            <Row>
                                <Col style={{ textAlign: "right" }}>
                                    <Form.Label className={styles.id}>{`Nº ${cause.id}`}</Form.Label>
                                </Col>
                            </Row>
                            {
                                cause.title ? (
                                    <Row>
                                        <Col style={{ textAlign: "center" }}>
                                            <Form.Label className={styles.title}>{cause.title}</Form.Label>
                                        </Col>
                                    </Row>
                                )
                                : <></>
                            }                       
                            {
                                cause.principalProsecutor ? (
                                    <>
                                        <Row>
                                            <Col style={{ textAlign: "center" }}>
                                                <Form.Label className={styles.tinyTitle}>Asignada a</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: "center" }}>
                                                <Form.Label className={styles.tinyValue}>{`${cause.principalProsecutor.firstName} ${cause.principalProsecutor.lastName}`}</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: "center" }}>
                                                <Form.Label className={styles.tinyTitle}>Asignada el</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: "center" }}>
                                                <Form.Label className={styles.tinyValue}>{`${cause.createdAt}`}</Form.Label>
                                            </Col>
                                        </Row>                                                                                
                                    </>
                                )
                                : 
                                (
                                    <Row>
                                        <Col style={{ textAlign: "center" }}>
                                            <Form.Label className={styles.notAssigned}>Pendiente de asignación de fiscal</Form.Label>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <Form.Label className={styles.status}>{`Causa ${CauseStatus[cause.status].otherName}`}</Form.Label>
                                </Col>
                            </Row>                                 
                            {
                                cause.causeSeverityLevel ? (
                                    <Row>
                                        <Col style={{ textAlign: "center" }}>
                                            <Badge pill bg={cause.causeSeverityLevel === "LOW" ? "success" : (cause.causeSeverityLevel === "MEDIUM" ? "warning" : "danger")}>{`Complejidad ${Complexity[cause.causeSeverityLevel].name}`}</Badge>
                                        </Col>
                                    </Row>
                                )
                                : 
                                <>
                                </>
                            }
                        </Form>
                        <Link className={`${styles.button} ${menu === 'information' ? styles.active : ''}`} onClick={(e) => { setMenu("information"); }}>
                            <span>Información</span>
                        </Link>
                        <Link className={`${styles.button} ${menu === 'team' ? styles.active : ''}`} onClick={(e) => { setMenu("team"); }}> 
                            <span>Unidad fiscal</span>
                        </Link>
                        <Link className={`${styles.button} ${menu === 'history' ? styles.active : ''}`} onClick={(e) => { setMenu("history"); }}> 
                            <span>Historial</span>
                        </Link>
                        <Link className={`${styles.button} ${menu === 'disassociate' ? styles.active : ''}`} onClick={(e) => { setMenu("disassociate"); }}> 
                            <span>Inhibición</span>
                        </Link>                        
                    </div>
                </Col>
                <Col md={10}>
                    <div className={styles.container}>
                        {
                            menu === "information" ? <Information cause={cause} callback={() => findCause()}/> : <></>
                        }
                        {
                            menu === "team" ? <UnidadFiscal cause={cause} callback={() => findCause()}/> : <></>
                        }
                        {
                            menu === "history" ? <History cause={cause} callback={() => findCause()} /> : <></>
                        }
                        {
                            menu === "disassociate" ? <Disassociate cause={cause} callback={() => findCause()} /> : <></>
                        }                        
                    </div>                    
                </Col>
            </Row>
        </>
    )
    : <></>
}

export default CausaContext;
  