import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Table } from "react-bootstrap";
import { AiFillDelete, AiOutlineUser } from "react-icons/ai";
import styles from "./Organizations.module.css";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import OrganizationModal from "./OrganizationModal/OrganizationModal";
import UsersModal from "./UsersModal";

const Organizations = () => {

    const { keycloak } = useKeycloak();

    const [ loading, setLoading ] = useState(false);
    const [ organizations, setOrganizations ] = useState([]);
    const [ showOrganizationModal, setShowOrganizationModal ] = useState(false);

    const [ organization, setOrganization ] = useState(undefined);
    const [ showUsersModal, setShowUsersModal ] = useState(false);
    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }

    const findOrganizations = () => {
        setLoading(true);
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/organizations`, prepareHeaders()).
            then(res => {
                setOrganizations(res.status == 200 ? res.data : []);
                setLoading(false);
            }).
            catch(err => {
                setLoading(false);
                setOrganizations([]);
            })
        }
        catch(err){
            setLoading(false);
            setOrganizations([]);
        }
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        setShowOrganizationModal(false);
    }

    const handleCloseUsersModal = (e) => {
        e.preventDefault();
        setShowUsersModal(false);        
    }

    const registerCallback = () => {
        setShowOrganizationModal(false);
        findOrganizations();
    }

    const showUsers = (e, organization) => {
        e.preventDefault();
        setOrganization(organization);
        setShowUsersModal(true);
    }

    const deleteOrganization = (e, id) => {
        e.preventDefault();
        try {
            axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${id}`, prepareHeaders()).
            then(res => {
                toast.success("Organización eliminada exitosamente");
                findOrganizations();
            }).
            catch(err => {
                toast.error("Ha ocurrido un error al eliminar la organización");
            });
        }
        catch(err){
            toast.error("Ha ocurrido un error al eliminar la organización");
        }
    }

    useEffect(() => { findOrganizations(); }, []);

    return (
        <>
            <Toaster />
            <UsersModal show={showUsersModal} handleClose={handleCloseUsersModal} organization={organization} />
            <OrganizationModal show={showOrganizationModal} handleClose={handleCloseModal} callback={registerCallback} />
            <Form>
                <Form.Group as={Col}>
                    <Form.Label className={styles.groupTitle}>
                        <Row>
                            <Col md="auto">
                                <span>Organizaciones</span>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                                <Button onClick={(e) => { e.preventDefault(); setShowOrganizationModal(true); }} className={styles.add}>Nueva organización</Button>
                            </Col>
                        </Row>
                    </Form.Label>
                    {
                        (organizations && organizations.length > 0) ? (
                            <>
                                <Table>
                                    <thead>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th>Nombre</th>
                                        <th style={{ textAlign: "center" }}>Creado el</th>
                                        <th>Creado por</th>                                        
                                        <th style={{ textAlign: "center" }}>Actualizado el</th>
                                        <th>Actualizado por</th>
                                        <th style={{ textAlign: "center" }}>Acciones</th>
                                    </thead>
                                    <tbody>
                                        {
                                            organizations.map(organization => (
                                                <tr>
                                                    <td>{organization.id}</td>
                                                    <td>{organization.name}</td>
                                                    <td style={{ textAlign: "center" }}>{organization.createdAt}</td>
                                                    <td>{`${organization.createdBy.firstName} ${organization.createdBy.lastName}`}</td>
                                                    <td style={{ textAlign: "center" }}>{organization.updatedAt}</td>
                                                    <td>{`${organization.updatedBy?.firstName??""} ${organization.updatedBy?.lastName??""}`}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <AiOutlineUser onClick={(e) => { showUsers(e, organization); }} style={{ cursor: "pointer" }} title="Usuarios"/>&nbsp;
                                                        <AiFillDelete style={{ cursor: "pointer" }} title="Eliminar" onClick={(e) => { deleteOrganization(e, organization.id); }} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </>
                        )
                        :
                        <></>
                    }
                </Form.Group>
            </Form>
        </>
    );

};

export default Organizations;