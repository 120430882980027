import React from "react";
import { Row, Col, Form, Table, Badge } from "react-bootstrap";
import styles from "./GenericDetail.module.css";

const GenericDetail = ({ detail, title }) => {

    return detail ? (
        <Row style={{ marginBottom: "1rem"}}>
            <Form.Label className={styles.groupLabel} as={Col}>{title}</Form.Label>
            <Col md={12}>
                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Motivo</th>
                            <th>Archivos adjuntos</th>
                            <th>Creado el</th>
                            <th>Creado por</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{detail.observation}</td>
                            <td>
                                {
                                    (detail.attachments && detail.attachments.length > 0)
                                    ?
                                    (
                                        <>
                                        {
                                            detail.attachments.map((attachment) => (
                                                <>
                                                    <Badge bg="secondary">{attachment.fileName}</Badge>&nbsp;
                                                </>
                                            ))
                                        }
                                        </>
                                    )
                                    : 
                                    <>-</>
                                }
                            </td>
                            <td>{detail.createdAt}</td> 
                            <td>{`${detail.createdBy.firstName} ${detail.createdBy.lastName}`}</td> 
                        </tr>
                    </tbody>
                </Table>
            </Col>                                                    
        </Row>
    )
    : <></>;

};

export default GenericDetail;