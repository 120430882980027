import React from "react";
import { Row, Form, Col } from "react-bootstrap"
import styles from "./TimeFilter.module.css";

const TimeFilter = ({ rangeType, setRangeType, year, setYear, month, setMonth, startDate, setStartDate, endDate, setEndDate }) => {

    return (
        <Row style={{ justifyContent: "right" }}>
            <Form.Group as={Col} md="auto">
                <Form.Label className={styles.label} as={Col}>Tipo de filtro</Form.Label>
                <Form.Control as="select" className={styles.input} value={rangeType} onChange={(e) => setRangeType(e.target.value)}>
                    <option value="year">Año específico</option>
                    <option value="month">Mes específico</option>
                    <option value="custom">Rango de fechas</option>
                </Form.Control>
            </Form.Group>


            {rangeType === "year" && (
                <Form.Group as={Col} md="auto">
                    <Form.Label className={styles.label} as={Col}>Año</Form.Label>
                    <Form.Control className={styles.input} type="number" value={year} onChange={(e) => setYear(e.target.value)} />
                </Form.Group>
            )}

            {rangeType === "month" && (
                <>
                    <Form.Group as={Col} md="auto">
                        <Form.Label className={styles.label} as={Col}>Mes</Form.Label>
                        <Form.Control as="select" className={styles.input} value={month} onChange={(e) => setMonth(e.target.value)}>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="auto">
                        <Form.Label className={styles.label} as={Col}>Año</Form.Label>
                        <Form.Control className={styles.input} type="number" value={year} onChange={(e) => setYear(e.target.value)} />
                    </Form.Group>
                </>
            )}

            {rangeType === "custom" && (
                <>
                    <Form.Group as={Col} md="auto">
                        <Form.Label className={styles.label} as={Col}>Fecha inicio</Form.Label>
                        <Form.Control className={styles.input} type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="auto">
                        <Form.Label className={styles.label} as={Col}>Fecha fin</Form.Label>
                        <Form.Control className={styles.input} type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>
                </>
            )}
        </Row>
    );

}

export default TimeFilter;