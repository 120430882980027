import React, { useState } from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss"
import DocumentType from "../../../../constants/DocumentType";
import ComplainantModal from "../../../../components/modals/ComplainantModal";

const Step1 = ( { next, data } ) => {

    const [ showComplainant, setShowComplainant ] = useState(false);
    const [ currentComplainant, setCurrentComplainant ] = useState(undefined);
   
    return (
        <>
            <ComplainantModal data={currentComplainant} show={showComplainant} handleClose={() => setShowComplainant(false)}/>
            <Form>
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Datos generales</Form.Label>
                    <Row style={{ marginTop: "0.2rem" }}>
                        <Col style={{ marginBottom: ".5rem" }}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Tipo de denuncia</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.type} disabled/>
                        </Col>
                        <Col style={{ marginBottom: ".5rem" }}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Reservada</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.reserved ? "Sí" : "No"} disabled />
                        </Col>
                        <Col style={{ marginBottom: ".5rem" }}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Fecha y hora de recepción</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="date-time" value={data.createdAt} disabled />
                        </Col>
                        <Col style={{ marginBottom: ".5rem" }}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Recibida por</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.createdBy.firstName + " " + data.createdBy.lastName} disabled />
                        </Col>                                         
                    </Row>
                    {
                        data.causeReference || data.resolution ? (
                            <Row className="mt-2">
                                {
                                    data.causeReference ? (
                                        <Col>
                                            <Form.Label as={Col} bsPrefix={styles.label}>Nro. SIGEDE</Form.Label>
                                            <Form.Control bsPrefix={styles.input} type="text" value={data.causeReference} disabled />
                                        </Col>
                                    )
                                    : 
                                    <></>
                                }
                                {
                                    data.resolution ? (
                                        <>
                                            <Col>
                                                <Form.Label as={Col} bsPrefix={styles.label}>Número de resolución</Form.Label>
                                                <Form.Control bsPrefix={styles.input} type="text" value={data.resolution.number} disabled />                                        
                                            </Col>                                        
                                            <Col md={4}>
                                                <Form.Label as={Col} bsPrefix={styles.label}>Descripción</Form.Label>
                                                <Form.Control bsPrefix={styles.input} type="text" value={data.resolution.description} disabled />                                        
                                            </Col>
                                            {
                                                data.prosecutor ? (
                                                    <Col>                            
                                                        <Form.Label bsPrefix={styles.label} as={Col}>Fiscal asignado</Form.Label>
                                                        <Form.Control bsPrefix={styles.input} value={data.prosecutor.firstName + " " + data.prosecutor.lastName} disabled />
                                                    </Col>                                             
                                                )
                                                :
                                                <></>
                                            }
                                            {
                                                data.resolution.file ? (
                                                    <Col>                            
                                                        <Form.Label bsPrefix={styles.label} as={Col}>Resolución adjunta</Form.Label>
                                                        <a download={data.resolution.file.fileName} href={data.resolution.file.payload}>
                                                            <Badge bg="secondary">{data.resolution.file.fileName}</Badge>
                                                        </a>
                                                    </Col>                                                           
                                                )
                                                :
                                                <></>
                                            }
                                        </>
                                    )
                                    :
                                    <></>
                                }
                            </Row>
                        )
                        : 
                        <></>
                    }
                </Form.Group>
                {
                    data.transferDetail ? (
                        <Form.Group className={styles.group}>
                            <Form.Label className={styles.groupLabel}>Datos de la transferencia</Form.Label>            
                            <Row className="mt-2">
                                <Col>
                                    <Form.Label as={Col} bsPrefix={styles.label}>Motivo</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="text" value={data.transferDetail.reason} disabled />
                                </Col>
                                <Col>
                                    <Form.Label as={Col} bsPrefix={styles.label}>Lugar</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="text" value={data.transferDetail.place} disabled />
                                </Col>
                                <Col>
                                    <Form.Label as={Col} bsPrefix={styles.label}>Transferida el</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="text" value={data.transferDetail.createdAt} disabled />
                                </Col>
                                <Col>
                                    <Form.Label as={Col} bsPrefix={styles.label}>Transferida por</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="text" value={data.transferDetail.createdBy.firstName + " " + data.transferDetail.createdBy.lastName} disabled />
                                </Col>                                
                            </Row>
                        </Form.Group>
                    )
                    : 
                    <></>
                }                
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Denunciantes</Form.Label>
                    {
                        data.complainants && data.complainants.length > 0 ? (
                            <>
                                {
                                    (data.complainants && data.complainants.filter(complaint => complaint.type == undefined || complaint.type == "PF").length > 0) ? (
                                        <Col md={12}>
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>Nombres y apellidos</th>
                                                        <th>Tipo de documento</th>
                                                        <th>Número de documento</th>
                                                        <th>Fecha de nacimiento</th>
                                                        <th>Departamento</th>
                                                        <th>Ciudad</th>
                                                        <th>Barrio</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.complainants.filter(complaint => complaint.type == undefined || complaint.type == "PF").map((complainant) => (
                                                            <tr>
                                                                <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                                <td>{complainant.document.type ? DocumentType[complainant.document.type].description : "-"}</td>
                                                                <td>{complainant.document.number}</td>
                                                                <td>{complainant.birthDate}</td>
                                                                <td>{complainant.address.department ? complainant.address.department.name : "-"}</td>
                                                                <td>{complainant.address.city ? complainant.address.city.name : "-"}</td>
                                                                <td>{complainant.address.neighborhood ? complainant.address.neighborhood.name : "-"}</td>                                                    
                                                                <td align="center">
                                                                    <Button onClick={(e) => { e.preventDefault(); setCurrentComplainant(complainant); setShowComplainant(true); }} bsPrefix={styles.iconBtn}>Ver</Button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>                        
                                    )
                                    : <></>
                                }
                                {
                                    (data.complainants && data.complainants.filter(complaint => complaint.type != undefined && complaint.type == "IE").length > 0) ? (
                                        <Col md={12}>
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>Institución del estado</th>
                                                        <th>Nombres y apellidos</th>
                                                        <th>Tipo de documento</th>
                                                        <th>Número de documento</th>
                                                        <th>Cargo</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.complainants.filter(complaint => complaint.type == "IE").map((complainant) => (
                                                            <tr>
                                                                <td>{complainant.stateInstitution.name}</td>
                                                                <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                                <td>{complainant.document.type ? DocumentType[complainant.document.type].description : "-"}</td>
                                                                <td>{complainant.document.number}</td>
                                                                <td>{complainant.title}</td>                              
                                                                <td align="center"><Button onClick={(e) => { e.preventDefault(); setCurrentComplainant(complainant); setShowComplainant(true); }} bsPrefix={styles.iconBtn}>Ver</Button></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>                        
                                    )
                                    : <></>
                                }
                                {
                                    (data.complainants && data.complainants.filter(complaint => complaint.type != undefined && complaint.type == "IP").length > 0) ? (
                                        <Col md={12}>
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>Institución privada</th>
                                                        <th>Nombres y apellidos</th>
                                                        <th>Tipo de documento</th>
                                                        <th>Número de documento</th>
                                                        <th>Fecha de nacimiento</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.complainants.filter(complaint => complaint.type == "IP").map((complainant) => (
                                                            <tr>
                                                                <td>{complainant.privateInstitution}</td>
                                                                <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                                <td>{complainant.document.type ? DocumentType[complainant.document.type].description : "-"}</td>
                                                                <td>{complainant.document.number}</td>
                                                                <td>{complainant.birthDate}</td>
                                                                <td align="center">
                                                                    <Button onClick={(e) => { e.preventDefault(); setCurrentComplainant(complainant); setShowComplainant(true); }} bsPrefix={styles.iconBtn}>Ver</Button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>                        
                                    )
                                    : <></>
                                }
                            </>
                        )
                        : 
                        <Row>
                            <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                        </Row>
                    }
                </Form.Group>
            </Form>
        </>
    );

}

export default Step1;