export const ComplainantType = {
    "PF": {
        name: "Ciudadano"
    },
    "IE": {
        name: "Institución del Estado"
    },
    "IP": {
        name: "Institución privada"
    }
};