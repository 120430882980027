import React, { useState, useEffect } from "react";
import styles from "./DisassociateModal.module.css";
import { Modal, Button, Form, Row, Col, Badge } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const DisassociateModal = ( { show, handleClose, cause, toast, callback } ) => {

    const [ loading, setLoading ] = useState(false);
    const [ reason, setReason ] = useState(undefined);
    const [ files, setFiles ] = useState(undefined);
    const [ blobFiles, setBlobFiles ] = useState(undefined);
    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }         

    const handleConfirm = async (event) => {
        event.preventDefault();
        setLoading(true);
        let builded = await build();
        axios.post(`${process.env.REACT_APP_API_URL}/causes/${cause.id}/disassociates`, builded, prepareHeaders())
        .then(res => {
            toast.success("Solicitud de inhibición realizada exitosamente");
            setLoading(false);
            callback();
            handleClose();
        })
        .catch(err => {
            toast.error("Ha ocurrido un error al realizar la solicitud: " + err.data ? (": " + err.data.message) : "", { duration: "5000", position: "top-center"});                        
            setLoading(false);
        });
    }

    const build = async () => {
        return {
            attachments: (files == null || files.length == 0) ? undefined : await buildAttachments(),
            reason
        };
    }    

    const buildAttachments = async () => {
        const promises = files.map((file, i) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    resolve({
                        fileName: blobFiles[i].name,
                        payload: reader.result
                    });
                };
                reader.readAsDataURL(blobFiles[i]);
            });
        });
        return await Promise.all(promises);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title><h5>Datos de la inhibición</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Motivo</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ingrese el motivo"
                                className={styles.input} 
                                value={reason} 
                                onChange={(e) => { setReason(e.target.value); }}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Archivos adjuntos</Form.Label>
                            <Form.Control 
                                type="file" 
                                multiple
                                placeholder="Ingrese los archivos"
                                className={styles.input}
                                onChange={(e) => { setFiles(Array.from(e.target.files)); setBlobFiles(e.target.files); }}
                            />
                        </Form.Group>
                    </Row>
                    {
                        (files && files.length > 0) ? 
                            <Row className="mt-2">
                                {
                                    files.map(file => (
                                        <Col md="auto" style={{ paddingRight: "0px" }}><Badge bg="secondary">{file.name}</Badge></Col>
                                    ))
                                }
                            </Row>                                
                        : 
                        <></> 
                    }                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                <Button variant="primary" disabled={loading} onClick={handleConfirm}>Solicitar</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default DisassociateModal;