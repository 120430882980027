import React, { useEffect } from "react";
import { TweenMax, Circ } from "gsap/all";
import styles from "./NumberDisplay.module.css";

const NumberDisplay = ( { value, title, id, digit, label } ) => {

    /*
    useEffect(() => {
        let counter = { var: 0 };
        let display = document.getElementById(id);
        TweenMax.to(counter, 3, {
            var: value,
            onUpdate: function () {
                display.innerHTML = Math.ceil(counter.var);
            },
            ease: Circ.easeOut
        });
    }, 
    [value]);
    */

    return (
        <section style={{ margin: "auto" }}>
            <p className={styles.digit} id={id} style={digit ? { fontSize: `${digit}px` } : undefined}>{value}</p>
            <p className={styles.label} style={label ? { fontSize: `${label}px` } : undefined}>{title}</p>
        </section>
    );

}

export default NumberDisplay;