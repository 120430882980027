import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import styles from "./DefendantAdd.module.css";
import { useKeycloak } from "@react-keycloak/web";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const DefendantAdd = ({ show, handleClose, callback, data }) => {

    const [ preCarga, setPreCarga ] = useState(true);

    const [ loading, setLoading ] = useState(false);
    const [ firstName, setFirstName ] = useState(undefined);
    const [ lastName, setLastName ] = useState(undefined);
    const [ birthDate, setBirthDate ] = useState(undefined);
    const [ documentType, setDocumentType ] = useState("CI");
    const [ documentValue, setDocumentValue ] = useState(undefined);

    const [ department, setDepartment ] = useState(undefined);
    const [ city, setCity ] = useState(undefined);
    const [ neighborhood, setNeighborhood ] = useState(undefined); 
    const [ principalStreet, setPrincipalStreet ] = useState(undefined);
    const [ secondaryStreet, setSecondaryStreet ] = useState(undefined);
    const [ houseNumber, setHouseNumber ] = useState(undefined);

    const [ publicOfficial, setPublicOfficial ] = useState(false);
    const [ publicOfficialTitle, setPublicOfficialTitle ] = useState(undefined);
    const [ pep, setPep ] = useState(false);
    const [ pepTitle, setPepTitle ] = useState(undefined);
    const [ description, setDescription ] = useState(undefined);
    
    const [ pepTitles, setPepTitles] = useState([]);

    const [ departments, setDepartments ] = useState([]);
    const [ neighborhoods, setNeighborhoods ] = useState([]);
    const [ cities, setCities ] = useState([]);

    const { keycloak } = useKeycloak();          

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const findPep = () => {
        axios.get(process.env.REACT_APP_API_URL + "/pep", prepareHeaders())
        .then(res => setPepTitles(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };    

    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findNeighborhoods = ( cityId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`, prepareHeaders())
        .then(res => setNeighborhoods(res.status == 200 ? res.data : []))
        .catch(error => console.error(error));
    };

    const handleChangeNeighborhood = (event, value, reason, details) => {
        event.preventDefault();
        setNeighborhood(typeof value == 'object' ? value : { name: value });
    }    

    const handleChangePep = (event, value, reason, details) => {
        setPepTitle(value);
    }    

    const buildBody = () => {
        let elements = [
            {
                action: "ADD",
                field: "DEFENDANT",
                value: {
                    firstName,
                    lastName,
                    document: {
                        type: documentType,
                        number: documentValue
                    },
                    birthDate,
                    address: {
                        department,
                        city,
                        neighborhood,
                        street: {
                            principal: principalStreet,
                            secondary: secondaryStreet
                        },
                        houseNumber,
                        description
                    },
                    publicOfficial: !publicOfficial ? undefined : {
                        title: publicOfficialTitle
                    },
                    publiclyExposedPerson: !pep ? undefined : { ...pepTitle }
                }
            }
        ]
        return { data: elements };
    }    

    const patch = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        .then(
            res => {
                toast.success("Datos actualizados", { position: "top-center"});
                setLoading(false);
                callback();
            }
        )
        .catch(err => {
            toast.error("Ha ocurrido un error al actualizar los datos", { position: "top-center"});
            setLoading(false);
        });
    }    

    useEffect(() => { findDeparments(); findPep(); }, []);
    useEffect(() => { if(department) { findCities(department.id); }}, [ department ]);
    useEffect(() => { if(city) { findNeighborhoods(city.id); }}, [ city ]);    

    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar denunciado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Datos personales</Form.Label>
                            <Form.Group as={Col} controlId="nameValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required
                                        id="floatingName"
                                        type="text" 
                                        value={firstName}
                                        onChange={(e) => { setFirstName(e.target.value); }}
                                        placeholder="Ingrese el nombre del denunciante" 
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingName">Nombres</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastNameValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required
                                        value={lastName}
                                        onChange={(e) => { setLastName(e.target.value); }}                        
                                        className={styles.input}
                                        type="text" 
                                        placeholder="Ingrese el nombre del denunciante" 
                                    />
                                    <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="birthDateValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingBirthDate"
                                        required={!preCarga}
                                        value={birthDate}
                                        onChange={(e) => { setBirthDate(e.target.value); }}                        
                                        className={styles.input}
                                        type="date" 
                                    />
                                    <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                                </Form.Floating>
                            </Form.Group>                           
                        </Row>
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Documento de identidad</Form.Label>
                            <Form.Group as={Col} controlId="documentTypeValidation">
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingDocumentType" 
                                        className={styles.input} 
                                        required={!preCarga}
                                        as="select" 
                                        value={documentType}
                                        onChange={(e) => {setDocumentType(e.target.value)}}
                                    >
                                        <option value="CI">Cédula de identidad</option>
                                        <option value="PAS">Pasaporte</option>
                                    </Form.Control>
                                    <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="documentValueValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required={!preCarga}
                                        id="floatingDocumentValue"
                                        type={documentType === "CI" ? "number" : "text"}
                                        value={documentValue} 
                                        className={styles.input}
                                        onChange={(e) => { setDocumentValue(e.target.value); }} 
                                        placeholder="Ingrese el número de documento"
                                    />
                                    <label htmlFor="floatingDocumentValue" className={styles.label}>Número de documento</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Domicilio</Form.Label>
                            <Form.Group className="mb-4" as={Col} controlId="departamentValidation">
                                <Form.Floating>
                                    <Form.Select 
                                        id="floatingDepartment" 
                                        className={styles.input} 
                                        required={!preCarga} 
                                        value={JSON.stringify(department)} 
                                        onChange={(e) => { setDepartment(JSON.parse(e.target.value)); }}
                                    >
                                        <option value="" selected>Seleccione</option>
                                        {
                                            departments.map((current) => (
                                                <option value={JSON.stringify(current)}>{current.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <label htmlFor="floatingDepartment" className={styles.label}>Departamento</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="cityValidation">
                                <Form.Floating>
                                    <Form.Select 
                                        id="floatingCity" 
                                        className={styles.input} 
                                        required={!preCarga} 
                                        value={JSON.stringify(city)} 
                                        onChange={(e) => { setCity(JSON.parse(e.target.value)); }}
                                    >
                                        <option value="" selected>Seleccione</option>
                                        {
                                            cities.map((current) => (
                                                <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                            ))
                                        }
                                    </Form.Select>
                                    <label htmlFor="floatingCity" className={styles.label}>Ciudad</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="neighborhoodValidation">
                                <Autocomplete 
                                    options={neighborhoods}
                                    getOptionLabel={(option) => typeof option == "object" ? option.name : option}
                                    value={JSON.stringify(neighborhood)}
                                    freeSolo
                                    autoSelect
                                    onChange={handleChangeNeighborhood}
                                    renderInput={(params) => ( <TextField {...params} label="Barrio / Localidad" /> )}
                                />
                            </Form.Group>
                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="principalStreetValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingPrincipalStreet"
                                            required={!preCarga}
                                            type="text"
                                            value={principalStreet}
                                            className={styles.input}
                                            placeholder="Ingrese la calle principal"
                                            onChange={(e) => { setPrincipalStreet(e.target.value); }}                            
                                        />
                                        <label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} controlId="secondaryStreetValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingSecondaryStreet"
                                            required={!preCarga}
                                            type="text"
                                            placeholder="Ingrese la calle secundaria"
                                            value={secondaryStreet}
                                            className={styles.input}
                                            onChange={(e) => { setSecondaryStreet(e.target.value); }}                            
                                        />
                                        <label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</label>
                                    </Form.Floating>
                                </Form.Group>            
                                <Form.Group as={Col} controlId="houseNumberValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingHouseNumber"
                                            type="number"
                                            placeholder="Ingrese el número de residencia"
                                            value={houseNumber}
                                            className={styles.input}
                                            onChange={(e) => { setHouseNumber(e.target.value); }}
                                        />
                                        <label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="descriptionValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingDescription"
                                            required={!preCarga}
                                            type="text"
                                            value={description}
                                            placeholder="Ingrese la descripción"
                                            className={styles.input}
                                            onChange={(e) => { setDescription(e.target.value); }}
                                        />
                                        <label htmlFor="floatingDescription" className={styles.label}>Descripción</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>                
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={3} controlId="pepValidation">
                                <Form.Check
                                    label="Perfil politico prioritario"
                                    type="switch"
                                    name="pep"
                                    id="pep"
                                    style={{ accentColor: '#AD0039', marginTop: "auto", marginBottom: "auto", paddingBottom: "0px", paddingRight: "0px" }}
                                    defaultChecked={pep}
                                    onChange={e => setPep(e.target.checked) }
                                    className={styles.label}
                                />
                            </Form.Group>
                            {
                                pep ? (
                                    <Form.Group as={Col} md={6}>
                                        <Autocomplete 
                                            options={pepTitles.sort((a, b) => a.category.level > b.category.level ? 1 : (a.category.level == b.category.level ? 0 : -1))}
                                            groupBy={(option) => `Perfil político prioritario ${option.category.level}`}
                                            getOptionLabel={(option) => option.title}
                                            value={JSON.stringify(pepTitle)}
                                            onChange={handleChangePep}
                                            renderInput={(params) => ( <TextField {...params} label="Cargo" /> )}
                                        />
                                    </Form.Group>
                                )
                                : <></>
                            } 
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md={3} controlId="publicOfficialValidation">
                                <Form.Check
                                    label="Funcionario público"
                                    type="switch"
                                    name="publicOfficial"
                                    id="publicOfficial"
                                    style={{ accentColor: '#AD0039', marginTop: "auto", marginBottom: "auto", paddingBottom: "0px", paddingRight: "0px" }}
                                    defaultChecked={publicOfficial}
                                    onChange={e => setPublicOfficial(e.target.checked) }
                                    className={styles.label}
                                />
                            </Form.Group>
                            {
                                publicOfficial ? (
                                    <Form.Group as={Col} md={6}>
                                        <Form.Floating>
                                            <Form.Control 
                                                id="floatingPublicOfficial"
                                                type="text" 
                                                placeholder="Ingrese el cargo del funcionario público"
                                                className={styles.input} 
                                                required 
                                                value={publicOfficialTitle} 
                                                onChange={(e) => { setPublicOfficialTitle(e.target.value); }}
                                            />
                                            <label htmlFor="floatingPublicOfficial" className={styles.label}>Cargo</label>
                                        </Form.Floating>                                
                                    </Form.Group>
                                )
                                : <></>
                            } 
                        </Row>
                    </Form>                
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={patch} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

};

export default DefendantAdd;