const DocumentType = {
    "CI": {
        description: "Cédula de identidad"
    },
    "MAT": {
        description: "Matrícula de la Corte Suprema de Justicia"
    },
    "PAS": {
        description: "Pasaporte"
    }
};

export default DocumentType;