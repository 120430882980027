import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import toast, { Toaster } from 'react-hot-toast';
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./PlaceEdit.module.scss";

const PlaceEdit = ( { show, handleClose, callback, data } ) => {

    const [ preCarga, setPreCarga ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    const [ department, setDepartment ] = useState(undefined);
    const [ city, setCity ] = useState(undefined);
    const [ neighborhood, setNeighborhood ] = useState(undefined); 
    const [ principalStreet, setPrincipalStreet ] = useState(undefined);
    const [ secondaryStreet, setSecondaryStreet ] = useState(undefined);
    const [ houseNumber, setHouseNumber ] = useState(undefined);    
    const [ description, setDescription ] = useState(undefined);

    const [ departments, setDepartments ] = useState([]);
    const [ neighborhoods, setNeighborhoods ] = useState([]);
    const [ cities, setCities ] = useState([]);    

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }
      
    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findNeighborhoods = ( cityId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`, prepareHeaders())
        .then(res => setNeighborhoods(res.status == 200 ? res.data : []))
        .catch(error => console.error(error));
    };

    const returnValue = (value) => {
        try { return JSON.parse(value).name; }
        catch(e){ return value; }
    }        

    const handleChangeNeighborhood = (event, value, reason, details) => {
        event.preventDefault();
        setNeighborhood(typeof value == 'object' ? value : { name: value });
    }          

    useEffect(() => { findDeparments(); }, []);
    useEffect(() => { if(department != undefined) { findCities(department); }}, [ department ]);
    useEffect(() => { if(city != undefined) { findNeighborhoods(city); }}, [ city ]);      

    useEffect(() => {
        if(data){
            setDepartment(data.complaint.statementOfFact.address.department?.id);
            setCity(data.complaint.statementOfFact.address.city?.id);
            setNeighborhood(data.complaint.statementOfFact.address.neighborhood);
            setPrincipalStreet(data.complaint.statementOfFact.address.street.principal);
            setSecondaryStreet(data.complaint.statementOfFact.address.street.secondary);
            setHouseNumber(data.complaint.statementOfFact.address.houseNumber);
            setDescription(data.complaint.statementOfFact.address.description);
        }
    }, 
    [data]);

    const buildBody = () => {
        let elements = [];
        let dep = departments.filter(dep => dep.id == department);
        dep = dep.length > 0 ? dep[0] : {};

        let ct = cities.filter(ct => ct.id == city);
        ct = ct.length > 0 ? ct[0] : {};
        
        elements.push({
            action: "REPLACE",
            field: "STATEMENT_OF_FACT_ADDRESS",
            value: {
                department: dep,
                city: ct,
                neighborhood,
                street: {
                    principal: principalStreet,
                    secondary: secondaryStreet
                },
                description,
                houseNumber                        
            }
        });  
        return { data: elements };
    }

    const patch = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        .then(res => {
            toast.success("Datos actualizados", { position: "top-center" });
            setLoading(false);
            callback();
        })
        .catch(err => {
            toast.error("Ha ocurrido un error al actualizar los datos " + err.data ? (": " + err.data.message) : "", { position: "top-center"});
            setLoading(false);
        });
    }    
    
    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Lugar del hecho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mt-2 mb-4">
                            <Form.Group className="mb-3" as={Col} controlId="departamentValidation">
                                <Form.Floating>
                                    <Form.Select id="floatingDepartment" className={styles.input} required value={department}  onChange={(e) => { setDepartment(e.target.value); }}>
                                        <option value={undefined}>Seleccione</option>
                                        {
                                            departments.map((current) => (
                                                <option value={current.id}>{current.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Label htmlFor="floatingDepartment" className={styles.label}>Departamento</Form.Label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="cityValidation">
                                <Form.Floating>
                                    <Form.Select id="floatingCity" className={styles.input} required value={city} onChange={(e) => { setCity(e.target.value); }}>
                                        <option value={undefined}>Seleccione</option>
                                        {
                                            cities.map((current) => (
                                                <option value={current.id}>{current.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Label htmlFor="floatingCity" className={styles.label}>Ciudad</Form.Label>
                                </Form.Floating>                        
                            </Form.Group>
                            <Form.Group as={Col} controlId="neighborhoodValidation">
                                <Autocomplete 
                                    options={neighborhoods}
                                    getOptionLabel={(option) => typeof option == "object" ? option.name : returnValue(option)}
                                    value={neighborhood}
                                    freeSolo
                                    autoSelect
                                    onChange={handleChangeNeighborhood}
                                    renderInput={(params) => ( <TextField {...params} label="Barrio / Localidad" variant="standard" /> )}
                                />
                            </Form.Group>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="principalStreetValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            id="floatingPrincipalStreet"
                                            required={!preCarga}
                                            type="text"
                                            value={principalStreet}
                                            className={styles.input}
                                            placeholder="Ingrese la calle principal"
                                            onChange={(e) => { setPrincipalStreet(e.target.value); }}                            
                                        />
                                        <Form.Label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</Form.Label>                            
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} controlId="secondaryStreetValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingSecondaryStreet"
                                            required={!preCarga}
                                            type="text"
                                            placeholder="Ingrese la calle secundaria"
                                            value={secondaryStreet}
                                            className={styles.input}
                                            onChange={(e) => { setSecondaryStreet(e.target.value); }}                            
                                        />
                                        <Form.Label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</Form.Label>
                                    </Form.Floating>
                                </Form.Group>            
                                <Form.Group as={Col} controlId="houseNumberValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingHouseNumber"
                                            type="number"
                                            placeholder="Ingrese el número de residencia"
                                            value={houseNumber}
                                            className={styles.input}
                                            onChange={(e) => { setHouseNumber(e.target.value); }}
                                        />
                                        <Form.Label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</Form.Label>
                                    </Form.Floating>
                                </Form.Group>        
                            </Row>
                            <Row >
                                <Form.Group as={Col} controlId="descriptionValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            required={!preCarga}
                                            id="floatingDescription"
                                            type="text"
                                            value={description}
                                            className={styles.input}
                                            placeholder="Ingrese la descripción del lugar"
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                        <Form.Label htmlFor="floatingDescription" className={styles.label}>Descripción</Form.Label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={patch} disabled={loading}>
                        {
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

}

export default PlaceEdit;