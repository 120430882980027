import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card, Button, InputGroup, Spinner } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import styles from "./ComplexityRules.module.css";
import Slider from '@mui/material/Slider';
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

const ComplexityRules = () => {

    const [ features, setFeatures ] = useState([]);
    const [ loadingFeatures, setLoadingFeatures ] = useState(false);

    const [ complexityLevels, setComplexityLevels ] = useState([]);
    const [ loadingComplexityLevels, setLoadingComplexityLevels ] = useState(false);

    const [ savingFeatures, setSavingFeatures ] = useState(false);
    const [ savingComplexityLevels, setSavingComplexityLevels ] = useState(false);

    const [ max, setMax ] = useState(undefined);

    const { keycloak } = useKeycloak();

    const levelHandleChange = (e, value, code) => {
        e.preventDefault();
        let aux = [];
        for(let levelFeature of complexityLevels){
            if(levelFeature.code == code){
                levelFeature.left = value[0];
                levelFeature.right = value[1];
                levelFeature.marks = value.map(val => { return { value: val, label: val }; });
            }
            aux.push(levelFeature);
        }
        setComplexityLevels(aux);
    };

    const changeFeature = (e, code) => {
        e.preventDefault();
        let aux = [];
        for(let feat of features){
            if(feat.code == code){
                feat.points = e.target.value;
            }
            aux.push(feat);
        }
        setFeatures(aux);
    }

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const withMarks = (feats) => {
        let aux = [];
        for(let feat of feats){
            let marks = [];
            marks.push({ value: feat.left, label: feat.left }, { value: feat.right, label: feat.right });
            aux.push({...feat, marks});
        }
        return aux;
    }

    const buildPatchFeatures = () => {
        let feats = [];
        for(let feat of features){
            feats.push({
                code: feat.code,
                description: feat.description, 
                points: feat.points
            });
        }
        return { data: feats };
    }

    const buildPatchFeatureLevels = () => {
        let levels = [];
        for(let lev of complexityLevels){
            levels.push({
                code: lev.code,
                left: lev.left, 
                right: lev.right
            });
        }
        return { data: levels };
    }    

    const updateFeatureLevels = (e) => {
        e.preventDefault();
        setSavingComplexityLevels(true);
        try {
            axios.patch(`${process.env.REACT_APP_API_URL}/analyzer/features/levels`, buildPatchFeatureLevels(), prepareHeaders())
            .then(res => {
                setSavingComplexityLevels(false);
                findFeatures();
                toast.success("Rango de complejidad actualizado con éxito");
            })
            .catch(err => {
                setSavingComplexityLevels(false);
                toast.error("Ha ocurrido un error al actualizar el rango de complejidad");
            });                    
        }
        catch(err){
            setSavingComplexityLevels(false);
            toast.error("Ha ocurrido un error al actualizar el rango de complejidad");  
        }
    }    

    const updateFeatures = (e) => {
        e.preventDefault();
        setSavingFeatures(true);
        try {
            axios.patch(`${process.env.REACT_APP_API_URL}/analyzer/features`, buildPatchFeatures(), prepareHeaders())
            .then(res => {
                setSavingFeatures(false);
                findFeatures();
                toast.success("Puntaje actualizado con éxito");
            })
            .catch(err => {
                setSavingFeatures(false);
                toast.error("Ha ocurrido un error al actualizar el puntaje");
            });                    
        }
        catch(err){
            setSavingFeatures(false);
            toast.error("Ha ocurrido un error al actualizar el puntaje");         
        }
    }

    const findFeatures = () => {
        setLoadingFeatures(true);
        try {
            axios.get(process.env.REACT_APP_API_URL + "/analyzer/features", prepareHeaders()).
            then(res => {
                setFeatures(res.status == 200 ? res.data : []);
                setLoadingFeatures(false);
            }).
            catch(err => {
                setFeatures([]);
                setLoadingFeatures(false);
            })
        }
        catch(err){
            setFeatures([]);
            setLoadingFeatures(false);            
        }        
    }

    const findFeatureLevels = () => {
        setLoadingComplexityLevels(true);
        try {
            axios.get(process.env.REACT_APP_API_URL + "/analyzer/features/levels", prepareHeaders()).
            then(res => {
                setComplexityLevels(res.status == 200 ? withMarks(res.data) : []);
                setLoadingComplexityLevels(false);
            }).
            catch(err => {
                setComplexityLevels([]);
                setLoadingComplexityLevels(false);
            })
        }
        catch(err){
            setComplexityLevels([]);
            setLoadingComplexityLevels(false);            
        }        
    }    

    useEffect(() => {
        if(features && features.length > 0){
            let acc = 0;
            for(let feat of features){
                acc += feat.points;
            }
            setMax(acc);
        }
    }, 
    [features]);

    useEffect(() => { 
        findFeatures();
        findFeatureLevels();
    },
    []);

    return (
        <>
            <Toaster />
            <Form>
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>Características de la causa</Form.Label>
                    {
                        loadingFeatures 
                        ? <span className="mt-3 mb-2">Buscando...</span>
                        : 
                        ( !loadingFeatures && (!features || features.length == 0) ) 
                        ? <span className="mt-3 mb-2">No hay resultados</span>
                        :
                        (
                            <>
                                {
                                    features.map(feature => (
                                        <Card className={styles.featureGroup}>
                                            <Card.Body>
                                                <Card.Title style={{ margin: 0 }}>
                                                    <Row>
                                                        <Col className={styles.featureTitle}>
                                                            <span>{feature.description}</span>
                                                        </Col>
                                                        <Col style={{ textAlign: "right" }} md={2}>
                                                            <InputGroup>                                                    
                                                                <Form.Control className={styles.rangeInput} value={feature.points} type="number" onChange={(e) => { changeFeature(e, feature.code); }}/>
                                                                <InputGroup.Text>puntos</InputGroup.Text> 
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                            </>
                        )
                    }
                    <Row style={{ marginTop: "1rem" }}>
                        <Col style={{ textAlign: "right" }}>
                            <Button onClick={updateFeatures} disabled={savingFeatures}>
                            { 
                                savingFeatures ? (
                                    <>
                                        <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                        <span>Guardando...</span>
                                    </>
                                )
                                :
                                <span>Guardar</span>
                            }
                            </Button>
                        </Col>
                    </Row>                                   
                </Form.Group>
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>Rango de niveles de complejidad</Form.Label>
                    {
                       loadingComplexityLevels 
                       ? <span className="mt-3 mb-2">Buscando...</span>
                       : 
                       ( !loadingComplexityLevels && (!complexityLevels || complexityLevels.length == 0) ) 
                       ? <span className="mt-3 mb-2">No hay resultados</span>
                       :
                       (
                            <>
                                {
                                    complexityLevels.map((complexityLevel) => (
                                        <Row style={{ marginBottom: ".5rem" }}>
                                            <Col md={1}>
                                                <Form.Label className={styles.rangeTitle}>{complexityLevel.description}</Form.Label>
                                            </Col>
                                            <Col>
                                                <Slider
                                                    marks={complexityLevel.marks}
                                                    value={[complexityLevel.left, complexityLevel.right]}
                                                    onChange={((e, value) => { levelHandleChange(e, value, complexityLevel.code); })}
                                                    valueLabelDisplay="auto"
                                                    max={max}
                                                />
                                            </Col>
                                        </Row>                            
                                    ))
                                }
                            </>
                       )
                    }
                    <Row style={{ marginTop: "1rem" }}>
                        <Col style={{ textAlign: "right" }}>
                            <Button onClick={updateFeatureLevels} disabled={savingComplexityLevels}>
                            { 
                                savingComplexityLevels ? (
                                    <>
                                        <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                        <span>Guardando...</span>
                                    </>
                                )
                                :
                                <span>Guardar</span>
                            }
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </>
    );

};

export default ComplexityRules;