import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import NumberDisplay from '../../../components/charts/NumberDisplay';
import styles from "./DashboardFiscal.module.css";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import GaugeChart from 'react-gauge-chart'

const DashboardFiscal = ( props ) => {

  const [ year, setYear ] = useState(new Date().getFullYear());
  const [ dashboard, setDashboard ] = useState(undefined);
  const { keycloak } = useKeycloak();    

  const prepareHeaders = () => {
      return {
          headers: {
              "Accept": "application/json",
              "Authorization": `Bearer ${keycloak.token}`,
              "Access-Control-Allow-Origin": "*",
          }
      };
  }   

  const findDashboard = () => {
    let params = year ? ("?year=" + year) : "";
    axios.get(process.env.REACT_APP_API_URL + "/prosecutors/dashboard" + params, prepareHeaders())
    .then(res => {
        setDashboard(res.status == 206 ? undefined : res.data);
    })
    .catch(err => {
        setDashboard(undefined);
    });
  }

  useEffect(() => findDashboard(), []);
  useEffect(() => findDashboard(), [ year ]);

  return (
    <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
      <Col md={2}>
          <div className={styles.sideBar}>
              <Row style={{ marginBottom: "1rem" }}>
                  <Form.Group>
                      <Form.Label className={styles.label} as={Col}>Año</Form.Label>
                      <Form.Control className={styles.input} type="number" value={year} onChange={(e) => { setYear(e.target.value); }} />
                  </Form.Group>
              </Row>
          </div>
      </Col>
      <Col md={10}>
          <div className={styles.container}>
              {
                  dashboard ? (
                    <Row>
                        <Col md={4}>
                            <GaugeChart 
                                id="open-chart" 
                                nrOfLevels={9}
                                colors={["#FFC371", "#FF5F6D"]}
                                arcWidth={0.3}
                                textColor="black"
                                percent={dashboard.open > 0 ? (dashboard.open / dashboard.total) : 0}
                            />
                            <NumberDisplay value={dashboard.open} id={1} title={(<>Causas<br></br>activas</>)}/>
                        </Col>
                        <Col md={4}>
                            <GaugeChart 
                                id="closed-chart" 
                                nrOfLevels={9}
                                colors={["#FFC371", "#FF5F6D"]}
                                arcWidth={0.3}
                                textColor="black"
                                percent={dashboard.closed > 0 ? (dashboard.closed / dashboard.total) : 0}
                            />                        
                            <NumberDisplay value={dashboard.closed} id={2} title={(<>Causas<br></br>judicializadas</>)}/>
                        </Col>
                        <Col md={4}>
                            <GaugeChart 
                                id="risk-chart" 
                                nrOfLevels={9}
                                colors={["#FFC371", "#FF5F6D"]}
                                arcWidth={0.3}
                                textColor="black"
                                percent={dashboard.risk > 0 ? (dashboard.risk / dashboard.total) : 0}
                            />                                                
                            <NumberDisplay value={dashboard.risk} id={3} title={(<>Causas<br></br>en riesgo de<br></br>extinción</>)}/>
                        </Col>                                                
                    </Row>
                  )
                  : <span>No existen datos</span>
              }
          </div>
      </Col>
    </Row>
  )
}

export default DashboardFiscal;