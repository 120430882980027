import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Badge, Col, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import Spinner from "react-bootstrap/Spinner";
import styles from "./JudgmentModal.module.css";
import axios from "axios";

const JudgmentModal = ( { data, show, handleClose, callback} ) => {

    const { keycloak } = useKeycloak();
    const [ judgment, setJudgment ] = useState("ACCEPT");
    const [ description, setDescription ] = useState(undefined);
    const [ attachments, setAttachments ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                "Authorization": `Bearer ${keycloak.token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        };
    }    

    const judgments = [
        {
            label: "Aceptar",
            value: "ACCEPT"
        },
        {
            label: "Rechazar",
            value: "REJECT"
        },
        {
            label: "Indeterminado",
            value: "UNDETERMINED"
        }
    ];    

    const judge = (e) => {
        e.preventDefault();
        setLoading(true);
        buildFile((file) => { 
            let body = {
                judgmentType: judgment,
                description,
                file
            };
            axios.post(`${process.env.REACT_APP_API_URL}/economic/crimes/complaints/${data.id}/judge`, body, prepareHeaders())
            .then(res => {
                setLoading(false);
                callback();
                toast.success("Dictamen registrado", { position: "top-center"});
            })
            .catch(err => {
                toast.error("Ha ocurrido un error al registrar el dictamen" + ( err.response.data ? (": " + err.response.data.message) : ""), { position: "top-center"});
                setLoading(false);
                handleClose();
            });
        });
    }

    const buildFile = async (callback) => {
        if(!attachments || attachments.length == 0) { callback(undefined); }
        let file = attachments[0];
        let type = file.name.split('.').pop();
        let fileName = file.name;
        let fileBody = undefined;
        let reader = new FileReader();
        reader.onload = function(event) {
            const payload = event.target.result;
            callback({ type, fileName, payload });
        };
        reader.readAsDataURL(file);
    }

    return (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Datos del dictamen</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="judgementValidation">
                                    <Form.Label className={styles.label}>Dictamen</Form.Label>
                                    {
                                        judgments.map(
                                            ( current ) => (
                                                <Form.Check type="radio">
                                                    <Form.Check.Input 
                                                        type="radio"
                                                        value={current.value}
                                                        onChange={(e) => { setJudgment(e.target.value); }}
                                                        checked={judgment === current.value}
                                                        className={styles.checkbox}
                                                    />
                                                    <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                                </Form.Check>
                                            )
                                        )
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col}>
                                    <Form.Label className={styles.label}>Documento adjunto</Form.Label>
                                    <Row>
                                        <Col md={10}>
                                            <Form.Control
                                                required
                                                type="file"                             
                                                placeholder="Seleccione el documento"
                                                className={styles.fileInput}
                                                onChange={e => setAttachments(Array.from(e.target.files))}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        (attachments && attachments.length > 0) ? 
                                            <Row className="mt-2">
                                                {
                                                    attachments.map(file => (
                                                        <Col md="auto" style={{ paddingRight: "0px" }}>
                                                            <Badge bg="secondary">{file.name}</Badge>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        :
                                        <></> 
                                    }                            
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="descriptionValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingDescription"
                                            value={description}
                                            className={styles.input}
                                            type="text" 
                                            onChange={(e) => { setDescription(e.target.value); }}
                                        />
                                        <label className={styles.label} htmlFor="floatingDescription">Descripción</label>
                                    </Form.Floating>                        
                                </Form.Group>                            
                            </Row>
                        </Form>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                    <Button variant="primary" onClick={judge} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Registrando...</span>
                                </>
                            )
                            :
                            <span>Registrar</span>
                        }
                    </Button>                
                </Modal.Footer>
            </Modal>        
        </>            
    );

};

export default JudgmentModal;