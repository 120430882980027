import React, { useState, useEffect } from "react";
import styles from "./TransferModal.module.css";
import { Modal, Button, Form, Row, Col, Badge } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const TransferModal = ( { show, handleClose, complaint, callback } ) => {

    const [ loading, setLoading ] = useState(false);
    const [ reason, setReason ] = useState(undefined);
    const [ place, setPlace ] = useState(undefined);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }         

    const handleConfirm = async (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/complaints/${complaint.id}/transfer`, build(), prepareHeaders())
        .then(res => {
            setLoading(false);
            callback();
            handleClose();
        })
        .catch(err => {
            setLoading(false);
        });
    }

    const build = () => {
        return {
            place,
            reason
        };
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title><h5>Datos de la transferencia</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Motivo</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ingrese el motivo"
                                className={styles.input} 
                                value={reason} 
                                onChange={(e) => { setReason(e.target.value); }}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Lugar de transferencia</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Lugar de transferencia"
                                className={styles.input} 
                                value={place} 
                                onChange={(e) => { setPlace(e.target.value); }}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                <Button variant="primary" onClick={handleConfirm}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default TransferModal;