import React, { useState, useEffect } from "react";
import styles from "./OrganizationModal.module.css";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

const OrganizationModal = ( { show, handleClose, callback } ) => {

    const [ loading, setLoading ] = useState(false);
    const [ name, setName ] = useState(undefined);
    const [ description, setDescription ] = useState(undefined);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }         

    const handleRegister = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            axios.post(`${process.env.REACT_APP_API_URL}/organizations`, build(), prepareHeaders())
            .then(res => {
                toast.success("Organización registrada exitosamente");
                setLoading(false);
                callback();
            })
            .catch(err => {
                toast.error("Ha ocurrido un error al registrar la organización");
                setLoading(false);
            });
        }
        catch(err){
            toast.error("Ha ocurrido un error al registrar la organización");
            setLoading(false);            
        }
    }

    const build = () => {
        return {
            name,
            description
        };
    }

    return (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><h5>Datos de la organización</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Nombre</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Ingrese el nombre"
                                    className={styles.input} 
                                    value={name} 
                                    onChange={(e) => { setName(e.target.value); }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Descripción</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Ingrese la descripcion"
                                    className={styles.input} 
                                    value={description} 
                                    onChange={(e) => { setDescription(e.target.value); }}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary" onClick={handleRegister} disabled={ loading }>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Guardando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }
                    </Button>                    
                </Modal.Footer>
            </Modal>            
        </>
    );

}

export default OrganizationModal;