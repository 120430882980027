import React, { useState } from "react";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import styles from "./ExtintionRisk.module.css";

const ExtintionRisk = () => {

    const [ extintionTime, setExtintionTime ] = useState(6);
    const [ extintionAlertTime, setExtintionAlertTime ] = useState(1);    

    return (
        <>
            <Form>
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>Tiempo de extinción</Form.Label>
                    <Row>
                        <Form.Group as={Col} md="2" style={{ paddingRight: "0" }}> 
                            <Form.Control type="number" style={{ textAlign: "right" }} value={extintionTime} onChange={(e) => { setExtintionTime(e.target.value); }} />
                        </Form.Group>
                        <Form.Group as={Col} md="3" style={{ paddingRight: "0" }}>
                            <Form.Control as="select">
                                <option>día / días</option>                        
                                <option selected>mes / meses</option>
                                <option>año / años</option>                        
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} style={{ paddingRight: "0" }}>
                            <Button>Guardar</Button>
                        </Form.Group>
                    </Row>
                </Form.Group>
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>Notificación sobre tiempo de extinción</Form.Label>
                    <Row>
                        <Form.Group as={Col} md="2" style={{ paddingRight: "0" }}> 
                            <Form.Control type="number" style={{ textAlign: "right" }} value={extintionAlertTime} onChange={(e) => { setExtintionAlertTime(e.ta) }}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3" style={{ paddingRight: "0" }}>
                            <Form.Control as="select">
                                <option>día / días</option>                        
                                <option selected>mes / meses</option>
                                <option>año / años</option>                        
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} style={{ paddingRight: "0" }}>
                            <Button>Guardar</Button>
                        </Form.Group>                        
                    </Row>
                </Form.Group>
            </Form>
        </>
    );

};

export default ExtintionRisk;