import React, { useState, useEffect } from "react";
import { Row, Col, Form, Badge, Button } from "react-bootstrap";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import styles from "./Denuncia.module.css";
import { ComplaintStatus } from "../../../constants/ComplaintStatus";
import { BsNewspaper, BsFillPeopleFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { SlOptions } from "react-icons/sl";
import { IoDocumentsOutline } from "react-icons/io5";
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineFileSearch } from "react-icons/ai";
import { TbMinusVertical, TbListCheck } from "react-icons/tb";
import { RiMailSendLine, RiFileForbidLine } from "react-icons/ri";
import { FaDownload } from "react-icons/fa6";
import { TiCancel } from "react-icons/ti";
import { AiOutlineFileDone } from "react-icons/ai";
import { useKeycloak } from "@react-keycloak/web";
import { checkRoles } from "../../../Keycloak";
import EditDateModal from "./EditDateModal";
import { MdDateRange } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import TransferModal from "./TransferModal";

const Denuncia = ( { role, complaint, callback } ) => {

    const [ step, setStep ] = useState(1);
    const [ showEditDate, setShowEditDate ] = useState(false);
    const [ showTransfer, setShowTransfer ] = useState(false);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const prepareHeadersReport = () => {
        return {
            headers: {
                "Accept": "application/pdf",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const editDate = (e) => {
        e.preventDefault();
        setShowEditDate(true);
    }    

    const competenceReport = (e) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/analyzer/competence/crimes/report/${complaint.id}`, prepareHeadersReport())
            .then(response => {
                //response.ok esta en el rango 200<=response.status<=299
                if (response.ok) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = "ReporteNoCompetencia-" + complaint.id + ".pdf";
                        a.click();
                        toast.success('El reporte se descargó con éxito.');
                    });
                } else {
                    console.error(`Error en la solicitud. Código de estado: ${response.status}`);
                    toast.error('No se pudo descargar el reporte. Inténtalo de nuevo más tarde.');
                }
            })
            .catch(error => {
                console.error(error);
                toast.error('Ocurrió un error al descargar el reporte. Inténtalo de nuevo más tarde.');
            });
    }

    const prepareHeadersString = () => {
        return {
            headers: {
                "Content-Type": "text/plain",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const resolveActions = ( complaint ) => {
        if(role === "fiscal-adjunto"){
            switch(complaint.status){
                case ComplaintStatus.JUDGED_BY_DDEA.code:
                case ComplaintStatus.SENDED_TO_DDEA.code:
                    return (
                        <>
                            <Dropdown.Item onClick={(e) => { approve(e, complaint.id); }} className={styles.optionItem}>
                                <AiOutlineFileDone />&nbsp;
                                Aprobar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { archivar(e, complaint.id); }} className={styles.optionItem}>
                                <RiFileForbidLine />&nbsp;
                                Archivar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setShowTransfer(true); }} className={styles.optionItem}>
                                <RiFileForbidLine />&nbsp;
                                Transferir
                            </Dropdown.Item>                            
                            <Dropdown.Item onClick={editDate} className={styles.optionItem}>
                                <MdDateRange />&nbsp;
                                Editar fecha
                            </Dropdown.Item>                            
                            <Dropdown.Item onClick={(e) => { cancel(e, complaint.id); }} className={styles.optionItem}>
                                <TiCancel />&nbsp;
                                Cancelar
                            </Dropdown.Item>
                        </>
                    );            
                case ComplaintStatus.ANALYZING.code:
                    return (
                        <>
                            <Dropdown.Item className={styles.optionItem} onClick={competenceReport}>
                                <FaDownload />&nbsp;
                                Reporte de no competencia
                            </Dropdown.Item>              
                            <Dropdown.Item onClick={(e) => { delegate(e, complaint.id); }} className={styles.optionItem}>
                                <RiMailSendLine />&nbsp;
                                Remitir a la Dirección de Delitos Económicos
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { approve(e, complaint.id); }} className={styles.optionItem}>
                                <AiOutlineFileDone />&nbsp;
                                Aprobar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { archivar(e, complaint.id); }} className={styles.optionItem}>
                                <RiFileForbidLine />&nbsp;
                                Archivar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setShowTransfer(true); }} className={styles.optionItem}>
                                <RiFileForbidLine />&nbsp;
                                Transferir
                            </Dropdown.Item>                                                        
                            <Dropdown.Item onClick={editDate} className={styles.optionItem}>
                                <MdDateRange />&nbsp;
                                Editar fecha
                            </Dropdown.Item>                            
                            <Dropdown.Item onClick={(e) => { cancel(e, complaint.id); }} className={styles.optionItem}>
                                <TiCancel />&nbsp;
                                Cancelar
                            </Dropdown.Item>
                        </>
                    );
                case ComplaintStatus.RECEIVED.code:
                    return (
                        <>
                            <Dropdown.Item onClick={(e) => { cancel(e, complaint.id); }} className={styles.optionItem}>
                                <TiCancel />&nbsp;
                                Cancelar
                            </Dropdown.Item>
                        </>
                    );
                case ComplaintStatus.REJECTED.code:
                    return (
                        <>
                            <Dropdown.Item className={styles.optionItem} onClick={competenceReport}>
                                <FaDownload />&nbsp;
                                Reporte de no competencia
                            </Dropdown.Item>
                        </>                        
                    );                    
                default:
                    return (
                        <>
                            <Dropdown.Item onClick={editDate} className={styles.optionItem}>
                                <MdDateRange />&nbsp;
                                Editar fecha
                            </Dropdown.Item>                        
                            <Dropdown.Item onClick={(e) => { cancel(e, complaint.id); }} className={styles.optionItem}>
                                <TiCancel />&nbsp;
                                Cancelar
                            </Dropdown.Item>
                        </>
                    );
            }
        }
        else if(role === "ddea") {
            return (
                <>
                    <Dropdown.Item onClick={(e) => { judge(e, complaint.id); }} className={styles.optionItem}>
                        <AiOutlineFileDone />&nbsp;
                        Emitir dictamen
                    </Dropdown.Item>
                </>
            )
        }
        else {
            return (
                <Dropdown.Item onClick={editDate} className={styles.optionItem}>
                    <MdDateRange />&nbsp;
                    Editar fecha
                </Dropdown.Item>
            );
        }
    }

    const cancel = ( e, id ) => {
        e.preventDefault();
        axios.delete(`${process.env.REACT_APP_API_URL}/complaints/${id}`, prepareHeaders())
        .then(res => {
            callback();
        })
        .catch(err => {
            console.log(err);
        });          
    }

    const delegate = ( e, id ) => {
        e.preventDefault();        
        axios.post(`${process.env.REACT_APP_API_URL}/complaints/${id}/delegate`, null, prepareHeaders())
        .then(res => {
            callback();
        })
        .catch(err => {
            console.log(err);
        });        
    }

    const archivar = ( e, id ) => {
        e.preventDefault();        
        axios.post(`${process.env.REACT_APP_API_URL}/complaints/${id}/reject`, "test", prepareHeadersString())
        .then(res => {
            callback();
        })
        .catch(err => {
            console.log(err);
        });
    }

    const judge = (e, id) => {
        e.preventDefault();
    }

    const approve = ( e, id ) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/complaints/${id}/approve`, null, prepareHeaders())
        .then(res => {
            callback();
        })
        .catch(err => {
            console.log(err);
        });
    }
 
    const haveOptions = ( complaint ) => {
        return complaint.status != "TRANSFERRED";
    }

    return (
        <div className={styles.container}>
            <Toaster />
            <EditDateModal show={showEditDate} handleClose={() => { setShowEditDate(false); }} callback={callback} complaint={complaint} />
            <TransferModal show={showTransfer} handleClose={() => { setShowTransfer(false); }} callback={callback} complaint={complaint} />
            <Row>
                <Col md={8}>
                    <Form.Label className={styles.title}>{`Nº ${complaint.id}`}</Form.Label>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                    <div className={styles.actionsContainer}>
                        {
                            complaint.prosecutor ? (
                                <>
                                    <Badge className={styles.prosecutor} pill>{`${complaint.prosecutor.firstName} ${complaint.prosecutor.lastName}`}</Badge>&nbsp;&nbsp;
                                </>
                            )
                            :
                            <></>
                        }
                        <Badge className={styles.status} pill>{ComplaintStatus[complaint.status].name}</Badge>
                        {
                            haveOptions(complaint) ? (
                                <Dropdown align="end">
                                    <Dropdown.Toggle className={styles.option} id="dropdown-button-dark-example1" variant="secondary">
                                        <SlOptions />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        { resolveActions(complaint) }
                                    </Dropdown.Menu>
                                </Dropdown>                                                    
                            )
                            : <></>
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <Col md="auto">
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <BsNewspaper className={`${styles.navIcon} ${step === 1 ? styles.active : ""}`} onClick={ (e) => { setStep(1) }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <BsFillPeopleFill className={`${styles.navIcon} ${step === 2 ? styles.active : ""}`} onClick={ (e) => { setStep(2) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <FaMapMarkedAlt className={`${styles.navIcon} ${step === 3 ? styles.active : ""}`} onClick={ (e) => { setStep(3) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbListCheck className={`${styles.navIcon} ${step === 4 ? styles.active : ""}`} onClick={ (e) => { setStep(4) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                       
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <AiOutlineFileSearch className={`${styles.navIcon} ${step === 5 ? styles.active : ""}`} onClick={ (e) => { setStep(5) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <IoDocumentsOutline className={`${styles.navIcon} ${step === 6 ? styles.active : ""}`} onClick={ (e) => { setStep(6) }}/>
                        </Col>
                    </Row>                    
                </Col>
                <Col>
                    {
                        step === 1 ? <Step1 data={complaint} /> : (
                            step === 2 ? <Step2 data={complaint}/> : (
                                step === 3 ? <Step3 data={complaint}/> : (
                                    step === 4 ? <Step4 data={complaint}/> : (
                                        step === 5 ? <Step5 data={complaint}/> : (
                                            step === 6 ? <Step6 data={complaint} /> : <></>
                                        )
                                    )
                                )
                            )
                        )
                    }
                </Col>
            </Row>
            <Row style={{ display: "flex" }}>
                {
                    step > 1 && step < 6 ? (
                        <>
                            <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-1) }}>ANTERIOR</Button>
                            </Col>
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+1) }}>SIGUIENTE</Button>
                            </Col>                        
                        </>
                    )
                    : step === 1 ? (
                        <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+1) }}>SIGUIENTE</Button>
                        </Col>
                    )
                    : (
                        <Col md={12} style={{ marginRight: "auto", textAlign: "left" }}>
                            <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-1) }}>ANTERIOR</Button>
                        </Col>
                    )
                }
            </Row>
        </div>
    )

}

export default Denuncia;