import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button, Badge, Spinner } from "react-bootstrap";
import styles from "./ComplainantAdd.module.css";
import { useKeycloak } from "@react-keycloak/web";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

const ComplainantAdd = ({ show, handleClose, callback, data }) => {

    const [ preCarga, setPreCarga ] = useState(true);
    const [ loading, setLoading ] = useState(false);
 
    const [ stateInstitutions, setStateInstitutions ] = useState([]);
    const [ stateInstitution, setStateInstitution ] = useState(undefined);

    const [ privateInstitutions, setPrivateInstutions ] = useState([]);
    const [ privateInstitution, setPrivateInstitution ] = useState(undefined);

    const [ type, setType ] = useState("PF");
    const [ title, setTitle ] = useState(undefined);
    const [ firstName, setFirstName ] = useState(undefined);
    const [ lastName, setLastName ] = useState(undefined);
    const [ birthDate, setBirthDate ] = useState(undefined);
    const [ documentType, setDocumentType ] = useState("CI");
    const [ documentValue, setDocumentValue ] = useState(undefined);

    const [ department, setDepartment ] = useState(undefined);
    const [ city, setCity ] = useState(undefined);
    const [ neighborhood, setNeighborhood ] = useState(undefined); 
    const [ principalStreet, setPrincipalStreet ] = useState(undefined);
    const [ secondaryStreet, setSecondaryStreet ] = useState(undefined);
    const [ houseNumber, setHouseNumber ] = useState(undefined);
    const [ description, setDescription ] = useState(undefined);
    const [ mandatorio, setMandatorio ] = useState(false);
    const [ attachments, setAttachments ] = useState([]);

    const [ departments, setDepartments ] = useState([]);
    const [ neighborhoods, setNeighborhoods ] = useState([]);
    const [ cities, setCities ] = useState([]);

    const { keycloak } = useKeycloak();

    const types = [
        {
            label: "Ciudadano",
            value: "PF"
        },
        {
            label: "Institución del Estado",
            value: "IE"
        },
        {
            label: "Institución privada",
            value: "IP"
        }
    ];     

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findNeighborhoods = ( cityId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`, prepareHeaders())
        .then(res => setNeighborhoods(res.status == 200 ? res.data : []))
        .catch(error => console.error(error));
    };

    const findStateInstitutions = () => {
        axios.get(process.env.REACT_APP_API_URL + `/state/institutions`, prepareHeaders())
        .then(res => setStateInstitutions(res.status == 200 ? res.data : []))
        .catch(error => console.log(error));
    }

    const handleChangeStateInstitution = (event, value, reason, details) => {
        event.preventDefault();
        setStateInstitution(typeof value == 'object' ? value : { name: value });
    }    

    const handleChangeNeighborhood = (event, value, reason, details) => {
        event.preventDefault();
        setNeighborhood(typeof value == 'object' ? value : { name: value });
    }    

    const changeType = (e) => {
        setType(e.target.value);
        setMandatorio(e.target.value === "IP");
    } 

    const buildBody = () => {
        let elements = [
            {
                action: "ADD",
                field: "COMPLAINANT",
                value: {
                    firstName,
                    lastName,
                    document: {
                        type: documentType,
                        number: documentValue
                    },
                    birthDate,
                    address: {
                        department,
                        city,
                        neighborhood,
                        street: {
                            principal: principalStreet,
                            secondary: secondaryStreet
                        },
                        houseNumber,
                        description
                    },
                    type,
                    stateInstitution,
                    title,
                    privateInstitution,
                    mandatory: mandatorio,
                    attachments
                }
            }
        ]
        return { data: elements };
    }    

    const patch = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        //axios.patch(`http://localhost:8084/udea.deputy-prosecutor/api/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        .then(res => {
            toast.success("Datos actualizados", { position: "top-center"});
            setLoading(false);
            callback();
        })
        .catch(err => {
            toast.error("Ha ocurrido un error al actualizar los datos " + err.data ? (": " + err.data.message) : "", { position: "top-center"});
            setLoading(false);
        });
    }    

    useEffect(() => findDeparments(), []);
    useEffect(() => findStateInstitutions(), []);
    useEffect(() => { if(department) { findCities(department.id); }}, [ department ]);
    useEffect(() => { if(city) { findNeighborhoods(city.id); }}, [ city ]);    

    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar denunciante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mt-2 mb-4">
                            <Form.Label className={styles.groupLabel}>Tipo de denunciante</Form.Label>
                            <Form.Group as={Col} controlId="typeValidation">
                                {
                                    types.map(
                                        ( current ) => (
                                            <Form.Check type="radio">
                                                <Form.Check.Input 
                                                    type="radio"
                                                    value={current.value}
                                                    onChange={changeType}
                                                    checked={type === current.value}
                                                    className={styles.checkbox}
                                                />
                                                <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                            </Form.Check>
                                        )
                                    )
                                }
                                <Form.Control.Feedback>{`Denuncia ${type} ✓`}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Debes seleccionar el tipo de la denuncia</Form.Control.Feedback>
                            </Form.Group>                
                        </Row>            
                        {
                            type === "IE" ? (
                                <>
                                    <Row className="mb-4">
                                        <Form.Label className={styles.groupLabel}>Institución del estado</Form.Label>
                                        <Form.Group as={Col} md="6" controlId="nameValidation">
                                            <Autocomplete 
                                                options={stateInstitutions}
                                                getOptionLabel={(option) => typeof option == "object" ? option.name : option}
                                                value={JSON.stringify(stateInstitution)}
                                                freeSolo
                                                autoSelect
                                                onChange={handleChangeStateInstitution}
                                                renderInput={(params) => ( <TextField {...params} label="Seleccione o ingrese la institución"/> )}
                                            />
                                        </Form.Group>   
                                    </Row>                                   
                                </>
                            )
                            :<></>
                        }
                        {
                            type === "IP" ? (
                                <>
                                    <Row className="mb-4">
                                        <Form.Label className={styles.groupLabel}>Institución privada</Form.Label>
                                        <Form.Group as={Col} md="6" controlId="nameValidation">
                                            <Form.Floating>
                                                <Form.Control
                                                    required
                                                    id="floatingName"
                                                    type="text" 
                                                    value={privateInstitution}
                                                    onChange={(e) => { setPrivateInstitution(e.target.value); }}
                                                    placeholder="Ingrese el nombre de la institución privada" 
                                                    className={styles.input}
                                                />
                                                <label className={styles.label} htmlFor="floatingName">Institución privada</label>
                                            </Form.Floating>
                                        </Form.Group>   
                                    </Row>                                   
                                </>
                            )
                            :<></>
                        }            
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Datos personales</Form.Label>
                            <Form.Group as={Col} controlId="nameValidation">
                                <Form.Floating>
                                    <Form.Control
                                        required
                                        id="floatingName"
                                        type="text" 
                                        value={firstName}
                                        onChange={(e) => { setFirstName(e.target.value); }}
                                        placeholder="Ingrese el nombre del denunciante" 
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingName">Nombres</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastNameValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required
                                        id="floatingLastName"
                                        value={lastName}
                                        onChange={(e) => { setLastName(e.target.value); }}                        
                                        className={styles.input}
                                        type="text" 
                                        placeholder="Ingrese el nombre del denunciante" 
                                    />
                                    <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                                </Form.Floating>                        
                            </Form.Group>
                            {
                                type === "IE" ? (
                                    <Form.Group as={Col} controlId="cargo">
                                        <Form.Floating>
                                            <Form.Control
                                                id="cargo" 
                                                required
                                                type={"text"}
                                                value={title} 
                                                placeholder="Ingrese el cargo del representante"
                                                className={styles.input}
                                                onChange={(e) => { setTitle(e.target.value); }} 
                                            />
                                            <label htmlFor="cargo" className={styles.label}>Cargo</label>
                                        </Form.Floating>
                                    </Form.Group>
                                )
                                : (
                                    <Form.Group as={Col} controlId="birthDateValidation">
                                        <Form.Floating>
                                            <Form.Control 
                                                id="floatingBirthDate"
                                                required={!preCarga}
                                                value={birthDate}
                                                onChange={(e) => { setBirthDate(e.target.value); }}                        
                                                className={styles.input}
                                                placeholder="Ingrese la fecha de nacimiento"
                                                type="date" 
                                            />
                                            <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                                        </Form.Floating>
                                    </Form.Group>                       
                                )             
                            }
                        </Row>
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Documento de identidad</Form.Label>
                            <Form.Group as={Col} controlId="documentTypeValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingDocumentType" 
                                        className={styles.input} 
                                        required={!preCarga} 
                                        as="select" 
                                        value={documentType} 
                                        onChange={(e) => {setDocumentType(e.target.value)}}
                                    >
                                        <option value="CI">Cédula de identidad</option>
                                        <option value="PAS">Pasaporte</option>
                                        <option value="MAT">Matrícula de la Corte Suprema de Justicia</option>
                                    </Form.Control>
                                    <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="documentValueValidation">
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingDocumentNumber" 
                                        required={!preCarga} 
                                        type={documentType === "CI" ? "number" : "text"}
                                        value={documentValue} 
                                        placeholder="Ingrese el número de documento"
                                        className={styles.input}
                                        onChange={(e) => { setDocumentValue(e.target.value); }} 
                                    />
                                    <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Label className={styles.groupLabel}>Domicilio</Form.Label>
                            <Form.Group className="mb-2" as={Col} controlId="departamentValidation">
                                <Form.Floating>
                                    <Form.Control
                                        required={!preCarga}
                                        as="select"
                                        type="select"
                                        id="floatingDepartament" 
                                        className={styles.input} 
                                        value={JSON.stringify(department)}
                                        onChange={(e) => { setDepartment(JSON.parse(e.target.value)); }}
                                    >
                                        <option value="" selected>Seleccione</option>
                                        {
                                            departments.map((current) => (
                                                <option value={JSON.stringify(current)}>{current.name}</option>
                                            ))
                                        }
                                    </Form.Control> 
                                    <label htmlFor="floatingDepartament" className={styles.label}>Departamento</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="cityValidation">
                                <Form.Floating>
                                    <Form.Select 
                                        id="floatingCity" 
                                        className={styles.input} 
                                        required={!preCarga}
                                        value={JSON.stringify(city)} 
                                        onChange={(e) => { setCity(JSON.parse(e.target.value)); }}
                                    >
                                        <option value="" selected>Seleccione</option>
                                        {
                                            cities.map((current) => (
                                                <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                            ))
                                        }
                                    </Form.Select>
                                    <label htmlFor="floatingCity" className={styles.label}>Ciudad</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="neighborhoodValidation">
                                <Autocomplete 
                                    options={neighborhoods}
                                    getOptionLabel={(option) => typeof option == "object" ? option.name : option}
                                    value={JSON.stringify(neighborhood)}
                                    freeSolo
                                    autoSelect
                                    onChange={handleChangeNeighborhood}
                                    renderInput={(params) => ( <TextField {...params} label="Barrio / Localidad" /> )}
                                />
                            </Form.Group>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="principalStreetValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingPrincipalStreet"
                                            required={!preCarga}
                                            type="text"
                                            value={principalStreet}
                                            className={styles.input}
                                            placeholder="Ingrese la calle principal"
                                            onChange={(e) => { setPrincipalStreet(e.target.value); }}                            
                                        />
                                        <label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} controlId="secondaryStreetValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingSecondaryStreet"
                                            required={!preCarga}
                                            type="text"
                                            placeholder="Ingrese la calle secundaria"
                                            value={secondaryStreet}
                                            className={styles.input}
                                            onChange={(e) => { setSecondaryStreet(e.target.value); }}                            
                                        />
                                        <label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</label>
                                    </Form.Floating>
                                </Form.Group>            
                                <Form.Group as={Col} controlId="houseNumberValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            id="floatingHouseNumber"
                                            type="number"
                                            placeholder="Ingrese el número de residencia"
                                            value={houseNumber}
                                            className={styles.input}
                                            onChange={(e) => { setHouseNumber(e.target.value); }}
                                        />
                                        <label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</label>
                                    </Form.Floating>
                                </Form.Group>        
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="descriptionValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingDescription"
                                            required={!preCarga}
                                            type="text"
                                            value={description}
                                            placeholder="Ingrese la descripción"
                                            className={styles.input}
                                            onChange={(e) => { setDescription(e.target.value); }}
                                        />
                                        <label htmlFor="floatingDescription" className={styles.label}>Descripción</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} md={3} controlId="mandatarioValidation">
                                <Form.Check
                                    label="Por mandatario"
                                    type="switch"
                                    disabled={type === "IP"}
                                    name="porMandatorio"
                                    id="porMandatorio"
                                    style={{ accentColor: '#AD0039', marginTop: "auto", marginBottom: "auto", paddingBottom: "0px", paddingRight: "0px" }}
                                    defaultChecked={mandatorio}
                                    onChange={e => setMandatorio(e.target.checked) }
                                    className={styles.label}
                                />
                            </Form.Group>
                            {
                                mandatorio ? (
                                    <Form.Group as={Col}>
                                        <Form.Label className={styles.label}>Poder judicial</Form.Label>
                                        <Row>
                                            <Col md={10}>
                                                <Form.Control
                                                    required
                                                    type="file"                             
                                                    multiple={true}
                                                    placeholder="Seleccione los informes" 
                                                    className={styles.fileInput}
                                                    onChange={e => setAttachments(Array.from(e.target.files))}
                                                />   
                                            </Col>
                                        </Row>
                                        {
                                            (attachments && attachments.length > 0) ? 
                                                <Row className="mt-2">
                                                    {
                                                        attachments.map(file => (
                                                            <Col md="auto" style={{ paddingRight: "0px" }}>
                                                                <Badge bg="secondary">{file.name}</Badge>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            :
                                            <></> 
                                        }                            
                                    </Form.Group>
                                )
                                : <></>
                            }
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={patch} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

};

export default ComplainantAdd;