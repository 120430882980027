import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table, Button, Spinner } from "react-bootstrap";
import { AiOutlineSearch } from 'react-icons/ai';
import { useKeycloak } from "@react-keycloak/web";
import styles from "./History.module.css";
import axios from "axios";
import { CauseStatus } from "../../../../constants/CauseStatus";
import Diff from "./Diff/Diff";
import { MovementStatus } from "../../../../constants/MovementStatus";

const History = ( { cause, callback } ) => {

    const { keycloak } = useKeycloak();

    const [ history, setHistory ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ current, setCurrent ] = useState(undefined);
    const [ showInformation, setShowInformation ] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const findHistory = () => {
        setLoading(true);
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/causes/history/${cause.id}`, prepareHeaders())
            .then(res => {
                setHistory(res.status == 200 ? res.data.data : []);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
        }
        catch(e){
            setLoading(false);
        }
    }
    
    useEffect(() => findHistory(), []);

    return (
        <>
            <Diff cause={current} show={showInformation} handleClose={() => { setShowInformation(false); }}/>
            <Row>
                <Form.Label className={styles.groupLabel}>Historial de la causa</Form.Label>
            </Row>
            <Row>
                <Form>
                    <Row>
                        <Form.Group as={Col} style={{ textAlign: "right", marginTop: "auto", display: "flex" }}>
                            <Button onClick={() => findHistory()} bsPrefix={styles.primary} style={{ marginLeft: "auto" }}>Buscar {loading ? <Spinner as="span" style={{ width: "12px", height: "12px" }} animation="grow" /> : <AiOutlineSearch />}</Button>&nbsp;&nbsp;
                        </Form.Group>                        
                    </Row>
                </Form>
            </Row>
            <Row className="mt-4">
                {
                    loading ? (
                        <Row>
                            <Spinner animation="grow" role="status" style={{ marginLeft: "auto", marginRight: "auto"}}>
                                <span className="visually-hidden">Cargando...</span>
                            </Spinner>                                            
                        </Row>
                    )
                    : 
                    (
                        <>
                            {
                                history && history.length > 0 ? (
                                    <>
                                        <Table className={`${styles.table} mb-1`}>
                                            <thead>
                                                <th>ID</th>
                                                <th>Estado</th>
                                                <th>Estado causa</th>                                    
                                                <th>Fecha de modificación</th>
                                                <th>Modificado por</th>
                                                <th>Acciones</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    history.map(detail => (
                                                        <tr onClick={(e) => { e.preventDefault(); setCurrent(detail.cause); setShowInformation(true); }}>
                                                            <td>{detail.id}</td>
                                                            <td>{MovementStatus[detail.status].name}</td>
                                                            <td>{CauseStatus[detail.cause.status].name}</td>
                                                            <td>{detail.createdAt}</td>
                                                            <td>{detail.createdBy ? `${detail.createdBy.firstName} ${detail.createdBy.lastName}` : "Sistema"}</td>
                                                            <td><Button bsPrefix={styles.icon}>Ver información</Button></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                )
                                :
                                <span className={styles.noContent}>No hay historial</span>
                            }
                        </>
                    )
                }
            </Row>
        </>
    );

};

export default History;