import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Nav, Table } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { useKeycloak } from "@react-keycloak/web";

const Step5 = ( { next, prev, data } ) => {

    const { keycloak } = useKeycloak();

    const [current, setCurrent] = useState("testigos");


    const prepareHeaders = () => {
        return {
            headers: {
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        };
    }    
    
    const downloadFile = (e, fileName) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/content/${fileName}`, prepareHeaders())
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            });
        })
        .catch(error => {
            console.error(error);
        });        

    }

    return (
        <>

            <Form >
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Evidencias</Form.Label>
                    <Row style={{ marginTop: "0.2rem" }}>
                        <Col md={12}>
                            <Nav className={styles.pillsGroup} variant="pills" defaultActiveKey="testigos" onSelect={(selectedKey) => setCurrent(selectedKey)}>
                                <Nav.Item className={styles.pill}>
                                    <Nav.Link className={styles.pillLink} eventKey="testigos">Testigos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={styles.pill}>
                                    <Nav.Link className={styles.pillLink} eventKey="informes-periciales">Informes periciales</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={styles.pill}>
                                    <Nav.Link className={styles.pillLink} eventKey="documentos-adjuntos">Documentos adjuntos</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {
                                current === "testigos" ? (
                                    <>
                                        {
                                            data.witnesses && data.witnesses.length > 0 ? (
                                                <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                                                    <thead>
                                                        <th>Nombres</th>
                                                        <th>Apellidos</th>
                                                        <th>Fecha de nacimiento</th>
                                                        <th>Tipo de documento</th>
                                                        <th>Número de documento</th>
                                                        <th>Acciones</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.witnesses.map((witness) => (
                                                                <tr>
                                                                    <td>{witness.firstName}</td>
                                                                    <td>{witness.lastName}</td>
                                                                    <td>{witness.birthDate}</td>
                                                                    <td>{witness.document.type}</td>
                                                                    <td>{witness.document.number}</td>
                                                                    <td align="center"><Button bsPrefix={styles.iconBtn}>Ver</Button></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            )
                                            : 
                                            <Row>
                                                <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                                            </Row>
                                        }
                                    </>
                                )
                                :
                                current === "informes-periciales" ? (
                                    <>
                                        {
                                            (data.expertsReports && data.expertsReports.length > 0) ? (
                                                <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                                                    <thead>
                                                        <th>Nombre</th>
                                                        <th>Archivo</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.expertsReports.map((report) => (
                                                                <tr>
                                                                    <td>{report.fileName}</td>
                                                                    <td><button onClick={(e) => { downloadFile(e, report.fileName) }}>{report.fileName}</button></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            )
                                            : 
                                            <Row>
                                                <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                                            </Row>
                                        }
                                    </>
                                )
                                :
                                current === "documentos-adjuntos" ? (
                                    <>
                                        {
                                            data.attachments && data.attachments.length > 0 ? (
                                                <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                                                    <thead>
                                                        <th>Tipo</th>
                                                        <th>Nombre</th>
                                                        <th>Descripción</th>
                                                        <th>Ubicación</th>
                                                        <th>Archivos</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.attachments.map((attachment) => (
                                                                <tr>
                                                                    <td>{attachment.type}</td>
                                                                    <td>{attachment.name}</td>
                                                                    <td>{attachment.description}</td>
                                                                    <td>{attachment.location}</td>
                                                                    <td>{attachment.file ? <button onClick={(e) => { downloadFile(e, attachment.file.fileName) }}>{attachment.file.fileName}</button> : "-"}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            )
                                            : 
                                            <Row>
                                                <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                                            </Row>
                                        }
                                    </>
                                )
                                : 
                                <></>
                            }
                        </Col>
                    </Row>
                    
                </Form.Group>
            </Form>
        </>
    );

}

export default Step5;