import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button, Spinner, Badge } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import styles from "./DatosGeneralesEdit.module.scss";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

const DatosGeneralesEdit = ( { show, handleClose, callback, data } ) => {

    const [ loading, setLoading ] = useState(false);
    const [ type, setType ] = useState("ESCRITA");
    const [ resolutionNumber, setResolutionNumber ] = useState(undefined);
    const [ resolutionDescription, setResolutionDescription ] = useState(undefined);
    const [ causeReference, setCauseReference ] = useState(undefined);
    const [ file, setFile ] = useState(undefined);
    const [ reserved, setReserved ] = useState(false);
    const [ validated, setValidated ] = useState(false);    

    const { keycloak } = useKeycloak();    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const types = [
        {
            label: "Escrita",
            value: "ESCRITA"
        },
        {
            label: "Verbal",
            value: "VERBAL"
        },
        {
            label: "Por resolución",
            value: "RESOLUCION"
        }
    ];    

    const reservedOptions = [{ value: true, name: "Sí"}, {value: false, name: "No" }];

    const buildBody = () => {
        let elements = [];
        if(data.complaint.type != type){
            elements.push({
                action: "REPLACE",
                field: "TYPE",
                value: type
            });
        }
        if(data.complaint.reserved != reserved){
            elements.push({
                action: "REPLACE",
                field: "RESERVED",
                value: reserved
            });
        }
        if(resolutionNumber != undefined){
            elements.push({
                action: "REPLACE",
                field: "RESOLUTION_NUMBER",
                value: resolutionNumber
            });            
        }
        if(resolutionDescription != undefined){
            elements.push({
                action: "REPLACE",
                field: "RESOLUTION_DESCRIPTION",
                value: resolutionDescription
            });            
        }
        if(causeReference != undefined){
            elements.push({
                action: "REPLACE",
                field: "CAUSE_REFERENCE",
                value: causeReference
            });            
        }                
        return { data: elements };
    }

    const patch = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        //axios.patch(`http://localhost:8084/udea.deputy-prosecutor/api/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        .then(res => {
            toast.success("Datos actualizados", { position: "top-center"});
            setLoading(false);
            callback();
        })
        .catch(err => {
            toast.error("Ha ocurrido un error al actualizar los datos " + err.data ? (": " + err.data.message) : "", { position: "top-center"});
            setLoading(false);
        });
    }

    useEffect(() => {
        if(data){
            setType(data.complaint.type);
            setReserved(data.complaint.reserved);
            if(data.complaint.resolution){
                setResolutionNumber(data.complaint.resolution.number);
                setResolutionDescription(data.complaint.resolution.description);
                setFile(data.complaint.resolution.file);
            }
        }
    }, 
    [data]);
    
    return data ? (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Datos generales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form validated={validated}>
                        <Row className="mb-3 mt-2">
                            <Form.Group as={Col} controlId="typeValidation">
                                <Form.Label className={styles.label}>Tipo denuncia</Form.Label>
                                {
                                    types.map(
                                        ( current ) => (
                                            <Form.Check type="radio">
                                                <Form.Check.Input 
                                                    type="radio"
                                                    value={current.value}
                                                    onChange={(e) => { setType(e.target.value); }}
                                                    checked={type === current.value}
                                                    className={styles.checkbox}
                                                />
                                                <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                            </Form.Check>
                                        )
                                    )
                                }
                                <Form.Control.Feedback>{`Denuncia ${type} ✓`}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Debes seleccionar el tipo de la denuncia</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        {
                            type === "RESOLUCION" ? (
                                <>
                                    <Row className="mb-4">
                                        <Form.Label className={styles.label}>Datos de la resolución</Form.Label>
                                        <Form.Group as={Col} md={6}>
                                            <Form.Floating>
                                                <Form.Control 
                                                    id="floatingResolutionDescription"
                                                    type="text" 
                                                    placeholder="Ingrese la descripción"
                                                    className={styles.input} 
                                                    required 
                                                    value={resolutionDescription} 
                                                    onChange={(e) => { setResolutionDescription(e.target.value); }}
                                                />
                                                <label htmlFor="floatingResolutionDescription" className={styles.label}>Descripción</label>
                                            </Form.Floating>                                
                                        </Form.Group>              
                                        <Form.Group as={Col} md={6}>
                                            <Form.Floating>
                                                <Form.Control 
                                                    id="floatingResolutionNumber"
                                                    type="text" 
                                                    placeholder="Ingrese el número de resolución"
                                                    className={styles.input}
                                                    required 
                                                    value={resolutionNumber} 
                                                    onChange={(e) => { setResolutionNumber(e.target.value); }}
                                                />
                                                <label htmlFor="floatingResolutionNumber" className={styles.label}>Número</label>
                                            </Form.Floating>              
                                        </Form.Group>                                                                                        
                                        {
                                            file ? (
                                                <Form.Group as={Col} md={12} className="mt-2">                      
                                                    <a download={file.fileName} href={file.payload}>
                                                        <Badge bg="secondary">{file.fileName}</Badge>
                                                    </a>
                                                </Form.Group>                                                           
                                            )
                                            :
                                            <></>
                                        }                                           
                                    </Row>
                                </>
                            )
                            : 
                            <></>
                        }
                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="reservedValidation">
                                <Form.Label className={styles.label}>¿La denuncia es reservada?</Form.Label>
                                {
                                    reservedOptions.map(
                                        ( current ) => (
                                            <Form.Check type="radio">
                                                <Form.Check.Input 
                                                    required
                                                    type="radio"
                                                    value={current.value}
                                                    onChange={(e) => { setReserved(e.target.value); }}
                                                    checked={reserved == current.value}
                                                    className={styles.checkbox}                                    
                                                />
                                                <Form.Check.Label className={styles.checkboxLabel}>{current.name}</Form.Check.Label>
                                            </Form.Check>
                                        )
                                    )                        
                                }
                                <Form.Control.Feedback>{`Denuncia ${reserved ? "reservada" : "no reservada"} ✓`}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Debes marcar si es una denuncia reservada o no</Form.Control.Feedback>                        
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Label className={styles.label}>Nro. SIGEDE</Form.Label>
                            <Form.Group as={Col} md={12}>
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingCauseReference"
                                        type="text" 
                                        className={styles.input}
                                        value={causeReference}
                                        onChange={(e) => { setCauseReference(e.target.value); }}
                                    />
                                    <label htmlFor="floatingCauseReference" className={styles.label}>Número de referencia</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>            
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={patch} disabled={loading}>
                        {
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

}

export default DatosGeneralesEdit;