import React, { useState, useEffect } from "react";
import styles from "./ImputationLapse.module.css";
import { Table, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../../../components/pagination/CustomPagination";
import axios from "axios";
import { AiOutlineSearch } from 'react-icons/ai';

const ImputationLapse = () => {

    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [ causes, setCauses ] = useState([]);
    const [ prosecutors, setProsecutors ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const [ causeId, setCauseId ] = useState(undefined);
    const [ prosecutor, setProsecutor ] = useState({});
    const [ start, setStart ] = useState(undefined);
    const [ end, setEnd ] = useState(undefined);    

    const [ loadingExport, setLoadingExport ] = useState(false);

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",                
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const prepareHeadersBlob = () => {
        return {
            headers: {
                "Accept": "application/octet-stream",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    };    

    const findCauses = () => {
        setLoading(true);
        let params = buildParams();
        axios.get(`${process.env.REACT_APP_API_URL}/causes/history/top/elapsed/time` + params, prepareHeaders())
        .then(res => {
            setCauses(res.status == 200 ? res.data.data : []);
            setLoading(false);
        })
        .catch(err => {
            setCauses([]);
            setLoading(false);
        });
    };

    const buildParams = () => {
        let query = "?";
        if(causeId){ query += `causeId=${causeId}&` }
        if(start){ query += `start_date=${start}&` }
        if(end){ query += `end_date=${end}&` }        
        if(prosecutor.id && prosecutor.firstName != "TODOS"){ 
            query += `prosecutorId=${prosecutor.id}&`; 
            query += `prosecutor_id=${prosecutor.id}&`;
        }
        return query;
    }

    const findProsecutors = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/prosecutors`, prepareHeaders())        
        .then(res => { setProsecutors(res.status != 200 ? [] : res.data); })
        .catch(err => { console.log(err); });
    }    

    const buildDetailsParams = () => {
        let params = buildParams();
        params += "&type=IMPUTED";
        return params;
    }    

    const exportCauses = (e) => {
        e.preventDefault();
        setLoadingExport(true);
        axios({
            url: process.env.REACT_APP_API_URL + "/deputy/prosecutors/dashboard/export" + buildDetailsParams(),
            method: 'GET',
            responseType: 'blob',
            ...prepareHeadersBlob()
        })
        .then((response) => {
            if(response.status == 204){
                setLoadingExport(false);                
                return;
            }
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'reporte_causas.xlsx';
            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) { 
                  filename = matches[1].replace(/['"]/g, '');
                }
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            setLoadingExport(false);
        })
        .catch((error) => {
            console.error('Hubo un error al descargar el archivo: ', error);
            setLoadingExport(false);            
        });        
    }    

    useEffect(() => { findCauses(); findProsecutors(); }, []);

    return (
        <>
            <Row>
                <Form.Label className={styles.groupLabel}>Causas imputadas</Form.Label>
            </Row>
            <Row>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label bsPrefix={styles.label}>Causa</Form.Label>
                            <Form.Control className={styles.input} value={causeId} onChange={(e) => setCauseId(e.target.value)} type="number" placeholder="Número de la causa"/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label bsPrefix={styles.label}>Fiscal principal</Form.Label>
                            <Form.Control className={styles.input}  as="select" value={JSON.stringify(prosecutor)} onChange={(e) => { setProsecutor(JSON.parse(e.target.value))}}>
                                <option value={JSON.stringify({firstName: "TODOS"})} selected>TODOS</option>
                                {
                                    prosecutors.map((prosecutor) => (
                                        <option value={JSON.stringify(prosecutor)}>{`${prosecutor.firstName} ${prosecutor.lastName} (Unidad ${prosecutor.division ? prosecutor.division : "-"})`}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label bsPrefix={styles.label} >Fecha inicio</Form.Label>
                            <Form.Control className={styles.input} value={start} onChange={(e) => setStart(e.target.value)} type="date" />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label bsPrefix={styles.label} >Fecha fin</Form.Label>
                            <Form.Control className={styles.input} value={end} onChange={(e) => setEnd(e.target.value)} type="date" />
                        </Form.Group>                        
                        <Form.Group as={Col} style={{ textAlign: "right", marginTop: "auto", display: "flex" }}>
                            <Button onClick={() => findCauses()} bsPrefix={styles.primary} style={{ marginLeft: "auto" }}>Buscar {loading ? <Spinner as="span" style={{ width: "12px", height: "12px" }} animation="grow" /> : <AiOutlineSearch />}</Button>&nbsp;&nbsp;
                        </Form.Group>                        
                    </Row>
                </Form>
            </Row>
            <Row className="mt-4">
                {
                    causes && causes.length > 0 ? (
                        <>
                            {
                                loadingExport ? (
                                    <Spinner animation="grow" className="mb-2"/>
                                )
                                : (
                                    <a alt="Descargar reporte" href="#" onClick={exportCauses} style={{ marginBottom: "1rem", paddingLeft: "7px" }}>Descargar</a>
                                )
                            }
                            <Table className={`${styles.table} mb-1`}>
                                <thead>
                                    <th>Causa</th>
                                    <th>Fiscal</th>
                                    <th>Recibida el</th>
                                    <th>Asignada el</th>
                                    <th>Imputada el</th>
                                    <th>Días hasta la imputación</th>
                                </thead>
                                <tbody>
                                    {
                                        causes.map(cause => (
                                            <tr onClick={(e) => { e.preventDefault(); navigate(`/causas/${cause.causeId}`); }}>
                                                <td>{cause.causeId}</td>
                                                <td>{`${cause.prosecutor.firstName} ${cause.prosecutor.lastName}`}</td>
                                                <td>{cause.receivedAt}</td>
                                                <td>{cause.assignedAt}</td>
                                                <td>{cause.imputedAt}</td>
                                                <td>{cause.daysElapsedUntilImputation}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </>                            
                    )
                    :
                    <span className={styles.noContent}>No hay causas imputadas</span>
                }
            </Row>
        </>
        
    )

};

export default ImputationLapse;