export const Feature = {
    "ORGANIZED_STRUCTURE": {
        name: "Crimen organizado"
    },
    "POLITICAL_PRIORITY_PROFILE_LEVEL_1": {
        name: "Perfil político prioritario nivel 1"
    },
    "POLITICAL_PRIORITY_PROFILE_LEVEL_2": {
        name: "Perfil político prioritario nivel 2"
    },
    "DAMAGED_INSTITUTIONS": {
        name: "Daño al patrimonio"
    },
    "SERIOUS_PUNISHABLE_ACTS": {
        name: "Hechos punibles severos"
    }
};