import React, { useState, useEffect } from "react";
import { Row, Col, Form, Badge, Button } from "react-bootstrap";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import styles from "./EconomicCrimesUnitComplaint.module.css";
import { BsNewspaper, BsFillPeopleFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { SlOptions } from "react-icons/sl";
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineFileSearch } from "react-icons/ai";
import { TbMinusVertical, TbListCheck } from "react-icons/tb";
import { AiOutlineFileDone } from "react-icons/ai";
import { IoDocumentsOutline } from "react-icons/io5";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { EconomicCrimesUnitComplaintStatus } from "../../../constants/EconomicCrimesUnitComplaintStatus";
import JudgmentModal from "./JudgmentModal/JudgmentModal";
import Step6 from "./Steps/Step6";

const EconomicCrimesUnitComplaint = ( { data, callback } ) => {

    const [ step, setStep ] = useState(1);
    const [ showEditDate, setShowEditDate ] = useState(false);
    const [ showJudgmentModal, setShowJudgmentModal ] = useState(false);
    const [ current, setCurrent ] = useState(undefined);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const prepareHeadersString = () => {
        return {
            headers: {
                "Content-Type": "text/plain",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const resolveActions = ( data ) => {
        switch(data.status){
            case EconomicCrimesUnitComplaintStatus.RECEIVED.code:
                return (
                    <Dropdown.Item onClick={(e) => { judge(e, data); }} className={styles.optionItem}>
                        <AiOutlineFileDone />&nbsp;
                        Emitir dictamen
                    </Dropdown.Item>
                );
            default:
                return ( <></> );
        }
    }

    const hasOptions = () => data.status === EconomicCrimesUnitComplaintStatus.RECEIVED.code;

    const judge = (e, data) => {
        e.preventDefault();
        setCurrent(data);
        setShowJudgmentModal(true);
    }
 
    return (
        <div className={styles.container}>
            <JudgmentModal data={current} show={showJudgmentModal} handleClose={() => { setShowJudgmentModal(false); }} callback={callback}/>
            <Row>
                <Col md={8}>
                    <Form.Label className={styles.title}>{`Nº ${data.complaint.id}`}</Form.Label>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                    <div className={styles.actionsContainer}>
                        <Badge className={styles.status} pill>{EconomicCrimesUnitComplaintStatus[data.status].name}</Badge>
                        {
                            hasOptions() ? (
                                <Dropdown align="end">
                                    <Dropdown.Toggle className={styles.option} id="dropdown-button-dark-example1" variant="secondary">
                                        <SlOptions />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        { resolveActions(data) }
                                    </Dropdown.Menu>
                                </Dropdown>                                                    
                            )
                            :
                            <></>
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <Col md="auto">
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <BsNewspaper className={`${styles.navIcon} ${step === 1 ? styles.active : ""}`} onClick={ (e) => { setStep(1) }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <BsFillPeopleFill className={`${styles.navIcon} ${step === 2 ? styles.active : ""}`} onClick={ (e) => { setStep(2) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <FaMapMarkedAlt className={`${styles.navIcon} ${step === 3 ? styles.active : ""}`} onClick={ (e) => { setStep(3) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbListCheck className={`${styles.navIcon} ${step === 4 ? styles.active : ""}`} onClick={ (e) => { setStep(4) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                       
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <AiOutlineFileSearch className={`${styles.navIcon} ${step === 5 ? styles.active : ""}`} onClick={ (e) => { setStep(5) }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <IoDocumentsOutline className={`${styles.navIcon} ${step === 6 ? styles.active : ""}`} onClick={ (e) => { setStep(6) }}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {
                        step === 1 ? <Step1 data={data.complaint} /> : (
                            step === 2 ? <Step2 data={data.complaint}/> : (
                                step === 3 ? <Step3 data={data.complaint}/> : (
                                    step === 4 ? <Step4 data={data.complaint}/> : (
                                        step === 5 ? <Step5 data={data.complaint}/> : (
                                            step === 6 ? <Step6 data={data}/> : <></>
                                        )
                                    )
                                )
                            )
                        )
                    }
                </Col>
            </Row>
            <Row style={{ display: "flex" }}>
                {
                    step > 1 && step < 6 ? (
                        <>
                            <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-1) }}>ANTERIOR</Button>
                            </Col>
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+1) }}>SIGUIENTE</Button>
                            </Col>                        
                        </>
                    )
                    : step === 1 ? (
                        <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+1) }}>SIGUIENTE</Button>
                        </Col>
                    )
                    : (
                        <Col md={12} style={{ marginRight: "auto", textAlign: "left" }}>
                            <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-1) }}>ANTERIOR</Button>
                        </Col>
                    )
                }
            </Row>
        </div>
    )

}

export default EconomicCrimesUnitComplaint;