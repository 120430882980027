import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactComponent as Logo } from '../assets/logo.svg';

const NotFound = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <div className='central-block'>
            <div className='left-block'>                
                    <Logo height={150} />                
            </div>
            <hr />
            <div className='right-block'>
                <div>
                    <span className='title'>404</span>
                    <span className='subtitle'>Not Found</span>
                </div>
                <span>Parece que la página que buscas no existe o no cuentas con los permisos suficientes para acceder a ella</span>
            </div>
            {window.location.assign('/')}
        </div>
    );
}

export default NotFound