import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from "react-bootstrap";
import styles from "./DashboardMesaEntrada.module.css";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} 
from 'chart.js';
import { Bar } from 'react-chartjs-2';
import NumberDisplay from '../../../components/charts/NumberDisplay';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const DashboardMesaEntrada = () => {

    const [ year, setYear ] = useState(new Date().getFullYear());
    const [ dashboard, setDashboard ] = useState(undefined);
    const { keycloak } = useKeycloak();    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    } 

    const colors = ['#4BC0C0', '#FF6384'];    

    const mapDashboard = ( data ) => {
        let element = { total: 0 };
        let datasets = [];
        let index = 0;
        for(let current of data){
            if(current.year == year){
                element.total = current.total;
            }
            datasets.push({
                label: `${current.year} (${current.total})`,
                data: current.monthsQuantity,
                backgroundColor: colors[index]
            });
            index++;
        }
        element.data = { labels, datasets};
        return element;
    }

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: 'Cantidad de denuncias recibidas por mes/año',
          },
        },
    };    

    const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const callDashboard = () => {
        axios.get(process.env.REACT_APP_API_URL + "/dashboard" + (year ? ("?year=" + year) : ""), prepareHeaders())
        .then(res => {
            setDashboard(res.status === 204 ? undefined : mapDashboard(res.data));
        })
        .catch(error => {
            setDashboard(undefined);
        })
    };

    useEffect(() => { callDashboard(); }, []);
    useEffect(() => { callDashboard(); }, [year]);

    return (
        <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <Col md={2}>
                <div className={styles.sideBar}>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Group>
                            <Form.Label className={styles.label} as={Col}>Año</Form.Label>
                            <Form.Control className={styles.input} type="number" value={year} onChange={(e) => { setYear(e.target.value); }} />
                        </Form.Group>
                    </Row>
                </div>
            </Col>
            <Col md={10}>
                <div className={styles.container}>
                    {
                        dashboard ? (
                            <>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <NumberDisplay value={dashboard.total} title={(<>Denuncias<br></br>ingresadas</>)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Bar options={options} data={dashboard.data} />
                                    </Col>                                
                                </Row>
                            </>
                        )
                        : <span>No existen datos</span>
                    }
                </div>
            </Col>
        </Row>
    )
}

export default DashboardMesaEntrada