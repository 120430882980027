import React from "react";
import { Button, Form, Col, Row, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss"

const Step4 = ( { next, prev, data } ) => {

    return (
        <>
            <Form >
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Datos del hecho</Form.Label>
                    <Row style={{ marginTop: "0.2rem" }}>
                        <Col md={3}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Posible fecha del hecho</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.possibleDate} disabled/>
                        </Col>
                        <Col md={9}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Relato conciso de los hechos</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.detailedAccountOfThePunishableAct} disabled />
                        </Col>
                    </Row>
                    {
                        data.statementOfFact.crimes ? (
                            <Row style={{ marginTop: ".5rem" }}>
                                <Col md={12}>
                                    <Form.Label as={Col} bsPrefix={styles.label}>Hechos punibles</Form.Label>  
                                    {
                                        data.statementOfFact.crimes.map((crime) => (
                                            <Badge bg="secondary" style={{ marginRight: ".5rem"}}>{`Art. ${crime.article} ${crime.name}`}</Badge>
                                        ))
                                    }                          
                                </Col>
                            </Row>                            
                        )
                        : 
                        <></>
                    }
                </Form.Group>
                {
                    (data.statementOfFact.estimatedAmountOfDamage && data.statementOfFact.relatedInstitutions) ? (
                        <Form.Group className={styles.group}>
                            <Form.Label className={styles.groupLabel}>Daño estimado</Form.Label>
                            <Row style={{ marginTop: "0.2rem" }}>
                                {
                                    data.statementOfFact.estimatedAmountOfDamage ? (
                                        <Col md={3}>
                                            <Form.Label as={Col} bsPrefix={styles.label}>Monto</Form.Label>
                                            <Form.Control bsPrefix={styles.input} type="number" value={data.statementOfFact.estimatedAmountOfDamage.value} disabled/>
                                        </Col>                                    
                                    )
                                    : <></>
                                }
                                {
                                    data.statementOfFact.relatedInstitutions ? (
                                        <Col md={9}>
                                            <Form.Label as={Col} bsPrefix={styles.label}>Instituciones afectadas</Form.Label>
                                            {
                                                data.statementOfFact.relatedInstitutions.map((institution) => (
                                                    <Badge bg="secondary" style={{ marginRight: ".5rem"}}>{institution}</Badge>
                                                ))                                            
                                            }
                                        </Col>
                                    )
                                    : <></>
                                }
                            </Row>
                        </Form.Group>                
                    )
                    : <></>

                }
            </Form>
        </>
    );

}

export default Step4;