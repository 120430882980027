export const AlertType = {
    "PRINCIPAL_PROSECUTOR_ASSIGNATION": {
        name: "Causa asignada"
    },
    "DISASSOCIATION_REQUEST": {
        name: "Solicitud de inhibición"
    },
    "DISASSOCIATION_REPLY": {
        name: "Respuesta a solicitud de inhibición"
    }
};