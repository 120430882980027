import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Badge } from "react-bootstrap";
import { RiCloseFill } from "react-icons/ri";
import styles from "../DenunciaForm.module.scss";
import { BsPlusLg } from "react-icons/bs";
import { useKeycloak } from "@react-keycloak/web";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from "axios";

const Perjudicados = ( { next, previous, injuredParties, setInjuredParties } ) => {

    const [ validated, setValidated ] = useState(false);
    const [ injuredPartiesOptions, setInjuredPartiesOptions ] = useState([]);
    const [ name, setName ] = useState(undefined);
    const { keycloak } = useKeycloak();        

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const handleNext = (e) => {
        e.preventDefault();
        next(injuredParties);
    }    

    const skip = (e) => {
        e.preventDefault();
        next([]);
    }    

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(injuredParties);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(true);
            setInjuredParties([...injuredParties, name]);
            clear();
        }
    };    

    const remove = (event, injured) => {
        event.preventDefault();
        let aux = [];
        for(let current of injuredParties){
            if(current !== injured){
                aux.push(current);
            }
        }
        setInjuredParties(aux);
    }

    const clear = () => setName("");

    const retrieveInjuredParties = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/injuredParties`, prepareHeaders())
        .then(res => setInjuredPartiesOptions(res.status == 204 ? [] : res.data))
        .catch(err => {
            console.log(err);
            setInjuredPartiesOptions([]);
        });
    }

    const handleChange = (event, value, reason, details) => {
        event.preventDefault();
        setInjuredParties(value);
    }

    useEffect(() => retrieveInjuredParties(), []);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mt-2 mb-2">
                <Form.Label className={styles.groupLabel}>Perjudicados</Form.Label>
                <Autocomplete 
                    multiple id="tags-filled" options={injuredPartiesOptions.map((option) => option.name)} freeSolo 
                    value={injuredParties}
                    onChange={handleChange}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => ( 
                            <Chip 
                                variant="outlined" 
                                label={option} 
                                {...getTagProps({ index })} 
                            />
                        ))
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            label="Instituciones perjudicadas"
                        />
                    )}
                />
            </Row>
            <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                    <Button bsPrefix={styles.next} onClick={handlePrevious} >ANTERIOR</Button>
                </Col>
                {
                    injuredParties.length > 0 ? (
                        <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={handleNext}>SIGUIENTE</Button>
                        </Col>                    
                    )
                    : 
                    (
                        <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={skip}>OMITIR</Button>
                        </Col>                    
                    )
                }
            </Row>            
        </Form>
    );

}

export default Perjudicados;