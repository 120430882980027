import React, { useState } from "react";
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import styles from "./WitnessAdd.module.css";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

const WitnessAdd = ({ show, handleClose, callback, data }) => {

    const [ preCarga, setPreCarga ] = useState(true);
    const [ loading, setLoading ] = useState(false);    

    const [ firstName, setFirstName ] = useState(undefined);
    const [ lastName, setLastName ] = useState(undefined);
    const [ birthDate, setBirthDate ] = useState(undefined);
    const [ documentType, setDocumentType ] = useState("CI");
    const [ documentValue, setDocumentValue ] = useState(undefined);     

    const { keycloak } = useKeycloak();          

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const buildBody = () => {
        let elements = [
            {
                action: "ADD",
                field: "WITNESS",
                value: {
                    firstName,
                    lastName,
                    document: {
                        type: documentType,
                        number: documentValue
                    },
                    birthDate
                }
            }
        ]
        return { data: elements };
    }    

    const patch = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        .then(
            res => {
                toast.success("Datos actualizados", { position: "top-center"});
                setLoading(false);
                callback();
            }
        )
        .catch(err => {
            toast.error("Ha ocurrido un error al actualizar los datos", { position: "top-center"});
            setLoading(false);
        });
    }    

    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar testigo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Datos personales</Form.Label>
                            <Form.Group as={Col} controlId="nameValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required
                                        id="floatingName"
                                        type="text" 
                                        value={firstName}
                                        onChange={(e) => { setFirstName(e.target.value); }}
                                        placeholder="Ingrese el nombre del denunciante" 
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingName">Nombres</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastNameValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required
                                        value={lastName}
                                        onChange={(e) => { setLastName(e.target.value); }}                        
                                        className={styles.input}
                                        type="text" 
                                        placeholder="Ingrese el nombre del denunciante" 
                                    />
                                    <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="birthDateValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingBirthDate"
                                        required={!preCarga}
                                        value={birthDate}
                                        onChange={(e) => { setBirthDate(e.target.value); }}                        
                                        className={styles.input}
                                        type="date" 
                                    />
                                    <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                                </Form.Floating>
                            </Form.Group>                           
                        </Row>
                        <Row className="mb-4">
                            <Form.Label className={styles.groupLabel}>Documento de identidad</Form.Label>
                            <Form.Group as={Col} controlId="documentTypeValidation">
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingDocumentType" 
                                        className={styles.input} 
                                        required={!preCarga}
                                        as="select" 
                                        value={documentType}
                                        onChange={(e) => {setDocumentType(e.target.value)}}
                                    >
                                        <option value="CI">Cédula de identidad</option>
                                        <option value="PAS">Pasaporte</option>
                                    </Form.Control>
                                    <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="documentValueValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        required={!preCarga}
                                        id="floatingDocumentValue"
                                        type={documentType === "CI" ? "number" : "text"}
                                        value={documentValue} 
                                        className={styles.input}
                                        onChange={(e) => { setDocumentValue(e.target.value); }} 
                                        placeholder="Ingrese el número de documento"
                                    />
                                    <label htmlFor="floatingDocumentValue" className={styles.label}>Número de documento</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>
                    </Form>                
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={patch} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

};

export default WitnessAdd;