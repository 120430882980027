export const Complexity = {
    "LOW": {
        name: "baja",
        style: "success"
    },
    "MEDIUM": {
        name: "media",
        style: "warning"
    },
    "HIGH": {
        name: "alta",
        style: "danger"
    }
};