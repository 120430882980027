import React, { useState } from "react";
import { Modal, Form, Row, Col, Button, Badge, Spinner } from "react-bootstrap";
import styles from "./ExpertReportAdd.module.css";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const ExpertReportAdd = ({ show, handleClose, callback, data }) => {

    const { keycloak } = useKeycloak();

    const [ files, setFiles ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const prepareHeadersMultipart = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    
    
    const uploadFiles = async (e) => {
        e.preventDefault();
        if(files && files.length > 0){
            if(files.filter(file => file.id).length === 0){
                let formData = new FormData();
                for (let file of files) {
                    formData.append('files', file);
                }
                await axios.post(process.env.REACT_APP_API_URL + "/files", formData, prepareHeadersMultipart())
                .then(res => {
                    let aux = [];
                    for(let current of files){
                        for(const saved of res.data){
                            if(current.name === saved.fileName.replace(`${saved.id}-`, '')){
                                current.id = saved.id;
                                break;
                            }
                        }
                        aux.push(current);
                    }
                    setFiles(aux);
                    patch();
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else {
                handleClose();
            }
        }
        else {
            handleClose();
        }
    }    

    const buildBody = () => {
        let elements = [];
        for(let i = 0; i < files.length; i++){
            elements.push(
                {
                    action: "ADD",
                    field: "EXPERT_REPORT",
                    value: { id: files[i].id, fileName: files[i].name }
                }
            );
        }
        return { data: elements };
    }    

    const patch = () => {
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        //axios.patch(`http://localhost:8084/udea.deputy-prosecutor/api/causes/${data.id}/complaint`, buildBody(), prepareHeaders())    
        .then(
            res => {
                toast.success("Datos actualizados", { position: "top-center"});
                setLoading(false);
                callback();
            }
        )
        .catch(err => {
            console.log(err);
            toast.error("Ha ocurrido un error al actualizar los datos", { position: "top-center"});
            setLoading(false);
        });
    }    

    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar informe pericial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mt-2 mb-4">
                            <Form.Group as={Col} controlId="reportsValidation">
                                <Row>
                                    <Col md={12}>
                                        <Form.Control
                                            type="file"
                                            multiple
                                            onChange={(e) => { setFiles(Array.from(e.target.files)); }}
                                            placeholder="Seleccione los informes" 
                                            className={styles.fileInput}
                                        />                    
                                    </Col>
                                </Row>
                                {
                                    (files && files.length > 0) ? 
                                        <Row className="mt-2">
                                            {
                                                files.map(file => (
                                                    <Col md="auto" style={{ paddingRight: "0px" }}><Badge bg="secondary">{file.name}</Badge></Col>
                                                ))
                                            }
                                        </Row>                                
                                    : 
                                    <></> 
                                }
                            </Form.Group>
                        </Row>
                    </Form>       
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={uploadFiles} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

};

export default ExpertReportAdd;