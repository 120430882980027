import React, { Children } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import styles from "./Stepper.module.css";

const Stepper = ( { steps, current, children } ) => {

    return (
        <Container>
            <Row className="mb-3" style={{ justifyContent: "space-between" }}>
                { steps.map( step => <Step step={step} current={current} /> ) }
            </Row>
            <Row style={{ justifyContent: "space-between" }}>
                { Children.map( children, child => <> { child.props.step === current ? child.props.children : <></> } </> ) }
            </Row>
        </Container> 
    );

}

const Step = ( { step, current } ) => {
    return (
        <Col>
            <Row>
                <Button className={`rounded-circle ${styles.step} ${step.id === current ? styles.current : (step.id < current ? styles.passed : styles.default)}`}>{ step.id < current ? '✓' : step.code }</Button>
            </Row>
            <Row style={{ marginTop: ".5rem" }}>
                <span className={step.id === current ? styles.stepLabelCurrent : styles.stepLabel}>{ step.label }</span>
            </Row>
        </Col>
    );
}

export default Stepper;