import React, { useState, useEffect } from "react";
import { Button, Form, Table, Col, Row } from "react-bootstrap";
import { FiTrash } from "react-icons/fi";
import { AiOutlineFileSearch } from "react-icons/ai";
import styles from "../DenunciaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DefendantModal from "../../modals/DefendantModal";
import axios from "axios";

const Denunciados = ( { next, previous, defendants, setDefendants } ) => {

    const [ preCarga, setPreCarga ] = useState(false);

    const [ validated, setValidated ] = useState(false);
    const [ firstName, setFirstName ] = useState(undefined);
    const [ lastName, setLastName ] = useState(undefined);
    const [ birthDate, setBirthDate ] = useState(undefined);
    const [ documentType, setDocumentType ] = useState("CI");
    const [ documentValue, setDocumentValue ] = useState(undefined);

    const [ department, setDepartment ] = useState(undefined);
    const [ city, setCity ] = useState(undefined);
    const [ neighborhood, setNeighborhood ] = useState(undefined); 
    const [ principalStreet, setPrincipalStreet ] = useState(undefined);
    const [ secondaryStreet, setSecondaryStreet ] = useState(undefined);
    const [ houseNumber, setHouseNumber ] = useState(undefined);

    const [ publicOfficial, setPublicOfficial ] = useState(false);
    const [ publicOfficialTitle, setPublicOfficialTitle ] = useState(undefined);
    const [ pep, setPep ] = useState(false);
    const [ pepTitle, setPepTitle ] = useState(undefined);
    const [ description, setDescription ] = useState(undefined);
    const [ cartera, setCartera ] = useState(undefined);
    
    const [ pepTitles, setPepTitles] = useState([]);

    const [ showDefendant, setShowDefendant ] = useState(false);
    const [ currentDefendant, setCurrentDefendant ] = useState(undefined);    

    const [ departments, setDepartments ] = useState([]);
    const [ neighborhoods, setNeighborhoods ] = useState([]);
    const [ cities, setCities ] = useState([]);

    const { keycloak } = useKeycloak();       

    const handleNext = (e) => {
        e.preventDefault();
        next(defendants);
    }    

    const skip = (e) => {
        e.preventDefault();
        next([]);
    }       

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(defendants);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(true);
            setDefendants([
                ...defendants, 
                {
                    firstName,
                    lastName, 
                    birthDate,
                    document: {
                        type: documentType,
                        number: documentValue
                    },
                    address: {
                        department,
                        city,
                        neighborhood,
                        street: {
                            principal: principalStreet,
                            secondary: secondaryStreet
                        },
                        description,
                        houseNumber
                    },
                    publicOfficial,
                    publicOfficialTitle,
                    pep,
                    pepTitle,
                    pepInstitution: cartera
                }
            ]);
            clear();
        }
    };   
    
    const clear = () => {
        setFirstName("");
        setLastName("");
        setBirthDate("");
        setDocumentType("CI");
        setDocumentValue("")
        setDepartment(undefined);
        setCity(undefined);
        setNeighborhood(undefined);
        setPrincipalStreet("");
        setSecondaryStreet("");
        setDescription("");
        setHouseNumber("");
        setPublicOfficial(false);
        setPublicOfficialTitle(undefined);
        setPep(false);
        setPepTitle(undefined);
        setCartera(undefined);
    };

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }     

    const removeDefendant = (e, defendant) => {
        e.preventDefault();
        let aux = [];
        for(let current of defendants){
            if(current !== defendant){
                aux.push(current);
            }
        }
        setDefendants(aux);
    }    
    
    const findPep = () => {
        axios.get(process.env.REACT_APP_API_URL + "/pep", prepareHeaders())
        .then(res => setPepTitles(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findNeighborhoods = ( cityId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`, prepareHeaders())
        .then(res => setNeighborhoods(res.status == 200 ? res.data : []))
        .catch(error => console.error(error));
    };

    const handleChangeNeighborhood = (event, value, reason, details) => {
        event.preventDefault();
        setNeighborhood(typeof value == 'object' ? value : { name: value });
    }    

    const handleChangePep = (event, value, reason, details) => {
        setPepTitle(value);
    }

    const returnValue = (value) => {
        try { return JSON.parse(value).name; }
        catch(e){ return value; }
    }    

    useEffect(() => { findDeparments(); findPep(); }, []);
    useEffect(() => { if(department) { findCities(department.id); }}, [ department ]);
    useEffect(() => { if(city) { findNeighborhoods(city.id); }}, [ city ]);    

    return (
        <>
            <DefendantModal data={currentDefendant} show={showDefendant} handleClose={() => setShowDefendant(false)}/>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-4">
                    <Form.Label className={styles.groupLabel}>Datos personales</Form.Label>
                    <Form.Group as={Col} controlId="nameValidation">
                        <Form.Floating>
                            <Form.Control 
                                required
                                id="floatingName"
                                type="text" 
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value); }}
                                placeholder="Ingrese el nombre del denunciante" 
                                className={styles.input}
                            />
                            <label className={styles.label} htmlFor="floatingName">Nombres</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="lastNameValidation">
                        <Form.Floating>
                            <Form.Control 
                                required
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value); }}                        
                                className={styles.input}
                                type="text" 
                                placeholder="Ingrese el nombre del denunciante" 
                            />
                            <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="birthDateValidation">
                        <Form.Floating>
                            <Form.Control 
                                id="floatingBirthDate"
                                required={false}
                                value={birthDate}
                                onChange={(e) => { setBirthDate(e.target.value); }}                        
                                className={styles.input}
                                type="date" 
                            />
                            <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                        </Form.Floating>
                    </Form.Group>                           
                </Row>
                <Row className="mb-4">
                    <Form.Label className={styles.groupLabel}>Documento de identidad</Form.Label>
                    <Form.Group as={Col} controlId="documentTypeValidation">
                        <Form.Floating>
                            <Form.Control
                                id="floatingDocumentType" 
                                className={styles.input} 
                                required={false}
                                as="select" 
                                value={documentType}
                                onChange={(e) => {setDocumentType(e.target.value)}}
                            >
                                <option value="CI">Cédula de identidad</option>
                                <option value="PAS">Pasaporte</option>
                            </Form.Control>
                            <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="documentValueValidation">
                        <Form.Floating>
                            <Form.Control 
                                required={false}
                                id="floatingDocumentValue"
                                type={documentType === "CI" ? "number" : "text"}
                                value={documentValue} 
                                className={styles.input}
                                onChange={(e) => { setDocumentValue(e.target.value); }} 
                                placeholder="Ingrese el número de documento"
                            />
                            <label htmlFor="floatingDocumentValue" className={styles.label}>Número de documento</label>
                        </Form.Floating>
                    </Form.Group>
                </Row>
                <Row className="mb-4">
                    <Form.Label className={styles.groupLabel}>Domicilio</Form.Label>
                    <Form.Group className="mb-4" as={Col} controlId="departamentValidation">
                        <Form.Floating>
                            <Form.Select 
                                id="floatingDepartment" 
                                className={styles.input} 
                                required={false} 
                                value={JSON.stringify(department)} 
                                onChange={(e) => { setDepartment(JSON.parse(e.target.value)); }}
                            >
                                <option value="" selected>Seleccione</option>
                                {
                                    departments.map((current) => (
                                        <option value={JSON.stringify(current)}>{current.name}</option>
                                    ))
                                }
                            </Form.Select>
                            <label htmlFor="floatingDepartment" className={styles.label}>Departamento</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="cityValidation">
                        <Form.Floating>
                            <Form.Select 
                                id="floatingCity" 
                                className={styles.input} 
                                required={false} 
                                value={JSON.stringify(city)} 
                                onChange={(e) => { setCity(JSON.parse(e.target.value)); }}
                            >
                                <option value="" selected>Seleccione</option>
                                {
                                    cities.map((current) => (
                                        <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                    ))
                                }
                            </Form.Select>
                            <label htmlFor="floatingCity" className={styles.label}>Ciudad</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="neighborhoodValidation">

                        <Autocomplete 
                            options={neighborhoods}
                            getOptionLabel={(option) => typeof option == "object" ? option.name : returnValue(option)}
                            value={neighborhood}
                            freeSolo
                            autoSelect
                            onChange={handleChangeNeighborhood}
                            renderInput={(params) => ( <TextField {...params} label="Barrio / Localidad" variant="standard" /> )}
                        />

                    </Form.Group>
                    <Row className="mb-4">
                        <Form.Group as={Col} controlId="principalStreetValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingPrincipalStreet"
                                    required={false}
                                    type="text"
                                    value={principalStreet}
                                    className={styles.input}
                                    placeholder="Ingrese la calle principal"
                                    onChange={(e) => { setPrincipalStreet(e.target.value); }}                            
                                />
                                <label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</label>
                            </Form.Floating>
                        </Form.Group>
                        <Form.Group as={Col} controlId="secondaryStreetValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingSecondaryStreet"
                                    required={false}
                                    type="text"
                                    placeholder="Ingrese la calle secundaria"
                                    value={secondaryStreet}
                                    className={styles.input}
                                    onChange={(e) => { setSecondaryStreet(e.target.value); }}                            
                                />
                                <label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</label>
                            </Form.Floating>
                        </Form.Group>            
                        <Form.Group as={Col} controlId="houseNumberValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingHouseNumber"
                                    type="number"
                                    placeholder="Ingrese el número de residencia"
                                    value={houseNumber}
                                    className={styles.input}
                                    required={false}
                                    onChange={(e) => { setHouseNumber(e.target.value); }}
                                />
                                <label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</label>
                            </Form.Floating>
                        </Form.Group>
                    </Row>
                    <Row className="mb-4">
                        <Form.Group as={Col} controlId="descriptionValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingDescription"
                                    required={!preCarga}
                                    type="text"
                                    value={description}
                                    placeholder="Ingrese la descripción"
                                    className={styles.input}
                                    onChange={(e) => { setDescription(e.target.value); }}
                                />
                                <label htmlFor="floatingDescription" className={styles.label}>Descripción</label>
                            </Form.Floating>
                        </Form.Group>
                    </Row>                
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md={3} controlId="pepValidation">
                        <Form.Check
                            label="Perfil politico prioritario"
                            type="switch"
                            name="pep"
                            id="pep"
                            style={{ accentColor: '#AD0039', marginTop: "auto", marginBottom: "auto", paddingBottom: "0px", paddingRight: "0px" }}
                            defaultChecked={pep}
                            onChange={e => setPep(e.target.checked) }
                            className={styles.label}
                        />
                    </Form.Group>
                    {
                        pep ? (
                            <>
                                <Form.Group as={Col} md={4}>
                                    <Autocomplete 
                                        options={pepTitles.sort((a, b) => a.category.level > b.category.level ? 1 : (a.category.level == b.category.level ? 0 : -1))}
                                        groupBy={(option) => `Perfil político prioritario ${option.category.level}`}
                                        getOptionLabel={(option) => option.title}
                                        value={pepTitle}
                                        onChange={handleChangePep}                                        
                                        renderInput={(params) => ( <TextField {...params} label="Cargo" variant="standard" /> )}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={3}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="cartera" 
                                            className={styles.input} 
                                            value={cartera} 
                                            onChange={(e) => { setCartera(e.target.value); }}
                                        />
                                        <label htmlFor="cartera" className={styles.label}>Cartera/Institución pública</label>
                                    </Form.Floating>
                                </Form.Group>
                            </>
                        )
                        : <></>
                    } 
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md={3} controlId="publicOfficialValidation">
                        <Form.Check
                            label="Funcionario público"
                            type="switch"
                            name="publicOfficial"
                            id="publicOfficial"
                            style={{ accentColor: '#AD0039', marginTop: "auto", marginBottom: "auto", paddingBottom: "0px", paddingRight: "0px" }}
                            defaultChecked={publicOfficial}
                            onChange={e => setPublicOfficial(e.target.checked) }
                            className={styles.label}
                        />
                    </Form.Group>
                    {
                        publicOfficial ? (
                            <Form.Group as={Col} md={6}>
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingPublicOfficial"
                                        type="text" 
                                        placeholder="Ingrese el cargo del funcionario público"
                                        className={styles.input} 
                                        required 
                                        value={publicOfficialTitle} 
                                        onChange={(e) => { setPublicOfficialTitle(e.target.value); }}
                                    />
                                    <label htmlFor="floatingPublicOfficial" className={styles.label}>Cargo</label>
                                </Form.Floating>                                
                            </Form.Group>
                        )
                        : <></>
                    } 
                </Row>            

                <Row>
                    <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                        <Button bsPrefix={styles.next} type="submit">AGREGAR</Button>
                    </Col>                    
                </Row>            
                {
                    (defendants && defendants.length > 0) ? (                    
                            <Table className={styles.table} style={{ marginTop: "1rem" }}>
                                <thead>
                                    <th>Nombres y apellidos</th>
                                    <th>Tipo de documento</th>
                                    <th>Número de documento</th>
                                    <th>Fecha de nacimiento</th>
                                    <th>Departamento</th>
                                    <th>Ciudad</th>
                                    <th>Acciones</th>
                                </thead>
                                <tbody>
                                    {
                                        defendants.map(defendant => (
                                            <tr>
                                                <td>{`${defendant.firstName} ${defendant.lastName}`}</td>
                                                <td>{defendant.document.type}</td>
                                                <td>{defendant.document.number}</td>
                                                <td>{defendant.birthDate}</td>
                                                <td>{defendant.address.department ? defendant.address.department.name : "-"}</td>
                                                <td>{defendant.address.city ? defendant.address.city.name : "-"}</td>
                                                <td>
                                                    <AiOutlineFileSearch onClick={(e) => { e.preventDefault(); setCurrentDefendant(defendant); setShowDefendant(true); }} title="Ver" className={styles.icon}/>&nbsp;
                                                    <FiTrash onClick={e => removeDefendant(e, defendant) } title="Descartar" className={styles.icon}/>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                    )
                    : <></>
                }

                <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                    <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                        <Button bsPrefix={styles.next} onClick={handlePrevious} >ANTERIOR</Button>
                    </Col>
                    {
                        defendants.length > 0 ? (
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={handleNext}>SIGUIENTE</Button>
                            </Col>                    
                        )
                        : 
                        (
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={skip}>OMITIR</Button>
                            </Col>                    
                        )
                    }
                </Row>
            </Form>
        </>
    );

}

export default Denunciados;