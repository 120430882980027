import React from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import styles from "./Steps.module.scss"

const Step3 = ( { next, prev, data } ) => {

    return (
        <>
            <Form >
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Lugar del hecho</Form.Label>
                    <Row style={{ marginTop: "0.2rem" }}>
                        <Col md={2}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Departamento</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.department.name} disabled/>
                        </Col>
                        <Col md={2}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Ciudad</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.city.name} disabled />
                        </Col>
                        <Col md={2}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Barrio</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.neighborhood ? data.statementOfFact.address.neighborhood.name : "-"} disabled />
                        </Col>
                        <Col md={3}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Calle principal</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.street.principal} disabled />
                        </Col>
                        <Col md={3}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Calle secundaria</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.street.secondary} disabled />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: ".5rem" }}>
                        <Col md={3}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Número de residencia</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.houseNumber} disabled />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: ".5rem" }}>
                        <Col>
                            <Form.Label as={Col} bsPrefix={styles.label}>Descripción</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.statementOfFact.address.description} disabled />
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </>
    );

}

export default Step3;