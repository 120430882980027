import React, { useState } from "react";
import { Button, Form, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "../../DenunciaForm.module.scss";
import { FiTrash } from "react-icons/fi";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const DocumentosAdjuntos = ( { next, previous, attachments, setAttachments } ) => {

    const [ validated, setValidated ] = useState(false);
    const [ name, setName ] = useState(undefined);
    const [ description, setDescription ] = useState(undefined);
    const [ type, setType ] = useState("DIGITAL");
    const [ location, setLocation ] = useState(undefined);
    const [ file, setFile ] = useState(undefined);
    
    const { keycloak } = useKeycloak();

    const types = [
        {
            label: "Digital",
            value: "DIGITAL"
        },
        {
            label: "Físico",
            value: "FISICO"
        }
    ];

    const handleNext = (e) => {
        e.preventDefault();
        next(attachments);
    }    

    const skip = (e) => {
        e.preventDefault();
        next([]);
    }

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(attachments);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(true);
            uploadFile();
            clean();
        }
    };        

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const uploadFile = async () => {
        if(file){
            let formData = new FormData();
            formData.append('files', file);
            await axios.post(process.env.REACT_APP_API_URL + "/files", formData, prepareHeaders())
            .then(res => {
                let aux = file;
                for(const saved of res.data){
                    if(aux.name === saved.fileName.replace(`${saved.id}-`, '')){
                        aux.id = saved.id;
                        break;
                    }
                }
                setAttachments([
                    ...attachments, 
                    {
                        name, 
                        description,
                        type, 
                        location,
                        file: aux
                    }
                ]);
            })
            .catch(error => {
                console.log(error);
            });            
        }
        else {
            setAttachments([
                ...attachments, 
                {
                    name, 
                    description,
                    type, 
                    location
                }
            ]);
        }
    }

    const clean = () => {
        setName("");
        setDescription("");
        setType("DIGITAL");
        setLocation("");
        setFile(undefined);
    }

    return (
        <Form validated={validated} onSubmit={handleSubmit}>
            <Row className="mt-2 mb-2">
                <Form.Label className={styles.groupLabel}>Documentos adjuntos</Form.Label>
                <Form.Group as={Col} controlId="typeValidation">
                    <Form.Label className={styles.label}>Tipo de documento</Form.Label>
                    {
                        types.map(
                            ( current ) => (
                                <Form.Check type="radio">
                                    <Form.Check.Input 
                                        required
                                        type="radio"
                                        name="documentType"
                                        value={current.value}
                                        onChange={(e) => { setType(e.target.value); }}
                                        checked={type === current.value}
                                        className={styles.checkbox}
                                    />
                                    <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                </Form.Check>
                            )
                        )
                    }
                    <Form.Control.Feedback type="invalid">Debes seleccionar el tipo de documento</Form.Control.Feedback>
                </Form.Group>
            </Row>            
            <Row className="mb-2">
                <Form.Group as={Col} md={4} controlId="nameValidation">
                    <Form.Floating>
                        <Form.Control
                            required
                            id="floatingName"
                            type="text" 
                            value={name}
                            onChange={(e) => { setName(e.target.value); }}
                            placeholder="Ingrese el nombre de la evidencia" 
                            className={styles.input}
                        />
                        <label className={styles.label} htmlFor="floatingName">Nombre</label>
                    </Form.Floating>
                    <Form.Control.Feedback type="invalid">Debes ingresar el nombre de la evidencia</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={8} controlId="descriptionValidation">
                    <Form.Floating>
                        <Form.Control
                            required
                            id="floatingDescription"
                            type="text" 
                            value={description}
                            onChange={(e) => { setDescription(e.target.value); }}
                            placeholder="Ingrese la descripción de la evidencia" 
                            className={styles.input}
                        />
                        <label className={styles.label} htmlFor="floatingDescription">Descripción</label>
                    </Form.Floating>
                    <Form.Control.Feedback type="invalid">Debes ingresar el nombre de la evidencia</Form.Control.Feedback>
                </Form.Group>                
            </Row>
            <Row className="mb-2">
                <Form.Group as={Col} controlId="locationValidation">
                    <Form.Floating>
                        <Form.Control
                            required={type == "FISICO"}
                            id="floatingLocation"
                            type="text" 
                            value={location}
                            onChange={(e) => { setLocation(e.target.value); }}
                            placeholder="Ingrese la ubicación de la evidencia" 
                            className={styles.input}
                        />
                        <label className={styles.label} htmlFor="floatingLocation">Ubicación</label>
                    </Form.Floating>
                    <Form.Control.Feedback type="invalid">Debes ingresar la ubicación de la evidencia</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Group as={Col} controlId="reportsValidation">
                    <Form.Label className={styles.label}>Documento adjunto</Form.Label>
                    <Row>
                        <Col md={12}>
                            <Form.Control
                                type="file"                             
                                multiple={false}
                                placeholder="Seleccione el documento adjunto" 
                                className={styles.fileInput}
                                onChange={(e) => { setFile(e.target.files[0]) }}
                            />
                        </Col>
                    </Row>
                    {
                        (file) ? 
                            <Row className="mt-2">
                                <Col md="auto" style={{ paddingRight: "0px" }}>
                                    <Badge bg="secondary">{file.name}</Badge>
                                </Col>
                            </Row>
                        :
                        <></> 
                    }                    
                </Form.Group>
            </Row>
            <Row>
                <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                    <Button bsPrefix={styles.next} type="submit">AGREGAR</Button>
                </Col>                    
            </Row>
            {
                (attachments && attachments.length > 0) ? (                    
                        <Table className={styles.table} style={{ marginTop: "1rem" }}>
                            <thead>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Tipo</th>
                                <th>Ubicación</th>
                                <th>Archivo</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {
                                    attachments.map(attachment => (
                                        <tr>
                                            <td>{attachment.name}</td>
                                            <td>{attachment.description}</td>
                                            <td>{attachment.type}</td>
                                            <td>{attachment.location}</td>
                                            <td>{attachment.file ? attachment.file.name : '-'}</td>
                                            <td>
                                                <FiTrash className={styles.icon}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                )
                : <></>
            }

            <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                    <Button bsPrefix={styles.next} onClick={handlePrevious} >ANTERIOR</Button>
                </Col>
                {
                    ( attachments && attachments.length > 0 ) ? (
                        <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={handleNext}>FINALIZAR</Button>
                        </Col>                    
                    )
                    : 
                    (
                        <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={skip}>OMITIR</Button>
                        </Col>                    
                    )
                }
            </Row>
        </Form>
    );

}

export default DocumentosAdjuntos;