import React, { useState, useEffect } from 'react'
import { Row, Col } from "react-bootstrap";
import DenunciaForm from '../../components/DenunciaForm';
import styles from "./DenunciasConsulta.module.css";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { ReactComponent as ShiftsWarning } from './assets/shifts_warning.svg';
import Spinner from 'react-bootstrap/Spinner';

export const Denuncias = () => {

  const { keycloak } = useKeycloak();
  const [ loading, setLoading ] = useState(false);
  const [ shifts, setShifts ] = useState([]);

  const prepareHeaders = () => {
    return {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${keycloak.token}`
      }
    };
  }

  const checkProsecutorsShifts = () => {
    setLoading(true);
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/shifts/today`, prepareHeaders()).
      then(res => {
        console.log(res.data);
        setShifts(res.status == 204 ? [] : res.data);
        setLoading(false);      
      }).
      catch(err => {
        console.log(err);
        setShifts([]);      
        setLoading(false);
      })
    }
    catch(err){
      console.log(err);
      setShifts([]);
      setLoading(false);
    }

  }

  useEffect(() => checkProsecutorsShifts(), []);
  
  return loading ? (
    <div style={{ width: "100%", marginTop: "2rem", textAlign: "center" }}>
      <Spinner animation="grow" />
      <p style={{ fontFamily: "FiraSans-Regular", fontSize: "24px" }}>Cargando</p>
    </div>
  )
  : (
    <>
      {
        shifts && shifts.length > 0 ? (
          <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>    
            <Col md={12}>
              <div className={styles.container}>
                <DenunciaForm />
              </div>
            </Col>
          </Row>    
        )
        : 
        ( 
          <div style={{ width: "100%", marginTop: "2rem", textAlign: "center" }}>
            <ShiftsWarning />
          </div> 
        )
      }

    </>
  )
}

export default Denuncias;