import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "../DenunciaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import { FiTrash } from "react-icons/fi";
import { AiOutlineFileSearch } from "react-icons/ai";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from "axios";
import ComplainantModal from "../../modals/ComplainantModal/ComplainantModal";

const Denunciantes = ( { next, previous, complainants, setComplainants } ) => {

    const [ preCarga, setPreCarga ] = useState(false);

    const [ validated, setValidated ] = useState(false);
    const [ showComplainant, setShowComplainant ] = useState(false);

    const [ stateInstitutions, setStateInstitutions ] = useState([]);
    const [ stateInstitution, setStateInstitution ] = useState(undefined);

    const [ privateInstitutions, setPrivateInstutions ] = useState([]);
    const [ privateInstitution, setPrivateInstitution ] = useState(undefined);

    const [ type, setType ] = useState("PF");
    const [ title, setTitle ] = useState(undefined);
    const [ firstName, setFirstName ] = useState(undefined);
    const [ lastName, setLastName ] = useState(undefined);
    const [ birthDate, setBirthDate ] = useState(undefined);
    const [ documentType, setDocumentType ] = useState("CI");
    const [ documentValue, setDocumentValue ] = useState(undefined);

    const [ department, setDepartment ] = useState(undefined);
    const [ city, setCity ] = useState(undefined);
    const [ neighborhood, setNeighborhood ] = useState(undefined); 
    const [ principalStreet, setPrincipalStreet ] = useState(undefined);
    const [ secondaryStreet, setSecondaryStreet ] = useState(undefined);
    const [ houseNumber, setHouseNumber ] = useState(undefined);
    const [ description, setDescription ] = useState(undefined);
    const [ mandatorio, setMandatorio ] = useState(false);
    const [ attachments, setAttachments ] = useState([]);

    const [ departments, setDepartments ] = useState([]);
    const [ neighborhoods, setNeighborhoods ] = useState([]);
    const [ cities, setCities ] = useState([]);

    const [ currentComplainant, setCurrentComplainant ] = useState(undefined);

    const { keycloak } = useKeycloak();

    const types = [
        {
            label: "Ciudadano",
            value: "PF"
        },
        {
            label: "Institución del Estado",
            value: "IE"
        },
        {
            label: "Institución privada",
            value: "IP"
        }
    ];     

    const handleNext = (e) => {
        e.preventDefault();
        next(complainants);
    }    

    const skip = (e) => {
        e.preventDefault();
        next([]);
    }

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(complainants);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            uploadFiles();
            clear();
        }
    };    

    const prepareFileHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                'Content-Type': 'multipart/form-data'
            }
        };
    }      

    const uploadFiles = async () => {
        if(mandatorio && attachments && attachments.length > 0){
            if(attachments.filter(file => file.id).length === 0){
                let formData = new FormData();
                for (let file of attachments) {
                    formData.append('files', file);
                }
                await axios.post(process.env.REACT_APP_API_URL + "/files", formData, prepareFileHeaders())
                .then(res => {
                    let aux = [];
                    for(let current of attachments){
                        for(const saved of res.data){
                            if(current.name === saved.fileName.replace(`${saved.id}-`, '')){
                                current.id = saved.id;
                                break;
                            }
                        }
                        aux.push(current);
                    }
                    setComplainants([
                        ...complainants, 
                        {
                            type,
                            firstName,
                            lastName, 
                            birthDate,
                            document: {
                                type: documentType,
                                number: documentValue
                            },
                            title,
                            stateInstitution,
                            address: {
                                department,
                                city,
                                neighborhood,
                                street: {
                                    principal: principalStreet,
                                    secondary: secondaryStreet
                                },
                                houseNumber,
                                description
                            },
                            privateInstitution,
                            mandatorio,
                            attachments                    
                        }
                    ]);
                    handleNext();
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else {
                setComplainants([
                    ...complainants, 
                    {
                        type,
                        firstName,
                        lastName, 
                        birthDate,
                        document: {
                            type: documentType,
                            number: documentValue
                        },
                        title,
                        stateInstitution,
                        address: {
                            department,
                            city,
                            neighborhood,
                            street: {
                                principal: principalStreet,
                                secondary: secondaryStreet
                            },
                            houseNumber,
                            description
                        },
                        privateInstitution,
                        mandatorio,
                        attachments                    
                    }
                ]);  
                handleNext();              
            }
        }
        else {
            setComplainants([
                ...complainants, 
                {
                    type,
                    firstName,
                    lastName, 
                    birthDate,
                    document: {
                        type: documentType,
                        number: documentValue
                    },
                    title,
                    stateInstitution,
                    address: {
                        department,
                        city,
                        neighborhood,
                        street: {
                            principal: principalStreet,
                            secondary: secondaryStreet
                        },
                        houseNumber,
                        description
                    },
                    privateInstitution,
                    mandatorio,
                    attachments
                }
            ]);            
            handleNext();
        }
    }    

    const clear = () => {
        setFirstName("");
        setLastName("");
        setBirthDate("");
        setDocumentType("CI");
        setDocumentValue(undefined);
        setDepartment(undefined);
        setCity(undefined);
        setNeighborhood(undefined);
        setPrincipalStreet("");
        setSecondaryStreet("");
        setDescription("");
        setHouseNumber("");
        setMandatorio(false);
        setAttachments([]);
        setType("PF");
        setTitle(undefined);
        setPrivateInstitution(undefined);
    };

    const removeComplainant = (e, complainant) => {
        e.preventDefault();
        let aux = [];
        for(let current of complainants){
            if(current !== complainant){
                aux.push(current);
            }
        }
        setComplainants(aux);
    }

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findNeighborhoods = ( cityId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`, prepareHeaders())
        .then(res => setNeighborhoods(res.status == 200 ? res.data : []))
        .catch(error => console.error(error));
    };

    const findStateInstitutions = () => {
        axios.get(process.env.REACT_APP_API_URL + `/state/institutions`, prepareHeaders())
        .then(res => setStateInstitutions(res.status == 200 ? res.data : []))
        .catch(error => console.log(error));
    }

    const handleChangeStateInstitution = (event, value, reason, details) => {
        event.preventDefault();
        setStateInstitution(typeof value == 'object' ? value : { name: value });
    }    

    const handleChangePrivateInstitution = (event, value, reason, details) => {
        event.preventDefault();
        setPrivateInstitution(typeof value == 'object' ? value : { name: value });
    }        

    const handleChangeNeighborhood = (event, value, reason, details) => {
        event.preventDefault();
        setNeighborhood(typeof value == 'object' ? value : { name: value });
    }    

    const changeType = (e) => {
        setType(e.target.value);
        setMandatorio(e.target.value === "IP");
    } 

    const returnValue = (value) => {
        try { return JSON.parse(value).name; }
        catch(e){ return value; }
    }

    useEffect(() => findDeparments(), []);
    useEffect(() => findStateInstitutions(), []);
    useEffect(() => { if(department) { findCities(department.id); }}, [ department ]);
    useEffect(() => { if(city) { findNeighborhoods(city.id); }}, [ city ]);

    return (
        <>
            <ComplainantModal data={currentComplainant} show={showComplainant} handleClose={() => setShowComplainant(false)}/>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Row className="mt-2 mb-4">
                    <Form.Label className={styles.groupLabel}>Tipo de denunciante</Form.Label>
                    <Form.Group as={Col} controlId="typeValidation">
                        {
                            types.map(
                                ( current ) => (
                                    <Form.Check type="radio">
                                        <Form.Check.Input 
                                            type="radio"
                                            value={current.value}
                                            onChange={changeType}
                                            checked={type === current.value}
                                            className={styles.checkbox}
                                        />
                                        <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                    </Form.Check>
                                )
                            )
                        }
                        <Form.Control.Feedback>{`Denuncia ${type} ✓`}</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Debes seleccionar el tipo de la denuncia</Form.Control.Feedback>
                    </Form.Group>                
                </Row>            
                {
                    type === "IE" ? (
                        <>
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Institución del estado</Form.Label>
                                <Form.Group as={Col} md="6" controlId="nameValidation">
                                    <Autocomplete 
                                        options={stateInstitutions}
                                        getOptionLabel={(option) => typeof option == "object" ? option.name : returnValue(option)}
                                        value={JSON.stringify(stateInstitution)}
                                        freeSolo
                                        autoSelect
                                        onChange={handleChangeStateInstitution}
                                        renderInput={(params) => ( <TextField {...params} label="Seleccione o ingrese la institución"/> )}
                                    />
                                </Form.Group>   
                            </Row>                                   
                        </>
                    )
                    :<></>
                }
                {
                    type === "IP" ? (
                        <>
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Institución privada</Form.Label>
                                <Form.Group as={Col} md="6" controlId="nameValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            required
                                            id="floatingName"
                                            type="text" 
                                            value={privateInstitution}
                                            onChange={(e) => { setPrivateInstitution(e.target.value); }}
                                            placeholder="Ingrese el nombre de la institución privada" 
                                            className={styles.input}
                                        />
                                        <label className={styles.label} htmlFor="floatingName">Institución privada</label>
                                    </Form.Floating>
                                </Form.Group>   
                            </Row>                                   
                        </>
                    )
                    :<></>
                }            
                <Row className="mb-4">
                    <Form.Label className={styles.groupLabel}>Datos personales</Form.Label>
                    <Form.Group as={Col} controlId="nameValidation">
                        <Form.Floating>
                            <Form.Control
                                required
                                id="floatingName"
                                type="text" 
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value); }}
                                placeholder="Ingrese el nombre del denunciante" 
                                className={styles.input}
                            />
                            <label className={styles.label} htmlFor="floatingName">Nombres</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="lastNameValidation">
                        <Form.Floating>
                            <Form.Control 
                                required
                                id="floatingLastName"
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value); }}                        
                                className={styles.input}
                                type="text" 
                                placeholder="Ingrese el nombre del denunciante" 
                            />
                            <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                        </Form.Floating>                        
                    </Form.Group>
                    {
                        type === "IE" ? (
                            <Form.Group as={Col} controlId="cargo">
                                <Form.Floating>
                                    <Form.Control
                                        id="cargo" 
                                        required
                                        type={"text"}
                                        value={title} 
                                        placeholder="Ingrese el cargo del representante"
                                        className={styles.input}
                                        onChange={(e) => { setTitle(e.target.value); }} 
                                    />
                                    <label htmlFor="cargo" className={styles.label}>Cargo</label>
                                </Form.Floating>
                            </Form.Group>
                        )
                        : (
                            <Form.Group as={Col} controlId="birthDateValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingBirthDate"
                                        required={false}
                                        value={birthDate}
                                        onChange={(e) => { setBirthDate(e.target.value); }}                        
                                        className={styles.input}
                                        placeholder="Ingrese la fecha de nacimiento"
                                        type="date" 
                                    />
                                    <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                                </Form.Floating>
                            </Form.Group>                       
                        )             
                    }
                </Row>
                {
                    type !== "IE" ? (
                        <>
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Documento de identidad</Form.Label>
                                <Form.Group as={Col} controlId="documentTypeValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingDocumentType" 
                                            className={styles.input} 
                                            required={!preCarga} 
                                            as="select" 
                                            value={documentType} 
                                            onChange={(e) => {setDocumentType(e.target.value)}}
                                        >
                                            <option value="CI">Cédula de identidad</option>
                                            <option value="PAS">Pasaporte</option>
                                            <option value="MAT">Matrícula de la Corte Suprema de Justicia</option>
                                        </Form.Control>
                                        <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} controlId="documentValueValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            id="floatingDocumentNumber" 
                                            required={!preCarga} 
                                            type={documentType === "CI" ? "number" : "text"}
                                            value={documentValue} 
                                            placeholder="Ingrese el número de documento"
                                            className={styles.input}
                                            onChange={(e) => { setDocumentValue(e.target.value); }} 
                                        />
                                        <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Label className={styles.groupLabel}>Domicilio</Form.Label>
                                <Form.Group className="mb-2" as={Col} controlId="departamentValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            required={type !== "IP"}
                                            as="select"
                                            type="select"
                                            id="floatingDepartament" 
                                            className={styles.input} 
                                            value={JSON.stringify(department)}
                                            onChange={(e) => { setDepartment(JSON.parse(e.target.value)); }}
                                        >
                                            <option value="" selected>Seleccione</option>
                                            {
                                                departments.map((current) => (
                                                    <option value={JSON.stringify(current)}>{current.name}</option>
                                                ))
                                            }
                                        </Form.Control> 
                                        <label htmlFor="floatingDepartament" className={styles.label}>Departamento</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} controlId="cityValidation">
                                    <Form.Floating>
                                        <Form.Select 
                                            id="floatingCity" 
                                            className={styles.input} 
                                            required={type !== "IP"}
                                            value={JSON.stringify(city)} 
                                            onChange={(e) => { setCity(JSON.parse(e.target.value)); }}
                                        >
                                            <option value="" selected>Seleccione</option>
                                            {
                                                cities.map((current) => (
                                                    <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingCity" className={styles.label}>Ciudad</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} controlId="neighborhoodValidation">
                                    <Autocomplete 
                                        options={neighborhoods}
                                        getOptionLabel={(option) => typeof option == "object" ? option.name : returnValue(option)}
                                        value={neighborhood}
                                        freeSolo
                                        autoSelect
                                        onChange={handleChangeNeighborhood}
                                        renderInput={(params) => ( <TextField {...params} label="Barrio / Localidad" variant="standard" /> )}
                                    />
                                    {/*
                                    <Form.Floating>
                                        <Form.Select id="floatingNeighborhood" className={styles.input} required value={JSON.stringify(neighborhood)} onChange={(e) => { setNeighborhood(JSON.parse(e.target.value)); }}>
                                            <option value="" selected>Seleccione</option>
                                            {
                                                neighborhoods.map((current) => (
                                                    <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingNeighborhood" className={styles.label}>Barrio / Localidad</label>
                                    </Form.Floating>
                                    */}
                                </Form.Group>
                                <Row className="mb-2">
                                    <Form.Group as={Col} controlId="principalStreetValidation">
                                        <Form.Floating>
                                            <Form.Control 
                                                id="floatingPrincipalStreet"
                                                required={type !== "IP"}
                                                type="text"
                                                value={principalStreet}
                                                className={styles.input}
                                                placeholder="Ingrese la calle principal"
                                                onChange={(e) => { setPrincipalStreet(e.target.value); }}                            
                                            />
                                            <label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</label>
                                        </Form.Floating>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="secondaryStreetValidation">
                                        <Form.Floating>
                                            <Form.Control 
                                                id="floatingSecondaryStreet"
                                                required={type !== "IP"}
                                                type="text"
                                                placeholder="Ingrese la calle secundaria"
                                                value={secondaryStreet}
                                                className={styles.input}
                                                onChange={(e) => { setSecondaryStreet(e.target.value); }}                            
                                            />
                                            <label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</label>
                                        </Form.Floating>
                                    </Form.Group>            
                                    <Form.Group as={Col} controlId="houseNumberValidation">
                                        <Form.Floating>
                                            <Form.Control
                                                id="floatingHouseNumber"
                                                type="number"
                                                placeholder="Ingrese el número de residencia"
                                                required={false}
                                                value={houseNumber}
                                                className={styles.input}
                                                onChange={(e) => { setHouseNumber(e.target.value); }}
                                            />
                                            <label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</label>
                                        </Form.Floating>
                                    </Form.Group>        
                                </Row>
                                <Row className="mb-4">
                                    <Form.Group as={Col} controlId="descriptionValidation">
                                        <Form.Floating>
                                            <Form.Control 
                                                id="floatingDescription"
                                                required={type !== "IP"}
                                                type="text"
                                                value={description}
                                                placeholder="Ingrese la descripción"
                                                className={styles.input}
                                                onChange={(e) => { setDescription(e.target.value); }}
                                            />
                                            <label htmlFor="floatingDescription" className={styles.label}>Descripción</label>
                                        </Form.Floating>
                                    </Form.Group>
                                </Row>
                            </Row>                        
                        </>
                    )
                    :
                    <></>
                }
                <Row className="mb-4">
                    <Form.Group as={Col} md={2} controlId="mandatarioValidation">
                        <Form.Check
                            label="Por mandatario"
                            type="switch"
                            disabled={type === "IP"}
                            name="porMandatorio"
                            id="porMandatorio"
                            style={{ accentColor: '#AD0039', marginTop: "auto", marginBottom: "auto", paddingBottom: "0px", paddingRight: "0px" }}
                            defaultChecked={mandatorio}
                            onChange={e => setMandatorio(e.target.checked) }
                            className={styles.label}
                        />
                    </Form.Group>
                    {
                        mandatorio ? (
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Poder judicial</Form.Label>
                                <Row>
                                    <Col md={10}>
                                        <Form.Control
                                            required
                                            type="file"                             
                                            multiple={true}
                                            placeholder="Seleccione los informes" 
                                            className={styles.fileInput}
                                            onChange={e => setAttachments(Array.from(e.target.files))}
                                        />   
                                    </Col>
                                </Row>
                                {
                                    (attachments && attachments.length > 0) ? 
                                        <Row className="mt-2">
                                            {
                                                attachments.map(file => (
                                                    <Col md="auto" style={{ paddingRight: "0px" }}>
                                                        <Badge bg="secondary">{file.name}</Badge>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    :
                                    <></> 
                                }                            
                            </Form.Group>
                        )
                        : <></>
                    }
                </Row>
                <Row>
                    <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                        <Button bsPrefix={styles.next} type="submit">AGREGAR</Button>
                    </Col>                    
                </Row>
                {
                    (complainants && complainants.length > 0) ? (                    
                            <Table className={styles.table} style={{ marginTop: "1rem" }}>
                                <thead>
                                    <th>Nombres y apellidos</th>
                                    <th>Tipo de documento</th>
                                    <th>Número de documento</th>
                                    <th>Fecha de nacimiento</th>
                                    <th>Departamento</th>
                                    <th>Ciudad</th>
                                    <th>Barrio</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {
                                        complainants.map(complainant => (
                                            <tr>
                                                <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                <td>{complainant.document.type}</td>
                                                <td>{complainant.document.number}</td>
                                                <td>{complainant.birthDate}</td>
                                                <td>{complainant.address.department ? complainant.address.department.name : "-"}</td>
                                                <td>{complainant.address.city ? complainant.address.city.name : "-"}</td>
                                                <td>{complainant.address.neighborhood ? complainant.address.neighborhood.name : "-"}</td>
                                                <td>
                                                    <AiOutlineFileSearch 
                                                        onClick={(e) => { e.preventDefault(); setShowComplainant(true); setCurrentComplainant(complainant); }} 
                                                        title="Ver" 
                                                        className={styles.icon}
                                                    />
                                                    &nbsp;
                                                    <FiTrash onClick={e => removeComplainant(e, complainant) } title="Descartar" className={styles.icon}/>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                    )
                    : <></>
                }

                <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                    <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                        <Button bsPrefix={styles.next} onClick={handlePrevious}>ANTERIOR</Button>
                    </Col>
                    {
                        complainants.length > 0 ? (
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={handleNext}>SIGUIENTE</Button>
                            </Col>                    
                        )
                        : 
                        (
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={skip}>OMITIR</Button>
                            </Col>                    
                        )
                    }
                </Row>
            </Form>
        </>
    );

}

export default Denunciantes;