import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import styles from "./Alert.module.css";
import { BiAnalyse } from 'react-icons/bi';
import { SiGoogleanalytics } from 'react-icons/si';
import { calcTime } from "../../../core/time";
import { useKeycloak } from "@react-keycloak/web";
import { Rules } from "../../../constants/Rules";
import axios from 'axios';
import { Complexity } from "../../../constants/Complexity";
import { Feature } from "../../../constants/Feature";
import { RiChatCheckFill, RiChatDeleteFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { DisassociationReply } from "../../../constants/DisassociationReply";
import { MdAssignmentAdd, MdAssignmentLate } from "react-icons/md";

const Alert = ({ alert, callback }) => {

    const { keycloak, initialized } = useKeycloak();    
    const navigate = useNavigate();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const resolveAction = () => {
        switch(alert.type){
          case "ANALYSIS":
            navigate(`/denuncias/consultar?id=${alert.payload.complaint.id}`);
          case "PRINCIPAL_PROSECUTOR_ASSIGNATION":
            navigate(`/causas/${alert.payload.id}`);
          default:
            navigate(`/causas/${alert.payload.cause.id}`);
        }
    }

    const view = (e) => {
        e.preventDefault();
        axios.patch(`${process.env.REACT_APP_API_URL}/alerts/${alert.id}`, null, prepareHeaders())
        .then(res => {
            resolveAction();
        })
        .catch(err => {
            console.log(err);
        });
    }
    
    const resolveAlert = () => {
        switch(alert.type){
            case "ANALYSIS":
                return (
                    <>
                        <Col md={1}>
                            <BiAnalyse className={styles.alertItemIcon} />
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemTitle}>Análisis de competencia finalizado</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}> 
                                        Denuncia #{alert.payload.complaint.id}
                                        {
                                            alert.payload.result.result 
                                            ? (
                                                <>
                                                    <span> analizada como de </span>
                                                    <Badge pill bg="success">competencia</Badge> 
                                                    <span>{` con una tasa del ${alert.payload.result.accuracy}%`}</span>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <span> analizada como de </span>
                                                    <Badge pill bg="danger">no competencia</Badge> 
                                                    <span>{` con una tasa del ${alert.payload.result.accuracy}%`}</span>
                                                </>                                        
                                            )
                                        }
                                    </span>
                                    <p className={styles.rulesTitle}>Reglas aplicadas</p>
                                    {
                                        alert.payload.result.rules.true.map((rule) => (
                                            <Row>
                                                <Col md="10">
                                                    <span className={styles.ruleTitle}>{Rules[rule.name].name}</span>
                                                </Col>
                                                <Col md="2" style={{ textAlign: "right" }}>
                                                    <Badge className={styles.ruleStatus} pill bg="success">Aprobada</Badge>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    {
                                        alert.payload.result.rules.false.map((rule) => (
                                            <Row>
                                                <Col md="10">
                                                    <span className={styles.ruleTitle}>{Rules[rule.name].name}</span>
                                                </Col>
                                                <Col md="2" style={{ textAlign: "right" }}>
                                                    <Badge className={styles.ruleStatus} pill bg="danger" >No aprobada</Badge>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} style={{ textAlign: "right" }}>
                            <span className={styles.alertItemTime}>{calcTime(alert.createdAt, true)}</span>
                        </Col>   
                    </>                 
                );
            case "COMPLEXITY":
                return (
                    <>
                        <Col md={1}>
                            <SiGoogleanalytics className={styles.alertItemIcon} />
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemTitle}>Análisis de complejidad finalizado</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>
                                        Denuncia #{alert.payload.cause.complaint.id} analizada como de&nbsp;
                                        <Badge pill bg={Complexity[alert.payload.analysis.level].style}>
                                            {"complejidad " + Complexity[alert.payload.analysis.level].name}
                                        </Badge>
                                        <span>{` con un total de ${alert.payload.analysis.points} puntos`}</span>
                                    </span>
                                    <p className={styles.rulesTitle}>Indicadores aplicados</p>
                                    {
                                        alert.payload.analysis.details.map((detail) => (
                                            <Row>
                                                <Col md="10">
                                                    <span className={styles.ruleTitle}>{Feature[detail.feature].name}</span>
                                                </Col>
                                                <Col md="2" style={{ textAlign: "right" }}>
                                                    <span className={styles.ruleTitle}>{`${detail.points} puntos`}</span>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Col>
                            </Row>                            
                        </Col>
                        <Col md={2} style={{ textAlign: "right" }}>
                            <span className={styles.alertItemTime}>{calcTime(alert.createdAt, true)}</span>
                        </Col>   
                    </>                 
                );  
            case "PRINCIPAL_PROSECUTOR_ASSIGNATION":
                return (
                    <>
                        <Col md={1}>
                            <MdAssignmentAdd className={styles.alertItemIcon} />
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemTitle}>Causa asignada</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>{`Se le ha sido asignada la causa #${alert.payload.id}`}</span>
                                </Col>
                            </Row>                    
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>{alert.payload.cover}</span>
                                </Col>
                            </Row>                            
                        </Col>
                        <Col md={2} style={{ textAlign: "right" }}>
                            <span className={styles.alertItemTime}>{calcTime(alert.createdAt, true)}</span>
                        </Col>   
                    </>
                );
            case "DISASSOCIATION_REQUEST":
                return (
                    <>
                        <Col md={1}>
                            <MdAssignmentLate className={styles.alertItemIcon} />
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemTitle}>Solicitud de inhibición recibida</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>{`Fiscal: ${alert.payload.cause.principalProsecutor.firstName} ${alert.payload.cause.principalProsecutor.lastName}`}</span>
                                </Col>
                            </Row>                                           
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>{`${alert.payload.cause.cover}`}</span>
                                </Col>
                            </Row>                                                
                        </Col>
                        <Col md={2} style={{ textAlign: "right" }}>
                            <span className={styles.alertItemTime}>{calcTime(alert.createdAt, true)}</span>
                        </Col>   
                    </>
                );
            case "DISASSOCIATION_REPLY":
                return (
                    <>
                        <Col md={1}>
                            { alert.payload.reply.judgment == "APPROVED" ? ( <RiChatCheckFill className={styles.alertItemIcon} /> ) : (  <RiChatDeleteFill className={styles.alertItemIcon} /> ) }
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemTitle}>Solicitud de inhibición {DisassociationReply[alert.payload.reply.judgment].name}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>{`Causa #${alert.payload.cause.id}`}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.alertItemDetail}>{alert.payload.cause.cover}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} style={{ textAlign: "right" }}>
                            <span className={styles.alertItemTime}>{calcTime(alert.createdAt, true)}</span>
                        </Col>   
                    </>
                );           
            default:
                return <></>                
        }
    }
    
    return (
        <Row onClick={view} className={`${!alert.viewed ? styles.alertItemViewed : styles.alertItemContainer}`} >
            { resolveAlert() }
        </Row>        
    );
    
}

export default Alert;