import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const UsersModal = ( { show, handleClose, organization } ) => {

    const [ loading, setLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }

    const findUsers = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/users?group=/external-institution&organization=${organization?.id}`, prepareHeaders()).
        then(res => {
            setUsers(res.status == 200 ? res.data : []);
            setLoading(false);
        }).
        catch(err => {
            setUsers([]);
            setLoading(false);
        })
    }

    useEffect(
        () => { 
            if(organization){
                findUsers();
            }
        }, 
        [organization]
    );

    return organization ? (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header>
                    <Modal.Title><h5>{`Usuarios de la organización (${organization.name})`}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (users && users.length > 0) ? (
                            <Table>
                                <thead>
                                    <th>Nombres</th>
                                    <th>Apellidos</th>
                                    <th>Correo electrónico</th>
                                    <th>Fecha de creación</th>
                                </thead>
                                <tbody>
                                    {
                                        users.map((user) => (
                                            <tr>
                                                <td>{user.firstName}</td>
                                                <td>{user.lastName}</td>
                                                <td>{user.email}</td>
                                                <td>{user.createdAt}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        )
                        :
                        <span>No hay usuarios</span>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>            
        </>
    )
    :
    <></>;

}

export default UsersModal;