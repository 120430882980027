export const calcTime = (start, detailed) => {
    const startDate = new Date(start).getTime();
    const endDate = new Date().getTime();
    const timeDifference = endDate - startDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    if(daysDifference > 2){
        return Math.floor(daysDifference) + (detailed ? " días" : "d");
    }
    const hoursDifference = timeDifference / (1000 * 3600);
    if(hoursDifference >= 1){
        return Math.floor(hoursDifference) + (detailed ? (hoursDifference >= 2 ? " horas" : " hora") : "h");
    }
    const minutsDifference = timeDifference / (1000 * 60);
    if(minutsDifference >= 1){
        return Math.floor(minutsDifference) + (detailed ? (minutsDifference >= 2 ? " minutos" : " minuto") : "m");
    }
    const secondsDifference = Math.floor(timeDifference / 1000);
    return secondsDifference + (detailed ? (secondsDifference >= 2 ? " segundos" : " segundo") : "s");
}