import React, { useState, useEffect } from "react";
import { Button, Form, Table, Col, Row, Badge, Tab, Nav } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from "../DenunciaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const Hecho = ( { next, previous, data } ) => {

    const MAX_LONG = 9007199254740991;

    const [ validated, setValidated ] = useState(false);
    const [ preCarga, setPreCarga ] = useState(false);

    const [ department, setDepartment ] = useState(undefined);
    const [ city, setCity ] = useState(undefined);
    const [ neighborhood, setNeighborhood ] = useState(undefined); 
    const [ principalStreet, setPrincipalStreet ] = useState(undefined);
    const [ secondaryStreet, setSecondaryStreet ] = useState(undefined);
    const [ houseNumber, setHouseNumber ] = useState(undefined);    
    const [ description, setDescription ] = useState(undefined);
    const [ possibleDate, setPossibleDate ] = useState(undefined);
    const [ selectedCrimes, setSelectedCrimes ] = useState([]);
    const [ amount, setAmount ] = useState(undefined);
    const [ injuredInstitutions, setInjuredInstitutions ] = useState([]);
    const [ fact, setFact ] = useState(undefined);

    const [ departments, setDepartments ] = useState([]);
    const [ neighborhoods, setNeighborhoods ] = useState([]);
    const [ cities, setCities ] = useState([]);    
    const [ crimes, setCrimes ] = useState([]);

    const patrimonialDamageRule = [{ lawNumber: "1160/97", article: 187 }, { lawNumber: "1160/97", article: 192 }];

    const [ institutions, setInstitutions ] = useState([
        {
            code: "SALUD",
            label: "Salud",
            selected: false
        }, 
        {
            code: "EDUCACION",
            label: "Educación",
            selected: false
        },
        {
            code: "SERVICIOS_PUBLICOS",
            label: "Servicios públicos",
            selected: false
        }
    ]);

    const { keycloak } = useKeycloak();    

    const [formattedAmount, setFormattedAmount] = useState('');
  
    const formatAmount = (value) => {
        const cleanValue = value.replace(/[^\d]/g, '');
        const formatted = parseInt(cleanValue).toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
            minimumFractionDigits: 0,
        });
        return formatted;
    };
  
    const handleChange = (e) => {
        e.preventDefault();
        const inputValue = e.target.value;
        const numeric = parseInt(inputValue.replace(/[^\d]/g, ''));
        if(isNaN(numeric)){
            setFormattedAmount('');
        }
        else if(numeric <= MAX_LONG){
            const formatted = formatAmount(inputValue);
            setFormattedAmount(formatted);
            setAmount(isNaN(numeric) ? undefined : inputValue.replace(/[^\d]/g, ''));
        }
        else {
            alert("El máximo valor para el monto es " + MAX_LONG);
        }
    };    

    const handleNext = () => {
        next(build());
    }    

    const refreshInstitutions = ( updated ) => {
        let aux = [];
        for(let current of institutions){
            for(let currentUpdated of updated){
                if(current.code == currentUpdated.code){
                    current.selected = true;
                    break;
                }
            }
            aux.push(current);
        }
        setInstitutions(aux);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }    
        else {
            event.preventDefault();
            setValidated(true);
            handleNext();
        }
    };        

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(build());
    }

    const build = () => {
        return {
            address: {
                department,
                city,
                neighborhood,
                street: {
                    principal: principalStreet,
                    secondary: secondaryStreet
                },
                houseNumber,
                description
            },
            possibleDate,
            crimes: selectedCrimes,
            amount,
            injuredInstitutions,
            fact
        };
    }

    const handleSelectCrime = (e, crime) => {
        e.preventDefault();
        let aux = [];
        let selectedAux = [];
        for(let current of crimes){
            let auxCrimes = [];
            for(let currentCrime of current.crimes){
                if(currentCrime.id === crime.id){
                    currentCrime.selected = !currentCrime.selected;
                }
                auxCrimes.push(currentCrime);
            }
            if(auxCrimes.filter(auxCrime => auxCrime.selected).length > 0){
                selectedAux.push(...auxCrimes.filter(auxCrime => auxCrime.selected));
            }
            aux.push({ law: current.law, crimes: auxCrimes });
        }
        setSelectedCrimes(selectedAux);
        setCrimes(aux);
    }

    const handleSelectInstitution = (e, institution) => {
        e.preventDefault();
        let aux = [];
        for(let current of institutions){
            if(current.code === institution.code){
                current.selected = !current.selected;
            }
            aux.push(current);
        }
        setInjuredInstitutions(aux.filter(current => current.selected));
        setInstitutions(aux);
    }

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }     
    
    const checkPatrimonialDamageRule = ()  => {
        for(let selected of selectedCrimes){
            for(let rule of patrimonialDamageRule){
                if(rule.lawNumber == selected.law.number && rule.article == selected.article){
                    return true;
                }
            }
        }
        return false;
    }

    const groupBy = (list, keyGetter, identifierKey) => {
        const map = new Map();
        const formattedMap = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key[identifierKey]);
             const formattedCollection = formattedMap.get(JSON.stringify(key));
             if (!collection) {
                 map.set(key[identifierKey], [item]);
                 formattedMap.set(JSON.stringify(key), [item]);
             } 
             else {
                collection.push(item);
                formattedCollection.push(item);
             }
        });        
        return Object.fromEntries(formattedMap);
      };
      

    const mapCrimes = ( rawCrimes, selected ) => {
        const mappedCrimes = groupBy(rawCrimes, rawCrime => rawCrime.law, "id");
        let crimesFormatted = [];
        for(let key in mappedCrimes){
            crimesFormatted.push({
                law: JSON.parse(key),
                crimes: mappedCrimes[key].map((ctx) => { return {...ctx, selected: checkSelected( ctx, selected ) }; } )
            });
        }
        return crimesFormatted;
    }

    const checkSelected = ( crime, selected ) => {

        if(selected && selected.length > 0){
            for(let current of selected){
                if(current.id === crime.id){
                    return true;
                }
            }
        }
        return false;
    };
    
    const findCrimes = ( selected ) => {
        axios.get(process.env.REACT_APP_API_URL + "/crimes", prepareHeaders())        
        .then(res => {
            if(res.status == 200){
                setCrimes(mapCrimes(res.data, selected));
            }
        })
        .catch(error => {
            console.error(error);
        });
    };

    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findNeighborhoods = ( cityId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`, prepareHeaders())
        .then(res => setNeighborhoods(res.status == 200 ? res.data : []))
        .catch(error => console.error(error));
    };

    const handleChangeNeighborhood = (event, value, reason, details) => {
        event.preventDefault();
        setNeighborhood(typeof value == 'object' ? value : { name: value });
    }          

    useEffect(() => { findDeparments(); }, []);
    useEffect(() => { if(department) { findCities(department.id); }}, [ department ]);
    useEffect(() => { if(city) { findNeighborhoods(city.id); }}, [ city ]);        

    useEffect(() => {
        if(data){
            setDepartment(data.address.department);
            setCity(data.address.city);
            setNeighborhood(data.address.neighborhood);
            setPrincipalStreet(data.address.street.principal);
            setSecondaryStreet(data.address.street.secondary);
            setHouseNumber(data.address.houseNumber);
            setDescription(data.address.description);
            setPossibleDate(data.possibleDate);
            setSelectedCrimes(data.crimes);
            setAmount(data.amount);
            setInjuredInstitutions(data.injuredInstitutions);
            setFact(data.fact);

            findCrimes(data.crimes);
            refreshInstitutions(data.injuredInstitutions);
        }
        else {
            findCrimes([]);
        }
    }, 
    [data]);    

    const returnValue = (value) => {
        try { return JSON.parse(value).name; }
        catch(e){ return value; }
    }        

    return (
        <Form validated={validated} onSubmit={handleSubmit}>
            <Row className="mt-2 mb-4">
                <Form.Label className={styles.groupLabel}>Lugar del hecho</Form.Label>
                <Form.Group className="mb-3" as={Col} controlId="departamentValidation">
                    <Form.Floating>
                        <Form.Select id="floatingDepartment" className={styles.input} required value={JSON.stringify(department)}  onChange={(e) => { setDepartment(JSON.parse(e.target.value)); }}>
                            <option value="" selected>Seleccione</option>
                            {
                                departments.map((current) => (
                                    <option value={JSON.stringify(current)}>{current.name}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Label htmlFor="floatingDepartment" className={styles.label}>Departamento</Form.Label>
                    </Form.Floating>
                </Form.Group>
                <Form.Group as={Col} controlId="cityValidation">
                    <Form.Floating>
                        <Form.Select id="floatingCity" className={styles.input} required value={JSON.stringify(city)} onChange={(e) => { setCity(JSON.parse(e.target.value)); }}>
                            <option value="" selected>Seleccione</option>
                            {
                                cities.map((current) => (
                                    <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                ))
                            }
                        </Form.Select>
                        <Form.Label htmlFor="floatingCity" className={styles.label}>Ciudad</Form.Label>
                    </Form.Floating>                        
                </Form.Group>
                <Form.Group as={Col} controlId="neighborhoodValidation">
                    <Autocomplete 
                        options={neighborhoods}
                        getOptionLabel={(option) => typeof option == "object" ? option.name : returnValue(option)}
                        value={neighborhood}
                        freeSolo
                        autoSelect
                        onChange={handleChangeNeighborhood}
                        renderInput={(params) => ( <TextField {...params} label="Barrio / Localidad" variant="standard" /> )}
                    />
                    {/*
                    <Form.Floating>
                        <Form.Select id="floatingNeighborhood" className={styles.input} required value={JSON.stringify(neighborhood)} onChange={(e) => { setNeighborhood(JSON.parse(e.target.value)); }}>
                            <option value="" selected>Seleccione</option>
                            {
                                neighborhoods.map((current) => (
                                    <option value={JSON.stringify(current)}>{current.name}</option>                                    
                                ))
                            }
                        </Form.Select>
                        <Form.Label htmlFor="floatingNeighborhood" className={styles.label}>Barrio / Localidad</Form.Label>
                    </Form.Floating>
                    */}
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="principalStreetValidation">
                        <Form.Floating>
                            <Form.Control
                                id="floatingPrincipalStreet"
                                required={false}
                                type="text"
                                value={principalStreet}
                                className={styles.input}
                                placeholder="Ingrese la calle principal"
                                onChange={(e) => { setPrincipalStreet(e.target.value); }}                            
                            />
                            <Form.Label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</Form.Label>                            
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="secondaryStreetValidation">
                        <Form.Floating>
                            <Form.Control 
                                id="floatingSecondaryStreet"
                                required={false}
                                type="text"
                                placeholder="Ingrese la calle secundaria"
                                value={secondaryStreet}
                                className={styles.input}
                                onChange={(e) => { setSecondaryStreet(e.target.value); }}                            
                            />
                            <Form.Label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</Form.Label>
                        </Form.Floating>
                    </Form.Group>            
                    <Form.Group as={Col} controlId="houseNumberValidation">
                        <Form.Floating>
                            <Form.Control 
                                id="floatingHouseNumber"
                                type="number"
                                required={false}
                                placeholder="Ingrese el número de residencia"
                                value={houseNumber}
                                className={styles.input}
                                onChange={(e) => { setHouseNumber(e.target.value); }}
                            />
                            <Form.Label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</Form.Label>
                        </Form.Floating>
                    </Form.Group>        
                </Row>
                <Row >
                    <Form.Group as={Col} controlId="descriptionValidation">
                        <Form.Floating>
                            <Form.Control
                                required={false}
                                id="floatingDescription"
                                type="text"
                                value={description}
                                className={styles.input}
                                placeholder="Ingrese la descripción del lugar"
                                onChange={e => setDescription(e.target.value)}
                            />
                            <Form.Label htmlFor="floatingDescription" className={styles.label}>Descripción</Form.Label>
                        </Form.Floating>
                    </Form.Group>
                </Row>
            </Row>
            <Row className="mb-4">
                <Form.Label className={styles.groupLabel}>Posible fecha del hecho</Form.Label>
                <Form.Group as={Col} md={3} controlId="possibleDateValidation">
                    <Form.Floating>
                        <Form.Control 
                            id="floatingPossibleDate"
                            type="date" 
                            required={true}
                            value={possibleDate}
                            onChange={e => setPossibleDate(e.target.value)}
                            className={styles.input}
                        />
                        <Form.Label htmlFor="floatingPossibleDate" className={styles.label}>Fecha</Form.Label>
                    </Form.Floating>
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Form.Label className={styles.groupLabel}>Hechos punibles</Form.Label>
                <Form.Group as={Col} controlId="hechosPunibles">
                    {
                        crimes.length > 0 ? (
                            <Tab.Container defaultActiveKey={crimes[0].law.id}>
                                <Row>
                                    <Col sm={3}>
                                        <Nav variant="pills" className={`flex-column ${styles.pillsGroup}`}>
                                            {
                                                crimes.map(crime => (
                                                    <Nav.Item className={styles.pill}>
                                                        <Nav.Link className={styles.pillLink} eventKey={crime.law.id}>
                                                            <span className={styles.lawNumberSpan}>{`Ley Nº ${crime.law.number}`}</span><br></br>
                                                            <span className={styles.lawNameSpan}>{crime.law.name}</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))
                                            }
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            {                                          
                                                crimes.map(crime => (
                                                    <Tab.Pane eventKey={crime.law.id}>
                                                        {
                                                            crime.crimes.map((punishableFact) => (
                                                                <Badge 
                                                                    className={punishableFact.selected ? styles.badgeSelected : styles.badgeDefault}
                                                                    style={{ marginRight: ".5rem", cursor: "pointer" }}
                                                                    onClick={(e) => { handleSelectCrime(e, punishableFact); }}
                                                                >
                                                                {`Art. ${punishableFact.article} - ${punishableFact.name}`}
                                                                </Badge>
                                                            ))
                                                        }
                                                    </Tab.Pane>
                                                ))
                                            }
                                        </Tab.Content>
                                    </Col>
                                </Row>  
                            </Tab.Container>
                        )
                        :
                        <></>
                    }
                </Form.Group>
            </Row>
            {
                checkPatrimonialDamageRule() ? (
                    <Row className="mb-4">
                        <Form.Label className={styles.groupLabel}>Daño estimado</Form.Label>
                        <Form.Group as={Col} md={4}>
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingAmount"
                                    type="text" 
                                    required={!preCarga}
                                    placeholder="Ingrese el monto estimado del daño"
                                    className={styles.input}
                                    value={formattedAmount}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="floatingAmount" className={styles.label}>Monto</Form.Label>
                            </Form.Floating>
                        </Form.Group>
                        <Form.Group as={Col} md="auto" controlId="relatedInstitutionsValidation">
                            <Form.Label className={styles.label}>Instituciones afectadas</Form.Label>
                            <Row>
                            {
                                institutions.map(institution => (
                                <Col style={{ paddingRight: "0px" }}>
                                        <Badge 
                                            style={{ cursor: "pointer" }}
                                            className={institution.selected ? styles.badgeSelected : styles.badgeDefault}
                                            onClick={(e) => { handleSelectInstitution(e, institution); }}
                                        >
                                        {institution.label}
                                        </Badge>
                                    </Col>
                                ))                    
                            }
                            </Row>
                        </Form.Group>
                    </Row>                    
                )
                : 
                <></>
            }
            <Row className="mb-4">
                <Form.Label className={styles.groupLabel}>Relato conciso de los hechos</Form.Label>
                <Form.Group as={Col} controlId="relatoValidation">
                    <Form.Floating>
                        <Form.Control
                            id="floatingRelato"
                            as="textarea"
                            required={true}
                            rows={10}
                            className={styles.textarea}
                            placeholder="Descripción concisa de los hechos"
                            value={fact}
                            onChange={e => setFact(e.target.value)}
                        />
                        <label htmlFor="floatingRelato" className={styles.label}>Descripción concisa de los hechos</label>
                    </Form.Floating>
                </Form.Group>
            </Row>

            <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                    <Button bsPrefix={styles.next} onClick={handlePrevious} >ANTERIOR</Button>
                </Col>
                <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                    <Button bsPrefix={styles.next} type="submit">SIGUIENTE</Button>
                </Col>                    
            </Row>            
        </Form>
    );

}

export default Hecho;