export const JudgmentType = {
    ACCEPT: {
        code: "ACCEPT",
        name: "Aceptado",
        bg: "success"
    },
    REJECT: {
        code: "REJECT",
        name: "Rechazado",
        bg: "danger"
    },
    UNDETERMINED: {
        code: "UNDETERMINED",
        name: "Indeterminado",
        bg: "secondary"
    }
}; 