import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import styles from "./EditDateModal.module.css";
import axios from "axios";

const EditDateModal = ( { cause, show, handleClose, callback } ) => {

    const [ date, setDate ] = useState(undefined);
    const [ validated, setValidated ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }    
        else {
            event.preventDefault();
            setValidated(true);
            patch();
        }
    };    

    const patch = () => {
        setLoading(true);
        const data = {
            data: [
                {
                    action: "REPLACE",
                    field: "CREATION_DATE",
                    value: date
                }
            ]
        };
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${cause.id}`, data, prepareHeaders())
        .then(res => {
            setLoading(false);
            callback();
        })
        .catch(err => {
            setLoading(false);
        });
    }

    const checkDigits = (digit) => {
        if(digit > 9){
            return digit
        };
        return "0" + digit;
    }

    useEffect(() => {
        if( cause != undefined ){
            let date = new Date(cause.createdAt);
            let formattedDate = checkDigits(date.getFullYear()) + "-" + checkDigits(date.getMonth()+1) + "-" + checkDigits(date.getDate());
            setDate(formattedDate);            
        }
    }, 
    [cause])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Editar fecha de creación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3 mt-2">
                        <Form.Group as={Col} controlId="typeValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingPossibleDate"
                                    type="date" 
                                    required
                                    value={date}
                                    onChange={e => setDate(e.target.value)}
                                    className={styles.input}
                                />
                                <Form.Label htmlFor="floatingPossibleDate" className={styles.label}>Fecha de creación</Form.Label>
                            </Form.Floating>
                            <Form.Control.Feedback>{`Fecha válida ✓`}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Debes ingresar una fecha</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mt-4" style={{ borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                        <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                            <Button variant="primary" type="submit" style={{ marginLeft: "10px"}}>Editar</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )

};

export default EditDateModal;