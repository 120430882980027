import React, { useEffect } from "react";
import { Popover, Tabs, Tab, Row, Col } from "react-bootstrap";
import NumberDisplay from '../../../../components/charts/NumberDisplay';
import { CauseStatus } from "../../../../constants/CauseStatus";

const ActiveCausesDetail = React.forwardRef(

    ({ popper, children, show: _, ...props }, ref) => {

      useEffect(() => popper.scheduleUpdate(), [children, popper]);
      useEffect(() => console.log(props), []);
  
      return (
        <Popover ref={ref} body {...props}>
            <Popover.Body style={{ padding: "0px", width: "100%" }}>
                <Tabs defaultActiveKey="status" id="justify-tab-example" className="mb-3" justify>
                    <Tab eventKey="status" title="Estado">
                        <Row>
                            {
                                !props.data.detail 
                                ? <span>No existen causas activas</span> 
                                : 
                                <>
                                    {
                                        props.data.detail.map((detail, k) => (
                                            <Col>
                                                <NumberDisplay value={detail.total} id={`${props.id}${k}`} title={(<>{CauseStatus[detail.status].name}</>)} digit={30} label={10}/>
                                           </Col>
                                        ))   
                                    }
                                </>
                            }
                        </Row>
                    </Tab>
                    <Tab eventKey="complexity" title="Complejidad">
                        <Row>
                            <Col md={4}>
                                <NumberDisplay value={props.data.low} id={`${props.id}10`} title={(<>Baja</>)} digit={30} label={10}/>
                            </Col>
                            <Col md={4}>
                                <NumberDisplay value={props.data.medium} id={`${props.id}11`} title={(<>Media</>)} digit={30} label={10}/>
                            </Col>
                            <Col md={4}>
                                <NumberDisplay value={props.data.high} id={`${props.id}12`} title={(<>Alta</>)} digit={30} label={10}/>
                            </Col>                                                        
                        </Row>
                    </Tab>
                </Tabs>
            </Popover.Body>
        </Popover>
      );
    },
  );

export default ActiveCausesDetail;