import React, { useState } from "react";
import { Modal, Form, Row, Col, Button, Badge, Spinner } from "react-bootstrap";
import styles from "./AttachmentAdd.module.css";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const AttachmentAdd = ({ show, handleClose, callback, data }) => {

    const [ name, setName ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ description, setDescription ] = useState(undefined);
    const [ type, setType ] = useState("DIGITAL");
    const [ location, setLocation ] = useState(undefined);
    const [ file, setFile ] = useState(undefined);
    const { keycloak } = useKeycloak();

    const types = [
        {
            label: "Digital",
            value: "DIGITAL"
        },
        {
            label: "Físico",
            value: "FISICO"
        }
    ];    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const prepareHeadersMultipart = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        

    const uploadFile = async () => {
        if(file){
            let formData = new FormData();
            formData.append('files', file);
            await axios.post(process.env.REACT_APP_API_URL + "/files", formData, prepareHeadersMultipart())
            .then(res => {
                let aux = file;
                for(const saved of res.data){
                    if(aux.name === saved.fileName.replace(`${saved.id}-`, '')){
                        aux.id = saved.id;
                        break;
                    }
                }
                patch(aux);
            })
            .catch(error => {
                console.log(error);
            });            
        }
        else {
            handleClose();
        }
    }    

    const buildBody = ( uploadedFile ) => {
        let elements = [
            {
                action: "ADD",
                field: "ATTACHMENT",
                value: {
                    name, 
                    description,
                    type, 
                    location,
                    file: {
                        id: uploadedFile.id,
                        fileName: uploadedFile.name
                    }
                }
            }
        ];
        return { data: elements };
    }    

    const patch = ( uploadedFile ) => {
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(uploadedFile), prepareHeaders())
        .then(
            res => {
                toast.success("Datos actualizados", { position: "top-center"});
                setLoading(false);
                callback();
            }
        )
        .catch(
            err => {
                toast.error("Ha ocurrido un error al actualizar los datos", { position: "top-center" });
                setLoading(false);
            }
        );
    }

    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar documento adjunto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mt-2 mb-2">
                            <Form.Group as={Col} controlId="typeValidation">
                                <Form.Label className={styles.label}>Tipo de documento</Form.Label>
                                {
                                    types.map(
                                        ( current ) => (
                                            <Form.Check type="radio">
                                                <Form.Check.Input 
                                                    required
                                                    type="radio"
                                                    name="documentType"
                                                    value={current.value}
                                                    onChange={(e) => { setType(e.target.value); }}
                                                    checked={type === current.value}
                                                    className={styles.checkbox}
                                                />
                                                <Form.Check.Label className={styles.checkboxLabel}>{current.label}</Form.Check.Label>
                                            </Form.Check>
                                        )
                                    )
                                }
                                <Form.Control.Feedback type="invalid">Debes seleccionar el tipo de documento</Form.Control.Feedback>
                            </Form.Group>
                        </Row>            
                        <Row className="mb-2">
                            <Form.Group as={Col} md={4} controlId="nameValidation">
                                <Form.Floating>
                                    <Form.Control
                                        required
                                        id="floatingName"
                                        type="text" 
                                        value={name}
                                        onChange={(e) => { setName(e.target.value); }}
                                        placeholder="Ingrese el nombre de la evidencia" 
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingName">Nombre</label>
                                </Form.Floating>
                                <Form.Control.Feedback type="invalid">Debes ingresar el nombre de la evidencia</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={8} controlId="descriptionValidation">
                                <Form.Floating>
                                    <Form.Control
                                        required
                                        id="floatingDescription"
                                        type="text" 
                                        value={description}
                                        onChange={(e) => { setDescription(e.target.value); }}
                                        placeholder="Ingrese la descripción de la evidencia" 
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingDescription">Descripción</label>
                                </Form.Floating>
                                <Form.Control.Feedback type="invalid">Debes ingresar el nombre de la evidencia</Form.Control.Feedback>
                            </Form.Group>                
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} controlId="locationValidation">
                                <Form.Floating>
                                    <Form.Control
                                        required={type == "FISICO"}
                                        id="floatingLocation"
                                        type="text" 
                                        value={location}
                                        onChange={(e) => { setLocation(e.target.value); }}
                                        placeholder="Ingrese la ubicación de la evidencia" 
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingLocation">Ubicación</label>
                                </Form.Floating>
                                <Form.Control.Feedback type="invalid">Debes ingresar la ubicación de la evidencia</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="reportsValidation">
                                <Form.Label className={styles.label}>Documento adjunto</Form.Label>
                                <Row>
                                    <Col md={12}>
                                        <Form.Control
                                            type="file"                             
                                            multiple={false}
                                            placeholder="Seleccione el documento adjunto" 
                                            className={styles.fileInput}
                                            onChange={(e) => { setFile(e.target.files[0]) }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    (file) ? 
                                        <Row className="mt-2">
                                            <Col md="auto" style={{ paddingRight: "0px" }}>
                                                <Badge bg="secondary">{file.name}</Badge>
                                            </Col>
                                        </Row>
                                    :
                                    <></> 
                                }                    
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={uploadFile} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

};

export default AttachmentAdd;