import React from "react";
import { Modal, Button, Form, Badge, Col, Row } from "react-bootstrap";
import styles from "./DefendantModal.module.css";

const DefendantModal = ({ data, show, handleClose }) => {

    return data ? (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Datos del denunciado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="mb-4">
                        <Form.Group as={Col} controlId="nameValidation">
                            <Form.Floating>
                                <Form.Control
                                    id="floatingName"
                                    type="text" 
                                    value={data.firstName}
                                    className={styles.input}
                                />
                                <label className={styles.label} htmlFor="floatingName">Nombres</label>
                            </Form.Floating>
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastNameValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingLastName"
                                    value={data.lastName}
                                    className={styles.input}
                                    type="text" 
                                />
                                <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                            </Form.Floating>                        
                        </Form.Group>
                        <Form.Group as={Col} controlId="birthDateValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingBirthDate"
                                    value={data.birthDate}
                                    className={styles.input}
                                    type="date" 
                                />
                                <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                            </Form.Floating>
                        </Form.Group>                       
                    </Row>
                    <Row className="mb-4">
                        <Form.Group as={Col} controlId="documentTypeValidation">
                            <Form.Floating>
                                <Form.Control 
                                    id="floatingDocumentType" 
                                    className={styles.input} 
                                    as="select" 
                                    value={data.document.type} 
                                >
                                    <option value="CI">Cédula de identidad</option>
                                    <option value="PAS">Pasaporte</option>
                                    <option value="MAT">Matrícula de la Corte Suprema de Justicia</option>
                                </Form.Control>
                                <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                            </Form.Floating>
                        </Form.Group>
                        <Form.Group as={Col} controlId="documentValueValidation">
                            <Form.Floating>
                                <Form.Control
                                    id="floatingDocumentNumber" 
                                    type={data.document.type === "CI" ? "number" : "text"}
                                    value={data.document.number} 
                                    className={styles.input}
                                />
                                <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                            </Form.Floating>
                        </Form.Group>
                    </Row>
                    {
                        ( data.address.department && data.address.city ) ? (
                            <Row className="mb-2">
                                {
                                    data.address.department ? (
                                        <Col>
                                            <Badge>{data.address.department.name}</Badge>
                                        </Col>                            
                                    )
                                    : 
                                    <></>
                                }
                                {
                                    data.address.city ? (
                                        <Col>
                                            <Badge>{data.address.city.name}</Badge>
                                        </Col>
                                    )
                                    : 
                                    <></>
                                }
                                {
                                    data.address.neighborhood ? (
                                        <Col>
                                            <Badge>{data.address.neighborhood.name}</Badge>
                                        </Col>                            
                                    )
                                    : 
                                    <></>
                                }
                            </Row>
                        )
                        : 
                        <></>
                    }
                    <Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} controlId="principalStreetValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingPrincipalStreet"
                                        type="text"
                                        value={data.address.street.principal}
                                        className={styles.input}
                                    />
                                    <label htmlFor="floatingPrincipalStreet" className={styles.label}>Calle principal</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="secondaryStreetValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingSecondaryStreet"
                                        type="text"
                                        value={data.address.street.secondary}
                                        className={styles.input}
                                    />
                                    <label htmlFor="floatingSecondaryStreet" className={styles.label}>Calle secundaria</label>
                                </Form.Floating>
                            </Form.Group>            
                            <Form.Group as={Col} controlId="houseNumberValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingHouseNumber"
                                        type="number"
                                        value={data.address.houseNumber}
                                        className={styles.input}
                                    />
                                    <label htmlFor="floatingHouseNumber" className={styles.label}>Número de residencia</label>
                                </Form.Floating>
                            </Form.Group>        
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="descriptionValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingDescription"
                                        type="text"
                                        value={data.address.description}
                                        className={styles.input}
                                    />
                                    <label htmlFor="floatingDescription" className={styles.label}>Descripción</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
    : 
    <></>;

};

export default DefendantModal;