export const Rules = {
    "crime type rule": {
        name: "Hecho punible de competencia"
    },
    "public official rule": {
        name: "El/los demandados deben ser funcionarios públicos según el/los hechos punibles"
    },
    "patrimonial damage rule": {
        name: "El daño al patrimonio debe alcanzar la cantidad de jornales definida"
    },
    "tax evasion rule": {
        name: "El daño por evasión de impuestos debe alcanzar la cantidad de jornales definida"
    },
    "fraudulent adquisition of subsidies rule": {
        name: "El daño por adquisición fraudulenta de subveciones debe alcanzar la cantidad de jornales definida"
    },
    "exaction damage rule": {
        name: "El daño por exacción debe alcanzar la cantidad de jornales definida"
    },
    "territorial jurisdiction rule": {
        name: "La ciudad de ejecución del hecho debe ser Asunción, según la jurisdicción territorial de la unidad"
    }
};