export const CauseStatus = {
    RECEIVED: {
        code: "RECEIVED",
        name: "Recibida",
        otherName: "recibida"
    },
    ANALYZED: {
        code: "ANALYZED",        
        name: "Analizada",
        otherName: "analizada"        
    },
    ASSIGNATION_PROCESS: {
        code: "ASSIGNATION_PROCESS",        
        name: "En proceso de asignación",
        otherName: "en proceso de asignación"
    },
    ASSIGNED: {
        code: "ASSIGNED",        
        name: "Asignada",
        otherName: "asignada"
    },
    INVESTIGATION_PROCESS: {
        code: "INVESTIGATION_PROCESS",
        name: "En proceso de investigación",
        otherName: "en proceso de investigación"
    },
    IMPUTED: {
        code: "IMPUTED",
        name: "Imputada",
        otherName: "imputada"
    },
    ABBREVIATE_PROCESS: {
        code: "ABBREVIATE_PROCESS",
        name: "Proceso abreviado",
        otherName: "con proceso abreviado"
    },
    OPPORTUNITY_CRITERIA: {
        code: "OPPORTUNITY_CRITERIA",
        name: "En criterio de oportunidad",
        otherName: "en criterio de oportunidad"
    },
    ON_CONDITIONAL_SUSPENSION: {
        code: "ON_CONDITIONAL_SUSPENSION",
        name: "En suspensión condicional",
        otherName: "en suspensión condicional"
    },
    CONCILIATED: {
        code: "CONCILIATED",
        name: "Conciliada",
        otherName: "conciliada"
    },
    ARCHIVED: {
        code: "ARCHIVED",
        name: "Archivada",
        otherName: "archivada"
    },
    REJECTED: {
        code: "REJECTED",
        name: "Desestimada",
        otherName: "desestimada"
    }
};