import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Badge } from "react-bootstrap";
import styles from "../../DenunciaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const InformesPericiales = ( { next, previous, files, setFiles } ) => {

    const [ validated, setValidated ] = useState(false);

    const { keycloak } = useKeycloak();

    const handleNext = () => {        
        next(files);
    }    

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(files);
    }     

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(true);
            uploadFiles();
        }
    };      

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    
    
    const uploadFiles = async () => {
        if(files && files.length > 0){
            if(files.filter(file => file.id).length === 0){
                let formData = new FormData();
                for (let file of files) {
                    formData.append('files', file);
                }
                await axios.post(process.env.REACT_APP_API_URL + "/files", formData, prepareHeaders())
                .then(res => {
                    let aux = [];
                    for(let current of files){
                        for(const saved of res.data){
                            if(current.name === saved.fileName.replace(`${saved.id}-`, '')){
                                current.id = saved.id;
                                break;
                            }
                        }
                        aux.push(current);
                    }
                    setFiles(aux);
                    handleNext();
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else {
                handleNext();
            }
        }
        else {
            handleNext();
        }
    }

    useEffect(() => {
        console.log(files);
    }, 
    [files]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mt-2 mb-4">
                <Form.Group as={Col} controlId="reportsValidation">
                    <Form.Label className={styles.groupLabel}>Informes periciales</Form.Label>
                    <Row>
                        <Col md={12}>
                            <Form.Control
                                type="file"
                                multiple
                                onChange={(e) => { setFiles(Array.from(e.target.files)); }}
                                placeholder="Seleccione los informes" 
                                className={styles.fileInput}
                            />                    
                        </Col>
                    </Row>
                    {
                        (files && files.length > 0) ? 
                            <Row className="mt-2">
                                {
                                    files.map(file => (
                                        <Col md="auto" style={{ paddingRight: "0px" }}><Badge bg="secondary">{file.name}</Badge></Col>
                                    ))
                                }
                            </Row>                                
                        : 
                        <></> 
                    }
                </Form.Group>
            </Row>
            <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                    <Button bsPrefix={styles.next} onClick={handlePrevious} >ANTERIOR</Button>
                </Col>
                <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                    <Button bsPrefix={styles.next} type="submit">SIGUIENTE</Button>
                </Col>                    
            </Row>
        </Form>
    );

}

export default InformesPericiales;