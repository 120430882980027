import React, { useState } from "react";
import { Row, Col, Form, Badge, ListGroup, Button, Dropdown } from "react-bootstrap";
import styles from "./Causa.module.css";
import { CauseStatus } from "../../../../constants/CauseStatus";
import { useNavigate } from "react-router-dom";
import EditDateModal from "./EditDateModal";
import { Complexity } from "../../../../constants/Complexity";
import AssignFiscal from "./AssignFiscal";
import GenericStatusModal from "./GenericStatusModal";
import ImputationModal from "./ImputationModal/ImputationModal";
import DisassociateModal from "./DisassociateModal/DisassociateModal";
import toast from 'react-hot-toast';
import Options from "./Options";

const Causa = ( { cause, callback } ) => {

    const [ showAssign, setShowAssign ] = useState(false);
    const [ showEditDate, setShowEditDate ] = useState(false);

    const [ showConciliateStatus, setShowConciliateStatus ] = useState(false);
    const [ showOnConditionalSuspension, setShowOnConditionalSuspension ] = useState(false);
    const [ showOpportunityCriteria, setShowOpportunityCriteria ] = useState(false);
    const [ showAbbreviateProcess, setShowAbbreviateProcess ] = useState(false);
    const [ showArchive, setShowArchive ] = useState(false);
    const [ showReject, setShowReject ] = useState(false);
    const [ showImputation, setShowImputation ] = useState(false);
    const [ showReopen, setShowReopen ] = useState(false);
    const [ showDisassociate, setShowDisassociate ] = useState(false);

    const navigate = useNavigate();

    return (
        <>
            <GenericStatusModal toast={toast} show={showConciliateStatus} handleClose={() => setShowConciliateStatus(false)} cause={cause} callback={callback} route={"conciliate"} />
            <GenericStatusModal toast={toast} show={showAbbreviateProcess} handleClose={() => setShowAbbreviateProcess(false)} cause={cause} callback={callback} route={"abbreviate/process"} />
            <GenericStatusModal toast={toast} show={showOnConditionalSuspension} handleClose={() => setShowOnConditionalSuspension(false)} cause={cause} callback={callback} route={"conditional/suspension"} />
            <GenericStatusModal toast={toast} show={showOpportunityCriteria} handleClose={() => setShowOpportunityCriteria(false)} cause={cause} callback={callback} route={"opportunity/criteria"} />
            <GenericStatusModal toast={toast} show={showArchive} handleClose={() => setShowArchive(false)} cause={cause} callback={callback} route={"archive"} />
            <GenericStatusModal toast={toast} show={showReject} handleClose={() => setShowReject(false)} cause={cause} callback={callback} route={"reject"} />
            <GenericStatusModal toast={toast} show={showReopen} handleClose={() => setShowReopen(false)} cause={cause} callback={callback} route={"reopen"} />
            <ImputationModal toast={toast} show={showImputation} handleClose={() => setShowImputation(false)} cause={cause} callback={callback} />
            <DisassociateModal toast={toast} show={showDisassociate} handleClose={() => setShowDisassociate(false)} cause={cause} callback={callback} />
            <AssignFiscal toast={toast} show={showAssign} handleClose={() => setShowAssign(false)} cause={cause} callback={callback}/>
            <EditDateModal show={showEditDate} handleClose={() => { setShowEditDate(false); }} callback={callback} cause={cause} />            

            <div className={styles.container}>
                <Row>
                    <Col md={8}>
                        <Form.Label className={styles.title}>{`Nº ${cause.id}`}</Form.Label>
                    </Col>
                    <Col md={4} style={{ textAlign: "right" }}>
                        <div className={styles.actionsContainer}>
                            {
                                cause.causeSeverityLevel ? (
                                    <>
                                        <Badge pill className={styles.complexityLevel} bg={cause.causeSeverityLevel === "LOW" ? "success" : (cause.causeSeverityLevel === "MEDIUM" ? "warning" : "danger")}>{`Complejidad ${Complexity[cause.causeSeverityLevel].name}`}</Badge>&nbsp;
                                    </>
                                ) 
                                : <></>
                            }                        
                            <Badge className={styles.status} pill>{CauseStatus[cause.status].name}</Badge>
                            <Options 
                                cause={cause} 
                                callback={callback} 
                                style={styles.option} 
                                item={styles.optionItem} 
                                setShowEditDate={setShowEditDate}
                                setShowAssign={setShowAssign}
                                setShowArchive={setShowArchive}
                                setShowReject={setShowReject}
                                setShowImputation={setShowImputation}
                                setShowOpportunityCriteria={setShowOpportunityCriteria}
                                setShowOnConditionalSuspension={setShowOnConditionalSuspension}
                                setShowAbbreviateProcess={setShowAbbreviateProcess}
                                setShowConciliateStatus={setShowConciliateStatus}
                                setShowReopen={setShowReopen}
                                setShowDisassociate={setShowDisassociate}
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: ".5rem" }}>
                    <Form.Group className={styles.group} as={Col}>
                        <Form.Label className={styles.groupLabel} style={{ marginBottom: "0px"}}>Carátula</Form.Label>                        
                        <Form.Control bsPrefix={styles.input} type="text" value={cause.cover ? cause.cover : "-"} disabled/>                        
                    </Form.Group>
                </Row>
                <Row style={{ marginBottom: "1rem" }}>
                    <Col md="auto">
                        <Form.Group className={styles.group}>
                            <Form.Label className={styles.groupLabel}>Datos generales</Form.Label>
                            <Row style={{ marginTop: "0.2rem" }}>
                                <Col>
                                    <Form.Label as={Col} bsPrefix={styles.label}>Fecha y hora de la causa</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="date-time" value={cause.createdAt} disabled />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md="auto">
                        <Form.Group className={styles.group}>
                            <Form.Label className={styles.groupLabel}>Unidad fiscal</Form.Label>
                            <Row style={{ marginTop: "0.2rem" }}>
                                <Col md="auto">
                                    <Form.Label as={Col} bsPrefix={styles.label}>Fiscal principal</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="text" value={cause.principalProsecutor ? `${cause.principalProsecutor.firstName} ${cause.principalProsecutor.lastName}` : "No definido"} disabled/>
                                </Col>
                                <Col md="auto">
                                    <Form.Label as={Col} bsPrefix={styles.label}>Fiscales coadyuvantes</Form.Label>
                                    {
                                        cause.prosecutors ? (
                                            <ListGroup variant="flush">
                                                { 
                                                    cause.prosecutors.map((prosecutor) => (
                                                        <ListGroup.Item bsPrefix={styles.input}>{`${prosecutor.firstName} ${prosecutor.lastName}`}</ListGroup.Item>
                                                    ))
                                                }
                                            </ListGroup>
                                        )
                                        : <span className={styles.input}>No definido</span>
                                    }
                                </Col>
                            </Row>
                        </Form.Group>                        
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1rem" }}>
                    <Col md="auto">
                        <Form.Group className={styles.group}>
                            <Form.Label className={styles.groupLabel}>Denuncia</Form.Label>
                            <Row style={{ marginTop: "0.2rem" }}>
                                <Col md="auto">
                                    <Form.Label as={Col} bsPrefix={styles.label}>Identificador</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="number" value={cause.complaint.id} disabled/>
                                </Col>
                                <Col md="auto">
                                    <Form.Label as={Col} bsPrefix={styles.label}>Tipo</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="text" value={cause.complaint.type} disabled/>
                                </Col>                            
                                <Col md="auto">
                                    <Form.Label as={Col} bsPrefix={styles.label}>Fecha y hora de recepción</Form.Label>
                                    <Form.Control bsPrefix={styles.input} type="date-time" value={cause.complaint.createdAt} disabled />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>            
                <Row style={{ display: "flex" }}>
                    <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                        <Button bsPrefix={styles.next} onClick={(e) => { navigate(`/causas/${cause.id}`) }}>VER</Button>
                    </Col>
                </Row>
            </div>
        </>
    )

}

export default Causa;