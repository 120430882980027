import React, { useState, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown, Form, Button, Badge } from "react-bootstrap";
import { Loader } from 'rsuite';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiSortAsc, RiSortDesc, RiCloseFill } from "react-icons/ri";
import { useKeycloak } from "@react-keycloak/web";
import CustomPagination from "../../components/pagination/CustomPagination";
import styles from "./Alerts.module.css";
import axios from 'axios';
import Alert from "./Alert";

const Alerts = () => {

    const { keycloak } = useKeycloak();

    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(10);
    const [causeId, setCauseId] = useState(undefined);
    const [complaintId, setComplaintId] = useState(undefined);
    const [lteDate, setLteDate] = useState(undefined);
    const [gteDate, setGteDate] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [sorting, setSorting] = useState([]);    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",                
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    


    const findAlerts = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/alerts?page=${page}&pageSize=${pageSize}`, prepareHeaders())
        .then(
            res => { 
                setAlerts(res.status == 204 ? [] : res.data.content); 
                setTotalPages(res.status == 206 ? res.data.totalPages : 10);
                setLoading(false);
            }
        )
        .catch(
            err => {
                setAlerts([]);
                setLoading(false);
            }
        );
    };    

    const sortingBy = (field, label) => {
        if(sorting.filter((element) => element.key === field).length > 0){
            setSorting(sorting.filter((element) => element.key !== field));
        }
        else {
            setSorting([...sorting, { key: field, label, direction: "ASC" }]);
        }
    }    

    const changeDirection = (sort) => {
        let elements = [];
        for(let current of sorting){
            if(current.key === sort.key){
                current.direction = sort.direction === "ASC" ? "DESC" : "ASC";
            }
            elements.push(current);
        }
        setSorting(elements);
    }    

    useEffect(() => findAlerts(), []);
    useEffect(() => findAlerts(), [page, sorting]);

    return (
        <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <Col md={3}>
                <div className={styles.sideBar}>
                    <Form className={styles.filterContainer}>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col>
                                <Form.Label as={Col} bsPrefix={styles.label}>Número de causa</Form.Label>
                                <Form.Control className={styles.input} type="number" placeholder="ID de la causa" value={causeId} onChange={(e) => { setCauseId(e.target.value); }}/>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col>
                                <Form.Label as={Col} bsPrefix={styles.label}>Número de denuncia</Form.Label>
                                <Form.Control className={styles.input} type="number" placeholder="ID de la denuncia" value={complaintId} onChange={(e) => { setComplaintId(e.target.value); }}/>
                            </Col>
                        </Row>                            
                        <Row>
                            <Col>
                                <Form.Label as={Col} bsPrefix={styles.label}>Fecha de la alerta</Form.Label>
                                <Form.Control className={styles.input} type="date" value={gteDate} onChange={(e) => { setGteDate(e.target.value); }}/>
                            </Col>
                        </Row>
                        <Row  style={{ marginBottom: "1rem" }}>
                            <Col>
                                <Form.Control className={styles.input} type="date" value={lteDate} onChange={(e) => { setLteDate(e.target.value); }} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col style={{ textAlign: "right", marginTop: "auto", display: "flex" }}>
                                <Button onClick={() => findAlerts()} bsPrefix={styles.primary} style={{ marginLeft: "auto" }}>Buscar <AiOutlineSearch /></Button>&nbsp;&nbsp;
                                <DropdownButton bsPrefix={styles.secondary} title="Ordenar">
                                    <Dropdown.Item onClick={(e) => sortingBy("created_at", "Fecha de la alerta")} className={styles.item}>Fecha de la alerta</Dropdown.Item>
                                </DropdownButton>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>            
            <Col md={9}>
                <div className={styles.container}>
                    <h2>Alertas</h2>
                    {
                        loading
                        ? <Loader size="lg" content="Cargando componente..." />
                        : 
                        (
                            <>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={(page) => { setPage(page); }} totalPages={totalPages}/>
                                    </Col>
                                    <Col md={6} style={{ marginTop: "1rem", textAlign: "right" }}>
                                        {
                                            sorting.map((sort) => (
                                                <>
                                                    <Badge className={styles.sortItem}>
                                                        {sort.label}
                                                        &nbsp;
                                                        {
                                                            sort.direction === "ASC"
                                                            ? <RiSortAsc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/> 
                                                            : <RiSortDesc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/>
                                                        }
                                                        &nbsp; 
                                                        <RiCloseFill style={{ cursor: "pointer" }} onClick={(e) => {sortingBy(sort.key)}}/>
                                                    </Badge>&nbsp;
                                                </>
                                            ))
                                        }
                                    </Col>
                                </Row>
                                { alerts.map((alert) => ( <Alert alert={alert} callback={() => { findAlerts() }}/> )) }
                            </>
                        )
                    }                           
                </div>
            </Col>
        </Row>
    )

};

export default Alerts;