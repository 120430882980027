import PropTypes from 'prop-types';
import { useKeycloak } from "@react-keycloak/web";
import { roles, checkPermissions } from "../Keycloak";
import { useNavigate } from "react-router-dom";

export const PrivateRoute = ({ children, allowedRoles }) => {

    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;
    if(isLoggedIn && checkPermissions(roles(keycloak), allowedRoles)){
        return children;
    }
    else {
        navigate('/');
        return null;
    }
};

PrivateRoute.propTypes = {
    allowedRoles: PropTypes.array.isRequired
}

export default PrivateRoute;