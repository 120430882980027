import React from "react";
import styles from "./DisassociateReplyInfo.module.css";
import { Modal, Button, Form, Row, Col, Badge } from "react-bootstrap";


const DisassociateReplyInfo = ( { show, handleClose, reply } ) => {

    return reply ? (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title><h5>{reply.judgment === "APPROVED" ? "Datos de la aprobación" : "Datos del rechazo"}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Motivo</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ingrese el motivo"
                                className={styles.input} 
                                value={reply.reason} 
                                disabled
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Archivos adjuntos</Form.Label>
                            {
                                (reply.attachments && reply.attachments.length > 0) ? 
                                    <Row>
                                        {
                                            reply.attachments.map(file => (
                                                <Col md="auto" style={{ paddingRight: "0px" }}><Badge bg="secondary">{file.fileName}</Badge></Col>
                                            ))
                                        }
                                    </Row>                                
                                : 
                                <></> 
                            }  
                        </Form.Group>
                    </Row>       
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className={styles.label}>Fecha de confirmación</Form.Label>
                            <Form.Control 
                                type="text" 
                                className={styles.input} 
                                value={reply.createdAt} 
                                disabled
                            />
                        </Form.Group>
                    </Row>                               
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
    :
    <></>;

}

export default DisassociateReplyInfo;