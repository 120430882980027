import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card, Badge, Button, InputGroup, Spinner } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { useKeycloak } from "@react-keycloak/web";
import styles from "./CompetenceRules.module.css";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import CompetenceRuleAddModal from "./CompetenceRuleAddModal";

const CompetenceRules = () => {

    const [ jornal, setJornal ] = useState(undefined);
    const [ loadingJornal, setLoadingJornal ] = useState(false);
    const [ loadingSaveJornal, setLoadingSaveJornal ] = useState(false);

    const [ city, setCity ] = useState(undefined);
    const [ department, setDepartment ] = useState(undefined);
    const [ loadingPlace, setLoadingPlace ] = useState(false);
    const [ loadingSavePlace, setLoadingSavePlace ] = useState(false);

    const [ competenceCrimes, setCompetenceCrimes ] = useState([]);
    const [ loadingCompetence, setLoadingCompetence ] = useState(false);
    const [ loadingSaveCompetence, setLoadingSaveCompetence ] = useState(false);

    const [ cities, setCities ] = useState([]);
    const [ departments, setDepartments ] = useState([]);

    const [ competenceRuleModal, setCompetenceRuleModal ] = useState(false);

    const { keycloak } = useKeycloak();    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }     

    const crimes = [
        {
            article: 192,
            name: "Lesión de confianza",
            law: {
                number: 12,
                name: "Código Penal"
            }
        },
        {
            article: 261,
            name: "Evasión de impuestos",
            law: {
                number: 12,
                name: "Código Penal"
            }
        }
    ];

    const buildJornalBody = () => {
        return {
            code: "JORNAL_AMOUNT_VALUE",
            description: "Valor de un jornal en guaraníes",
            value: {
                amount: {
                    currency: "PYG",
                    value: jornal,
                    decimals: 0
                }
            }
        };
    }

    const changeJornal = (e) => {
        e.preventDefault();
        setLoadingSaveJornal(true);
        try {
            axios.put(`${process.env.REACT_APP_API_URL}/analyzer/competence/features`, buildJornalBody(), prepareHeaders()).
            then(res => {
                setLoadingSaveJornal(false);
                toast.success("Jornal actualizado con éxito");
                findJornal();
            }).
            catch(err => {
                setLoadingSaveJornal(false);
                toast.error("Ha ocurrido un error al actualizar el jornal");
            });
        }
        catch(err){
            setLoadingSaveJornal(false);
            toast.error("Ha ocurrido un error al actualizar el jornal");
        }
    }

    const changePlace = (e) => {
        e.preventDefault();
        setLoadingSavePlace(true);
        setLoadingSavePlace(false);
    }    

    const findJornal = () => {
        try {
            setLoadingJornal(true);
            axios.get(`${process.env.REACT_APP_API_URL}/analyzer/competence/features?code=JORNAL_AMOUNT_VALUE`, prepareHeaders()).
            then(res => {
                setJornal(res.data.value.amount.value);
                setLoadingJornal(false);
            }).
            catch(err => {
                setJornal(undefined);
                setLoadingJornal(false);
            });
        }
        catch(err){
            setJornal(undefined);            
            setLoadingJornal(false);
        }
    }

    const findStatementOfFactPlace = () => {
        try {
            setLoadingPlace(true);
            axios.get(`${process.env.REACT_APP_API_URL}/analyzer/competence/features?code=COMPETENCE_CITY`, prepareHeaders()).
            then(res => {
                setDepartment(res.data.value.department.id);
                setCity(res.data.value.city.id); 
                setLoadingPlace(false);
            }).
            catch(err => {
                setDepartment(undefined);
                setCity(undefined);
                setLoadingPlace(false);
            });
        }
        catch(err){
            setDepartment(undefined);
            setCity(undefined);
            setLoadingPlace(false);
        }
    }

    const findDeparments = () => {
        axios.get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
        .then(res => setDepartments(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };

    const findCities = ( departmentId ) => {
        axios.get(process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`, prepareHeaders() )
        .then(res => setCities(res.status == 200 ? res.data : []))
        .catch(error => {
            console.error(error);
        });
    };    

    const findCompetenceCrimes = () => {
        try {
            setLoadingCompetence(true);
            axios.get(`${process.env.REACT_APP_API_URL}/analyzer/competence/crimes`, prepareHeaders()).
            then(res => {
                setCompetenceCrimes(res.status == 200 ? res.data : []);
                setLoadingCompetence(false);
            }).
            catch(err => {
                setCompetenceCrimes([]);
                setLoadingCompetence(false);
            });
        }
        catch(err){
            setCompetenceCrimes([]);
            setLoadingCompetence(false);
        }        
    }

    const deleteCompetenceCrime = (e, id) => {
        e.preventDefault();
        try {
            axios.delete(`${process.env.REACT_APP_API_URL}/analyzer/competence/crimes/${id}`, prepareHeaders()).
            then(res => {
                toast.success("Regla de competencia eliminado con éxito");
                findCompetenceCrimes();
            }).
            catch(err => {
                toast.error("Ha ocurrido un error al eliminar la regla de competencia");
            });
        }
        catch(err){
            toast.error("Ha ocurrido un error al eliminar la regla de competencia");            
        }
    }

    useEffect(() => { findJornal(); findDeparments(); findStatementOfFactPlace(); findCompetenceCrimes(); }, []);

    useEffect(() => { if(department != undefined) { findCities(department); }}, [ department ]);

    const resolveBadge = (crime, condition) => {
        let data = <></>
        switch(condition.type){
            case "PUBLIC_OFFICIAL":
                data = (
                    <>
                        <Badge bg="secondary" className={styles.condition}>
                            <span>Cometido por funcionario público en ejercicio de sus funciones</span>
                        </Badge>
                        &nbsp;&nbsp;
                    </>
                );
                break;
            case "DAMAGE_TO_STATE_PATRIMONY":
                data = (
                    <>
                        <Badge bg="secondary" className={styles.condition}>
                            <span>Perjuicio al patrimonio del estado</span>
                        </Badge>
                        &nbsp;&nbsp;
                    </>
                );
                break;
            case "LOSS_FOR_WAGES_AND_SALARIES":                
                data = (
                    <>
                        <Badge bg="secondary" className={styles.condition}>
                            <span>Perjuicio igual o superior a {condition.value} jornales</span>
                        </Badge>
                        &nbsp;&nbsp;
                    </>
                );
                break;
            default:
                break;
        }
        return data;
    }

    return (
        <>
            <Toaster />
            <CompetenceRuleAddModal
                show={competenceRuleModal}
                handleClose={() => setCompetenceRuleModal(false)} 
                callback={() => { setCompetenceRuleModal(false); findCompetenceCrimes(); }}
                competenceCrimes={competenceCrimes}
            />         
            <Form>
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>Valor de un jornal en guaraníes</Form.Label>
                    {
                        loadingJornal ? <span className="mt-3 mb-2">Buscando...</span>
                        : 
                        (!loadingJornal && !jornal) ? <span className="mt-3 mb-2">No hay resultados</span>
                        :
                        (
                            <div style={{ display: "flex" }}>
                                <InputGroup>
                                    <InputGroup.Text>Gs.</InputGroup.Text>                        
                                    <Form.Control placeholder="Ingrese el monto" type="number" value={jornal} onChange={(e) => { setJornal(e.target.value); }} />&nbsp;&nbsp;
                                    <Button onClick={changeJornal} disabled={ loadingSaveJornal }>
                                    { 
                                        loadingSaveJornal ? (
                                            <>
                                                <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                                <span>Guardando...</span>
                                            </>
                                        )
                                        :
                                        <span>Guardar</span>
                                    }
                                    </Button>                            
                                </InputGroup>
                            </div>
                        )
                    }
                </Form.Group>
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>Lugar del hecho</Form.Label>
                    {
                        loadingPlace ? <span className="mt-3 mb-2">Buscando...</span>
                        : 
                        (
                            <div style={{ display: "flex" }}>
                                <Form.Select id="floatingDepartment" className={styles.input} required value={department} onChange={(e) => { setDepartment(e.target.value); }}>
                                    <option value={undefined}>Seleccione</option>
                                    {
                                        departments.map((current) => (
                                            <option value={current.id}>{current.name}</option>
                                        ))
                                    }
                                </Form.Select>
                                &nbsp;&nbsp;
                                <Form.Select id="floatingCity" className={styles.input} required value={city} onChange={(e) => { setCity(e.target.value); }}>
                                    <option value={undefined}>Seleccione</option>
                                    {
                                        cities.map((current) => (
                                            <option value={current.id}>{current.name}</option>
                                        ))
                                    }
                                </Form.Select>
                                &nbsp;&nbsp;
                                <Button onClick={changePlace} disabled={ loadingSavePlace }>
                                    { 
                                        loadingSavePlace ? (
                                            <>
                                                <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                                <span>Guardando...</span>
                                            </>
                                        )
                                        :
                                        <span>Guardar</span>
                                    }
                                </Button>  
                            </div>
                        )
                    }
                </Form.Group>                
                <Form.Group as={Col} className={styles.group}>
                    <Form.Label className={styles.groupTitle}>
                        <Row>
                            <Col md="auto">
                                <span>Hechos punibles de competencia</span>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                                <Button className={styles.add} onClick={(e) => { e.preventDefault(); setCompetenceRuleModal(true); }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Form.Label>
                    {
                        competenceCrimes.map(competenceCrime => (
                            <Card className={styles.crimeGroup}>
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col className={styles.crimeTitle}>
                                                <span>{`Art. ${competenceCrime.crime.article} - ${competenceCrime.crime.name} / Ley N° ${competenceCrime.crime.law.number}`}</span>
                                            </Col>
                                            <Col style={{ textAlign: "right" }} md={1}>
                                                <AiFillDelete style={{ cursor: "pointer" }} onClick={(e) => { deleteCompetenceCrime(e, competenceCrime.id); }} title="Eliminar" />
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    {
                                        (competenceCrime.conditions && competenceCrime.conditions.length > 0) ? (
                                            <>
                                                <Row>
                                                    <Form.Label className={styles.subTitle}>Condiciones de competencia</Form.Label>
                                                </Row>
                                                <Row>
                                                    <Col>{ competenceCrime.conditions.map(condition => ( resolveBadge(competenceCrime, condition) )) }</Col>
                                                </Row>                                            
                                            </>
                                        )
                                        :
                                        <></>
                                    }
                                </Card.Body>
                            </Card>
                        ))
                    }
                </Form.Group>
            </Form>
        </>
    );

};

export default CompetenceRules;