import React, { useState, useEffect } from "react";
import { Form, Row, Table, Button, Badge } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import styles from "./Disassociate.module.css";
import { checkRoles } from "../../../../Keycloak";
import axios from "axios";
import DisassociateReply from "./DisassociateReply";
import DisassociateReplyInfo from "./DisassociateReplyInfo";

const Disassociate = ( { cause, callback } ) => {

    const { keycloak } = useKeycloak();

    const [ disassociates, setDisassociates ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ showReply, setShowReply ] = useState(false);
    const [ showReplyInfo, setShowReplyInfo ] = useState(false);
    const [ reply, setReply ] = useState(undefined);
    const [ current, setCurrent ] = useState(undefined);
    const [ judgment, setJudgment ] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const findDisassociates = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/causes/${cause.id}/disassociates`, prepareHeaders())
        .then(res => {
            setDisassociates(res.status == 200 ? res.data : []);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
        })
    }

    const approve = (e, id) => {
        e.preventDefault();
        setShowReply(true);
        setJudgment("APPROVED");
        setCurrent(id);
    }

    const reject = (e, id) => {
        e.preventDefault(); 
        setShowReply(true); 
        setJudgment("REJECTED");
        setCurrent(id);
    }

    const isFiscalAdjunto = () => {
        return checkRoles(keycloak) === "fiscal-adjunto";
    }
    
    useEffect(() => findDisassociates(), []);

    const handleCallback = () => {
        findDisassociates();
        callback();
    }

    return (
        <>
            <DisassociateReply show={showReply} handleClose={() => setShowReply(false)} cause={cause} callback={handleCallback} judgment={judgment} id={current} /> 
            <DisassociateReplyInfo show={showReplyInfo} reply={reply} handleClose={() => setShowReplyInfo(false)} />
            <Row>
                <Form.Label className={styles.groupLabel}>Solicitudes de inhibición</Form.Label>
            </Row>
            <Row className="mt-2">
                {
                    disassociates && disassociates.length > 0 ? (
                        <>
                            <Table className={`${styles.table} mb-1`}>
                                <thead>
                                    <th>ID</th>
                                    <th>Motivo</th>
                                    <th>Documentos adjuntos</th>
                                    <th>Fecha de solicitud</th>
                                    <th>Solicitado por</th>
                                    {
                                        isFiscalAdjunto() ? (
                                            <th>Acciones</th>
                                        )
                                        : 
                                        <></>
                                    }
                                    
                                </thead>
                                <tbody>
                                    {
                                        disassociates.map(detail => (
                                            <tr>
                                                <td>{detail.id}</td>
                                                <td>{detail.reason}</td>
                                                <td>
                                                    {
                                                        (detail.attachments && detail.attachments.length > 0)
                                                        ?
                                                        (
                                                            <>
                                                            {
                                                                detail.attachments.map((attachment) => (
                                                                    <>
                                                                        <Badge bg="secondary">{attachment.fileName}</Badge>&nbsp;
                                                                    </>
                                                                ))
                                                            }
                                                            </>
                                                        )
                                                        : 
                                                        <>-</>
                                                    }
                                                </td>
                                                <td>{detail.createdAt}</td>
                                                <td>{`${detail.createdBy.firstName} ${detail.createdBy.lastName}`}</td>
                                                {
                                                    isFiscalAdjunto() ? (
                                                        <>
                                                            {
                                                                ["APPROVED", "REJECTED"].includes(detail.status) ? (
                                                                    <td>
                                                                        <Button 
                                                                            bsPrefix={styles.icon} 
                                                                            onClick={(e) => { e.preventDefault(); setShowReplyInfo(true); setReply(detail.reply); }}
                                                                        >
                                                                        Ver respuesta
                                                                        </Button>
                                                                    </td>
                                                                )
                                                                : 
                                                                <td>
                                                                    <Button bsPrefix={styles.icon} onClick={(e) => { approve(e, detail.id); }}>Aprobar</Button>&nbsp;
                                                                    <Button bsPrefix={styles.icon} onClick={(e) => { reject(e, detail.id); }}>Rechazar</Button>
                                                                </td>                                                                                                                        
                                                            }
                                                        </>
                                                    )
                                                    :
                                                    <>
                                                        {
                                                            ["APPROVED", "REJECTED"].includes(detail.status) ? (
                                                                <td>
                                                                    <Button 
                                                                        bsPrefix={styles.icon} 
                                                                        onClick={(e) => { e.preventDefault(); setShowReplyInfo(true); setReply(detail.reply); }}
                                                                    >
                                                                    Ver respuesta
                                                                    </Button>
                                                                </td>
                                                            )
                                                            : 
                                                            <></>
                                                        }
                                                    </>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </>                            
                    )
                    :
                    <span className={styles.noContent}>No hay solicitudes de inhibición</span>
                }
            </Row>
        </>
    );

};

export default Disassociate;