import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Badge, Tab, Nav, Modal, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import { AiFillEdit } from "react-icons/ai";
import styles from "./CompetenceRuleAddModal.module.scss";

const CompetenceRuleAddModal = ( { show, handleClose, callback, competenceCrimes } ) => {

    const [ loadingSave, setLoadingSave ] = useState(false);
    const [ selectedCrime, setSelectedCrime ] = useState(undefined);
    const [ crimes, setCrimes ] = useState([]);

    const [ conditions, setConditions ] = useState([
        {
            active: false,
            type: "PUBLIC_OFFICIAL"
        },
        {
            active: false,
            type: "DAMAGE_TO_STATE_PATRIMONY"
        },
        {
            active: false,
            type: "LOSS_FOR_WAGES_AND_SALARIES",
            value: 5350
        }
    ]);

    const { keycloak } = useKeycloak();        

    const handleSelectCrime = (e, crime) => {
        e.preventDefault();
        if(crime.disabled){ return; }
        let aux = [];
        let selectedAux = undefined;
        for(let current of crimes){
            let auxCrimes = [];
            for(let currentCrime of current.crimes){
                if(currentCrime.id != crime.id){
                    currentCrime.selected = false;
                }
                else {
                    currentCrime.selected = !currentCrime.selected;
                    if(currentCrime.selected){
                        selectedAux = currentCrime;
                    }
                }
                auxCrimes.push(currentCrime);
            }
            aux.push({ law: current.law, crimes: auxCrimes });
        }
        setSelectedCrime(selectedAux);
        setCrimes(aux);
    }

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }     

    const groupBy = (list, keyGetter, identifierKey) => {
        const map = new Map();
        const formattedMap = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key[identifierKey]);
             const formattedCollection = formattedMap.get(JSON.stringify(key));
             if (!collection) {
                 map.set(key[identifierKey], [item]);
                 formattedMap.set(JSON.stringify(key), [item]);
             } 
             else {
                collection.push(item);
                formattedCollection.push(item);
             }
        });        
        return Object.fromEntries(formattedMap);
      };
      

    const mapCrimes = ( rawCrimes, selected ) => {
        const mappedCrimes = groupBy(rawCrimes, rawCrime => rawCrime.law, "id");
        let crimesFormatted = [];
        for(let key in mappedCrimes){
            crimesFormatted.push({
                law: JSON.parse(key),
                crimes: mappedCrimes[key].map((ctx) => { return {...ctx, disabled: checkDisabled(ctx), selected: checkSelected( ctx, selected ) }; } )
            });
        }
        return crimesFormatted;
    }

    const checkDisabled = (crime) => {
        if(competenceCrimes.length == 0){
            return false;
        }
        let disabled = false;
        for(let competenceCrime of competenceCrimes){
            if(competenceCrime.id == crime.id){
                disabled = true;
                break;
            }
        }
        return disabled;
    }

    const checkSelected = (crime, selected ) => {
        if(selected && selected.length > 0){
            for(let current of selected){
                if(current.id === crime.id){
                    return true;
                }
            }
        }
        return false;
    };  
    
    const findCrimes = ( selected ) => {
        axios.get(process.env.REACT_APP_API_URL + "/crimes", prepareHeaders())        
        .then(res => {
            if(res.status == 200){
                setCrimes(mapCrimes(res.data, selected));
            }
        })
        .catch(error => {
            console.error(error);
        });
    };    

    const changeCondition = (e, currentCondition) => {
        e.preventDefault();
        let aux = [];
        for(let condition of conditions){
            if(condition.type == currentCondition.type){
                condition.active = !currentCondition.active;
            }
            aux.push(condition);
        }
        setConditions(aux);
    }

    const internCallback = () => {
        setSelectedCrime(undefined);
        setConditions([
            {
                active: false,
                type: "PUBLIC_OFFICIAL"
            },
            {
                active: false,
                type: "DAMAGE_TO_STATE_PATRIMONY"
            },
            {
                active: false,
                type: "LOSS_FOR_WAGES_AND_SALARIES",
                value: 5350
            }            
        ]);
        callback();
    }

    const buildBody = () => {
        return {
            id: selectedCrime.id,
            crime: selectedCrime,
            conditions: conditions.filter(condition => condition.active)
        };
    }

    const saveCompetenceCrime = (e) => {
        e.preventDefault();
        setLoadingSave(true);
        axios.post(`${process.env.REACT_APP_API_URL}/analyzer/competence/crimes`, buildBody(), prepareHeaders()).
        then(res => {
            setLoadingSave(false);
            internCallback(); 
            toast.success("Regla de competencia registrada exitosamente");
        }).
        catch(err => {
            setLoadingSave(false);
            internCallback();
            toast.error("Ha ocurrido un error al registrar la regla de competencia");
        });  
    }

    useEffect(() => { findCrimes(); }, [competenceCrimes]);

    const resolveCondition = (condition) => {
        let data = <></>
        switch(condition.type){
            case "PUBLIC_OFFICIAL":
                data = (
                    <>
                        <Badge onClick={(e) => { changeCondition(e, condition); }} bg="secondary" className={condition.active ? styles.badgeSelected : styles.badgeDefault} style={{ cursor: "pointer" }}>
                            <span>Cometido por funcionario público en ejercicio de sus funciones</span>&nbsp;
                        </Badge>
                        &nbsp;
                    </>
                );
                break;
            case "DAMAGE_TO_STATE_PATRIMONY":
                data = (
                    <>
                        <Badge onClick={(e) => { changeCondition(e, condition); }} bg="secondary" className={condition.active ? styles.badgeSelected : styles.badgeDefault} style={{ cursor: "pointer" }}>
                            <span>Perjuicio al patrimonio del estado</span>&nbsp;
                        </Badge>
                        &nbsp;
                    </>
                );
                break;
            case "LOSS_FOR_WAGES_AND_SALARIES":                
                data = (
                    <>
                        <Badge bg="secondary" className={condition.active ? styles.badgeSelected : styles.badgeDefault}>
                            <span style={{ cursor: "pointer" }} onClick={(e) => { changeCondition(e, condition); }}>Perjuicio igual o superior a <strong>{ condition.value }</strong> jornales</span>&nbsp;
                            <AiFillEdit style={{ cursor: "pointer" }} title="Editar cantidad de jornales" />
                        </Badge>
                        &nbsp;
                    </>
                );
                break;
            default:
                break;
        }
        return data;        
    }
    
    return (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva regla de competencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mt-2 mb-4">
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Hechos punibles</Form.Label>
                                <Form.Group as={Col} controlId="hechosPunibles">
                                    {
                                        crimes.length > 0 ? (
                                            <Tab.Container defaultActiveKey={crimes[0].law.id}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <Nav variant="pills" className={`flex-column ${styles.pillsGroup}`}>
                                                            {
                                                                crimes.map(crime => (
                                                                    <Nav.Item className={styles.pill}>
                                                                        <Nav.Link className={styles.pillLink} eventKey={crime.law.id}>
                                                                            <span className={styles.lawNumberSpan}>{`Ley Nº ${crime.law.number}`}</span><br></br>
                                                                            <span className={styles.lawNameSpan}>{crime.law.name}</span>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))
                                                            }
                                                        </Nav>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <Tab.Content>
                                                            {                                          
                                                                crimes.map(crime => (
                                                                    <Tab.Pane eventKey={crime.law.id}>
                                                                        {
                                                                            crime.crimes.map((punishableFact) => (
                                                                                <Badge 
                                                                                    className={punishableFact.selected ? styles.badgeSelected : ( punishableFact.disabled ? styles.badgeDisabled : styles.badgeDefault) }
                                                                                    style={{ marginRight: ".5rem", cursor: punishableFact.disabled ? "not-allowed" : "pointer" }}
                                                                                    onClick={(e) => { handleSelectCrime(e, punishableFact); }}
                                                                                >
                                                                                {`Art. ${punishableFact.article} - ${punishableFact.name}`}
                                                                                </Badge>
                                                                            ))
                                                                        }
                                                                    </Tab.Pane>
                                                                ))
                                                            }
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>  
                                            </Tab.Container>
                                        )
                                        :
                                        <></>
                                    }
                                </Form.Group>
                            </Row>
                            {
                                selectedCrime ? (
                                    <Row className="mb-4">
                                        <Form.Label className={styles.groupLabel}>Condiciones</Form.Label>
                                        <Form.Group as={Col} controlId="hechosPunibles">
                                            { conditions.map(condition => ( resolveCondition(condition) )) }
                                        </Form.Group>
                                    </Row>                            
                                )
                                :
                                <></>
                            }
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" disabled={loadingSave || !selectedCrime} onClick={saveCompetenceCrime}>
                        {
                            loadingSave ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default CompetenceRuleAddModal;