import React, { useState, useEffect } from "react";
import { Form, Row, Col, Badge, Table, Modal, Button } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import styles from "./Diff.module.css";
import { GrFormView } from "react-icons/gr";
import { HiDownload } from "react-icons/hi";
import ComplainantModal from "../../../../../components/modals/ComplainantModal";
import DefendantModal from "../../../../../components/modals/DefendantModal";
import WitnessModal from "../../../../../components/modals/WitnessModal";
import GenericDetail from "../../Information/GenericDetail/GenericDetail";
import DocumentType from "../../../../../constants/DocumentType";

const Diff = ( { cause, show, handleClose } ) => {

    const { keycloak } = useKeycloak();

    const [ showComplainant, setShowComplainant ] = useState(false);
    const [ currentComplainant, setCurrentComplainant ] = useState(undefined);    

    const [ showDefendant, setShowDefendant ] = useState(false);
    const [ currentDefendant, setCurrentDefendant ] = useState(undefined);

    const [ showWitness, setShowWitness ] = useState(false);
    const [ currentWitness, setCurrentWitness ] = useState(undefined);

    const prepareHeaders = () => {
        return {
            headers: {
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }

    const downloadFile = (e, fileName) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/content/${fileName}`, prepareHeaders())
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            });
        })
        .catch(error => {
            console.error(error);
        });
    }

    const formatAmount = (value) => {
        const formatted = parseInt(value).toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
            minimumFractionDigits: 0,
        });
        return formatted;
    };          

    return cause ? (
        <>
            <ComplainantModal data={currentComplainant} show={showComplainant} handleClose={() => setShowComplainant(false)}/>
            <DefendantModal data={currentDefendant} show={showDefendant} handleClose={() => setShowDefendant(false)}/>
            <WitnessModal data={currentWitness} show={showWitness} handleClose={() => setShowWitness(false)}/>        
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Datos de la denuncia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Row className={styles.group}>
                            <Form.Label className={styles.groupLabel} as={Col}>Datos generales</Form.Label>
                            <Row className="mb-2">
                                <Col md="12">
                                    <Form.Label bsPrefix={styles.label} as={Col}>Carátula</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.cover} disabled/>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md="auto">
                                    <Form.Label bsPrefix={styles.label} as={Col}>Tipo de denuncia</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.type} disabled />
                                </Col>
                                <Col md="auto">
                                    <Form.Label bsPrefix={styles.label} as={Col}>Reservada</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.reserved ? "Sí" : "No"} disabled />
                                </Col>
                                <Col md="auto">
                                    <Form.Label bsPrefix={styles.label} as={Col}>Nro. SIGEDE</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.causeReference ? cause.complaint.causeReference : "-"} disabled />
                                </Col>                        
                                <Col md="auto">
                                    <Form.Label bsPrefix={styles.label} as={Col}>Fecha y hora de recepción</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.createdAt} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="auto">
                                    <Form.Label bsPrefix={styles.label} as={Col}>Recibida por</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={`${cause.complaint.createdBy.firstName} ${cause.complaint.createdBy.lastName}`} disabled/>
                                </Col>                                
                            </Row>
                            {
                                (cause.complaint.type == "RESOLUTION" && cause.complaint.resolution) ? (
                                    <Row className="mt-3">
                                        <Col md="auto">
                                            <Form.Label bsPrefix={styles.label} as={Col}>Descripción de la resolución</Form.Label>
                                            <Form.Control bsPrefix={styles.input} value={cause.complaint.resolution.description ? cause.complaint.resolution.description : "-"} disabled />
                                        </Col>
                                        <Col md="auto">
                                            <Form.Label bsPrefix={styles.label} as={Col}>Número de resolución</Form.Label>
                                            <Form.Control bsPrefix={styles.input} value={cause.complaint.resolution.number ? cause.complaint.resolution.number : "-"} disabled />
                                        </Col>
                                    </Row>                             
                                )
                                :
                                <></>
                            }
                        </Row>
                        {
                            cause.principalProsecutor ? (
                                <Row style={{ marginBottom: "1rem"}}>
                                    <Form.Label className={styles.groupLabel} as={Col}>Unidad fiscal</Form.Label>
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Nombres y apellidos</th>
                                                    <th>Rol</th>
                                                    <th>Unidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{`${cause.principalProsecutor.firstName} ${cause.principalProsecutor.lastName}`}</td>
                                                    <td>Fiscal principal</td>
                                                    <td>{cause.principalProsecutor.division}</td>
                                                </tr>
                                                {
                                                    (cause.prosecutors??[]).map((prosecutor) => (
                                                        <tr>
                                                            <td>{`${prosecutor.firstName} ${prosecutor.lastName}`}</td>
                                                            <td>Fiscal coadyuvante</td>
                                                            <td>{prosecutor.division}</td>                                                            
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    cause.principalAssistant ? (
                                                        <tr>
                                                            <td>{`${cause.principalAssistant.firstName} ${cause.principalAssistant.lastName}`}</td>
                                                            <td>Asistente principal</td>
                                                            <td>{cause.principalAssistant.division}</td>
                                                        </tr>
                                                    )   
                                                    :
                                                    <></>
                                                }
                                                {
                                                    (cause.assistants??[]).map((assistant) => (
                                                        <tr>
                                                            <td>{`${assistant.firstName} ${assistant.lastName}`}</td>
                                                            <td>Asistente</td>
                                                            <td>{assistant.division}</td>                                                            
                                                        </tr>
                                                    ))                                                    
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            )
                            : 
                            <></>
                        }
                        {
                            (cause.rejectionDetails && cause.rejectionDetails.length > 0) 
                            ?  
                            <>
                                <Row style={{ marginBottom: "1rem"}}>
                                    <Form.Label className={styles.groupLabel} as={Col}>Detalles de la desestimación</Form.Label>
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Motivo</th>
                                                    <th>Archivos adjuntos</th>
                                                    <th>Creado el</th>
                                                    <th>Creado por</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.rejectionDetails.map((rejectionDetail) => (
                                                        <tr>
                                                            <td>{rejectionDetail.observation}</td>
                                                            <td>
                                                                {
                                                                    (rejectionDetail.attachments && rejectionDetail.attachments.length > 0)
                                                                    ?
                                                                    (
                                                                        <>
                                                                        {
                                                                            rejectionDetail.attachments.map((attachment) => (
                                                                                <>
                                                                                    <Badge bg="secondary" style={{ cursor: "pointer" }} onClick={(e) => { downloadFile(e, attachment.id + "-" + attachment.fileName); }}>{attachment.fileName}</Badge>&nbsp;
                                                                                </>
                                                                            ))
                                                                        }
                                                                        </>
                                                                    )
                                                                    : 
                                                                    <>-</>
                                                                }
                                                            </td>
                                                            <td>{rejectionDetail.createdAt}</td> 
                                                            <td>{`${rejectionDetail.createdBy.firstName} ${rejectionDetail.createdBy.lastName}`}</td> 
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>                                                    
                                </Row>
                            </>
                            :
                            <></>
                        }
                        {
                            (cause.archivingDetails && cause.archivingDetails.length > 0) 
                            ?  
                            <>
                                <Row style={{ marginBottom: "1rem"}}>
                                    <Form.Label className={styles.groupLabel} as={Col}>Detalles del archivo de la causa</Form.Label>
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Motivo</th>
                                                    <th>Archivos adjuntos</th>
                                                    <th>Creado el</th>
                                                    <th>Creado por</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.archivingDetails.map((archivingDetail) => (
                                                        <tr>
                                                            <td>{archivingDetail.observation}</td>
                                                            <td>
                                                                {
                                                                    (archivingDetail.attachments && archivingDetail.attachments.length > 0)
                                                                    ?
                                                                    (
                                                                        <>
                                                                        {
                                                                            archivingDetail.attachments.map((attachment) => (
                                                                                <>
                                                                                    <Badge bg="secondary" style={{ cursor: "pointer" }} onClick={(e) => { downloadFile(e, attachment.id + "-" + attachment.fileName); }}>{attachment.fileName}</Badge>&nbsp;
                                                                                </>
                                                                            ))
                                                                        }
                                                                        </>
                                                                    )
                                                                    : 
                                                                    <>-</>
                                                                }
                                                            </td>
                                                            <td>{archivingDetail.createdAt}</td> 
                                                            <td>{`${archivingDetail.createdBy.firstName} ${archivingDetail.createdBy.lastName}`}</td> 
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>                                                    
                                </Row>
                            </>
                            :
                            <></>
                        }

                        <GenericDetail detail={cause.conciliationDetail} title={"Detalles de la conciliación"} />
                        <GenericDetail detail={cause.opportunityCriteriaDetail} title={"Detalles del criterio de oportunidad"} />
                        <GenericDetail detail={cause.conditionalSuspensionDetail} title={"Detalles de la suspensión condicional"} />
                        <GenericDetail detail={cause.abbreviateProcessDetail} title={"Detalles del proceso abreviado"} />
                        <GenericDetail detail={cause.abbreviateProcessDetail} title={"Detalles del proceso abreviado"} />

                        {
                            (cause.reopenDetails && cause.reopenDetails.length > 0) 
                            ?  
                            <>
                                <Row style={{ marginBottom: "1rem"}}>
                                    <Form.Label className={styles.groupLabel} as={Col}>Detalles de la reapertura</Form.Label>
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Motivo</th>
                                                    <th>Archivos adjuntos</th>
                                                    <th>Creado el</th>
                                                    <th>Creado por</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.reopenDetails.map((detail) => (
                                                        <tr>
                                                            <td>{detail.observation}</td>
                                                            <td>
                                                                {
                                                                    (detail.attachments && detail.attachments.length > 0)
                                                                    ?
                                                                    (
                                                                        <>
                                                                        {
                                                                            detail.attachments.map((attachment) => (
                                                                                <>
                                                                                    <Badge bg="secondary" style={{ cursor: "pointer" }} onClick={(e) => { downloadFile(e, attachment.id + "-" + attachment.fileName); }}>{attachment.fileName}</Badge>&nbsp;
                                                                                </>
                                                                            ))
                                                                        }
                                                                        </>
                                                                    )
                                                                    : 
                                                                    <>-</>
                                                                }
                                                            </td>
                                                            <td>{detail.createdAt}</td> 
                                                            <td>{`${detail.createdBy.firstName} ${detail.createdBy.lastName}`}</td> 
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>                                                    
                                </Row>
                            </>
                            :
                            <></>
                        }                       
                        {
                            cause.imputationDetail ? (
                                <Row style={{ marginBottom: "1rem"}}>
                                    <Form.Label className={styles.groupLabel} as={Col}>Detalles de la imputación</Form.Label>
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Motivo</th>
                                                    <th>Ref. JUDISOFT</th>
                                                    <th>Archivos adjuntos</th>
                                                    <th>Creado el</th>
                                                    <th>Creado por</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{cause.imputationDetail.observation}</td>
                                                    <td>{cause.imputationDetail.reference}</td>                                            
                                                    <td>
                                                        {
                                                            (cause.imputationDetail.attachments && cause.imputationDetail.attachments.length > 0)
                                                            ?
                                                            (
                                                                <>
                                                                {
                                                                    cause.imputationDetail.attachments.map((attachment) => (
                                                                        <>
                                                                            <Badge bg="secondary" style={{ cursor: "pointer" }} onClick={(e) => { downloadFile(e, attachment.id + "-" + attachment.fileName); }}>{attachment.fileName}</Badge>&nbsp;
                                                                        </>
                                                                    ))
                                                                }
                                                                </>
                                                            )
                                                            : 
                                                            <>-</>
                                                        }
                                                    </td>
                                                    <td>{cause.imputationDetail.createdAt}</td> 
                                                    <td>{`${cause.imputationDetail.createdBy.firstName} ${cause.imputationDetail.createdBy.lastName}`}</td> 
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>                                                    
                                </Row>
                            )
                            :
                            <></>
                        }

                        <Row style={{ marginBottom: "1rem"}}>
                            <Form.Label className={styles.groupLabelAction} as={Col}>Denunciantes</Form.Label>
                            {
                                (cause.complaint.complainants && cause.complaint.complainants.filter(complaint => complaint.type == undefined || complaint.type == "PF").length > 0) ? (
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Nombres y apellidos</th>
                                                    <th>Tipo de documento</th>
                                                    <th>Número de documento</th>
                                                    <th>Fecha de nacimiento</th>
                                                    <th>Departamento</th>
                                                    <th>Ciudad</th>
                                                    <th>Barrio</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.complaint.complainants.filter(complaint => complaint.type == undefined || complaint.type == "PF").map((complainant) => (
                                                        <tr>
                                                            <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                            <td>{complainant.document.type ? DocumentType[complainant.document.type].description : "-"}</td>
                                                            <td>{complainant.document.number}</td>
                                                            <td>{complainant.birthDate}</td>
                                                            <td>{complainant.address.department ? complainant.address.department.name : "-"}</td>
                                                            <td>{complainant.address.city ? complainant.address.city.name : "-"}</td>
                                                            <td>{complainant.address.neighborhood ? complainant.address.neighborhood.name : "-"}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <GrFormView title="Ver" onClick={(e) => { e.preventDefault(); setCurrentComplainant(complainant); setShowComplainant(true); }} className={styles.iconBtn} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>                        
                                )
                                : <></>
                            }
                            {
                                (cause.complaint.complainants && cause.complaint.complainants.filter(complaint => complaint.type != undefined && complaint.type == "IE").length > 0) ? (
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Institución del estado</th>
                                                    <th>Nombres y apellidos</th>
                                                    <th>Tipo de documento</th>
                                                    <th>Número de documento</th>
                                                    <th>Cargo</th> 
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.complaint.complainants.filter(complaint => complaint.type == "IE").map((complainant) => (
                                                        <tr>
                                                            <td>{complainant.stateInstitution.name}</td>
                                                            <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                            <td>{complainant.document.type ? DocumentType[complainant.document.type].description : "-"}</td>
                                                            <td>{complainant.document.number}</td>
                                                            <td>{complainant.title}</td>                              
                                                            <td style={{ textAlign: "center" }}>
                                                                <GrFormView title="Ver" onClick={(e) => { e.preventDefault(); setCurrentComplainant(complainant); setShowComplainant(true); }} className={styles.iconBtn} />
                                                            </td>                                                    
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>                        
                                )
                                : <></>
                            }
                            {
                                (cause.complaint.complainants && cause.complaint.complainants.filter(complaint => complaint.type != undefined && complaint.type == "IP").length > 0) ? (
                                    <Col md={12}>
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Institución privada</th>
                                                    <th>Nombres y apellidos</th>
                                                    <th>Tipo de documento</th>
                                                    <th>Número de documento</th>
                                                    <th>Fecha de nacimiento</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.complaint.complainants.filter(complaint => complaint.type == "IP").map((complainant) => (
                                                        <tr>
                                                            <td>{complainant.privateInstitution}</td>
                                                            <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                                                            <td>{complainant.document.type ? DocumentType[complainant.document.type].description : "-"}</td>
                                                            <td>{complainant.document.number}</td>
                                                            <td>{complainant.birthDate}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <GrFormView title="Ver" onClick={(e) => { e.preventDefault(); setCurrentComplainant(complainant); setShowComplainant(true); }} className={styles.iconBtn} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>                        
                                )
                                : <></>
                            }                
                        </Row>            
                        <Row style={{ marginBottom: "1rem" }} >
                            <Form.Label className={styles.groupLabelAction} as={Col}>Denunciados</Form.Label>
                            <Col md={12}>
                                <Table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th>Nombres</th>
                                            <th>Apellidos</th>
                                            <th>Fecha de nacimiento</th>
                                            <th>Tipo de documento</th>
                                            <th>Número de documento</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cause.complaint.defendants.map((defendant) => (
                                                <tr>
                                                    <td>{defendant.firstName}</td>
                                                    <td>{defendant.lastName}</td>
                                                    <td>{defendant.birthDate}</td>
                                                    <td>{defendant.document.type}</td>
                                                    <td>{defendant.document.number}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <GrFormView title="Ver" onClick={(e) => { e.preventDefault(); setCurrentDefendant(defendant); setShowDefendant(true); }} className={styles.iconBtn}  />
                                                    </td>                                            
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row className={styles.group}>
                            <Form.Label className={styles.groupLabel} as={Col}>Lugar del hecho</Form.Label>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Departamento</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.department ? cause.complaint.statementOfFact.address.department.name : "-"} disabled />
                                </Col>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Ciudad</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.city ? cause.complaint.statementOfFact.address.city.name : "-"} disabled />
                                </Col>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Barrio</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.neighborhood ? cause.complaint.statementOfFact.address.neighborhood.name : "-"} disabled />
                                </Col>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Calle principal</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.street.principal} disabled/>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Calle secundaria</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.street.secondary} disabled/>
                                </Col>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Número de residencia</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.houseNumber} disabled/>
                                </Col>                    
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Descripción</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.address.description} disabled/>
                                </Col>                                        
                            </Row>
                        </Row>   
                        <Row className={styles.group}>
                            <Form.Label className={styles.groupLabel} as={Col}>Datos del hecho</Form.Label>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Posible fecha del hecho</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.possibleDate} disabled />
                                </Col>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Relato del hecho</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.statementOfFact.detailedAccountOfThePunishableAct} disabled />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Hechos punibles</Form.Label>
                                    {
                                        cause.complaint.crimes.map((crime) => (
                                            <Badge bg="secondary" style={{ marginRight: ".5rem"}}>{`Art. ${crime.article}, ${crime.name}`}</Badge>
                                        ))
                                    }
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Daño estimado</Form.Label>
                                    <Form.Control bsPrefix={styles.input} value={cause.complaint.estimatedAmountOfDamage.value ? formatAmount(cause.complaint.estimatedAmountOfDamage.value) : "-"} disabled />
                                </Col>
                                <Col>
                                    <Form.Label bsPrefix={styles.label} as={Col}>Instituciones afectadas</Form.Label>
                                    {
                                        cause.complaint.relatedInstitutions.map((institution) => (
                                            <Badge bg="secondary" style={{ marginRight: ".5rem"}}>{institution}</Badge>
                                        ))
                                    }
                                </Col>                                        
                            </Row>                   
                        </Row>
                        <Row style={{ marginBottom: "2rem" }}>
                            <Form.Label className={styles.groupLabelAction} as={Col}>Testigos</Form.Label>
                            <Col md={12}>
                                {
                                    (cause.complaint.witnesses && cause.complaint.witnesses.length > 0) ?
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Nombres</th>
                                                    <th>Apellidos</th>
                                                    <th>Fecha de nacimiento</th>
                                                    <th>Tipo de documento</th>
                                                    <th>Número de documento</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.complaint.witnesses.map((witness) => (
                                                        <tr>
                                                            <td>{witness.firstName}</td>
                                                            <td>{witness.lastName}</td>
                                                            <td>{witness.birthDate}</td>
                                                            <td>{witness.document.type}</td>
                                                            <td>{witness.document.number}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <GrFormView title="Ver" onClick={(e) => { e.preventDefault(); setCurrentWitness(witness); setShowWitness(true); }} className={styles.iconBtn} />
                                                            </td>                                                                                                
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    :
                                    <span className={styles.label}>No hay registros</span>
                                }
                            </Col>
                        </Row>                    
                        <Row style={{ marginBottom: "2rem" }}>
                            <Form.Label className={styles.groupLabelAction} as={Col}>Informes periciales</Form.Label>
                            <Col md={12}>
                                {
                                    (cause.complaint.expertsReports && cause.complaint.expertsReports.length > 0) ?
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Archivo</th>
                                                    <th style={{ textAlign: "center" }}>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.complaint.expertsReports.map((expertReport) => (
                                                        <tr>
                                                            <td>{expertReport.fileName}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <HiDownload  
                                                                    onClick={(e) => { downloadFile(e, expertReport.id + "-" + expertReport.fileName); }}
                                                                    className={styles.iconBtn2} 
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    :
                                    <span className={styles.label}>No hay registros</span>
                                }

                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "2rem" }} >
                            <Form.Label className={styles.groupLabelAction} as={Col}>Documentos adjuntos</Form.Label>
                            <Col md={12}>
                                {
                                    (cause.complaint.attachments && cause.complaint.attachments.length > 0) ? 
                                        <Table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Nombre</th>
                                                    <th>Descripción</th>
                                                    <th>Ubicación</th>
                                                    <th>Archivos</th>
                                                    <th style={{ textAlign: "center" }}>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cause.complaint.attachments.map((attachment) => (
                                                        <tr>
                                                            <td>{attachment.type}</td>
                                                            <td>{attachment.name}</td>
                                                            <td>{attachment.description}</td>
                                                            <td>{attachment.location}</td>
                                                            <td>{attachment.file ? attachment.file.fileName : "-"}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <HiDownload 
                                                                    disabled={attachment.file ? true : false} 
                                                                    onClick={(e) => { downloadFile(e, attachment.file.id + "-" + attachment.file.fileName); }}
                                                                    className={styles.iconBtn2} 
                                                                />
                                                            </td>                                                    
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    :
                                    <span className={styles.label}>No hay registros</span>
                                }
                            </Col>
                        </Row>              
                    </Form>      
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>  
        </>
    )
    :
    <></>

}

export default Diff;