import React, { useState, useEffect } from "react";
import styles from "./AssignCoadyuvant.module.css";
import { Modal, Button, Form } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from "axios";

const AssignCoadyuvant = ( { show, handleClose, cause, callback } ) => {

    const [ prosecutors, setProsecutors ] = useState([]);
    const [ prosecutor, setProsecutor ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }         

    const filter = ( data ) => {
        let prosecutors = [];  
        if(cause.principalProsecutor){ prosecutors.push(cause.principalProsecutor); }      
        prosecutors = prosecutors.concat(cause.prosecutors);
        prosecutors = prosecutors.map(prosecutor => prosecutor.id);
        if(prosecutors && prosecutors.length > 0){            
            return data.filter(current => !prosecutors.includes(current.id));
        }
        return data;
    }    

    const findProsecutors = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/prosecutors`, prepareHeaders())        
        .then(res => { setProsecutors(res.status != 200 ? [] : filter(res.data)); })
        .catch(err => { console.log(err); });
    }

    const handleChange = (event, value, reason, details) => {
        event.preventDefault();
        setProsecutor(value);
    }
    
    const handleAssign = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/causes/${cause.id}/prosecutors/coadyuvants/${prosecutor.id}/assign`, null, prepareHeaders())
        .then(res => {
            setLoading(false);
            setProsecutor(undefined);
            callback();
            handleClose();
        })
        .catch(err => {
            setLoading(false);
        });
    }

    useEffect(() => findProsecutors(), []);

    useEffect(() => findProsecutors(), [cause]);    

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Asignar fiscal coadyuvante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Autocomplete 
                            options={prosecutors}
                            getOptionLabel={(option) => typeof option === "object" ? (option.firstName + " " + option.lastName) : (JSON.parse(option).firstName + " " + JSON.parse(option).lastName) }
                            value={JSON.stringify(prosecutor)}
                            onChange={handleChange}
                            autoSelect
                            renderInput={(params) => ( <TextField {...params} label="Fiscal" /> )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                <Button variant="primary" disabled={prosecutor == undefined} onClick={handleAssign}>Asignar</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default AssignCoadyuvant;