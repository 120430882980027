export const RoleEnum = {
    "fiscal-adjunto": {
        name: "Fiscal Adjunto"
    },
    "mesa-de-entrada": {
        name: "Mesa de entrada"
    },
    "fiscal": {
        name: "Fiscal"
    },
    "ddea": {
        name: "Dirección de delitos económicos"
    },
    "external-institution": {
        name: "Institución externa"
    },
    "admin": {
        name: "Administrador"
    },
    "asistente-fiscal": {
        name: "Asistente fiscal"
    }
};