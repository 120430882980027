import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Badge, Tab, Nav, Modal, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import styles from "./StatementOfFactEdit.module.scss";

const StatementOfFactEdit = ( { show, handleClose, callback, data } ) => {

    const MAX_LONG = 9007199254740991;

    const [ loading, setLoading ] = useState(false);
    const [ preCarga, setPreCarga ] = useState(true);
    const [ possibleDate, setPossibleDate ] = useState(undefined);
    const [ selectedCrimes, setSelectedCrimes ] = useState([]);
    const [ amount, setAmount ] = useState(undefined);
    const [ injuredInstitutions, setInjuredInstitutions ] = useState([]);
    const [ fact, setFact ] = useState(undefined);

    const [ crimes, setCrimes ] = useState([]);

    const patrimonialDamageRule = [{ lawNumber: "1160/97", article: 187 }, { lawNumber: "1160/97", article: 192 }];

    const [ institutions, setInstitutions ] = useState
    ([
        {
            code: "SALUD",
            label: "Salud",
            selected: false
        }, 
        {
            code: "EDUCACION",
            label: "Educación",
            selected: false
        },
        {
            code: "SERVICIOS_PUBLICOS",
            label: "Servicios públicos",
            selected: false
        }
    ]);

    const { keycloak } = useKeycloak();        

    const [formattedAmount, setFormattedAmount] = useState('');

  
    const formatAmount = (value) => {
        const cleanValue = value.replace(/[^\d]/g, '');
        const formatted = parseInt(cleanValue).toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
            minimumFractionDigits: 0,
        });
        return formatted;
    };
  
    const handleChange = (e) => {
        e.preventDefault();
        const inputValue = e.target.value;
        const numeric = parseInt(inputValue.replace(/[^\d]/g, ''));
        if(isNaN(numeric)){
            setFormattedAmount('');
        }
        else if(numeric <= MAX_LONG){
            const formatted = formatAmount(inputValue);
            setFormattedAmount(formatted);
            setAmount(isNaN(numeric) ? undefined : inputValue.replace(/[^\d]/g, ''));
        }
        else {
            alert("El máximo valor para el monto es " + MAX_LONG);
        }
    };      

    const handleChangeIntern = (inputValue) => {
        const numeric = parseInt(inputValue.replace(/[^\d]/g, ''));
        if(isNaN(numeric)){
            return '';
        }
        else if(numeric <= MAX_LONG){
            return formatAmount(inputValue);
        }
        else {
            return '';
        }
    };          

    const handleSelectCrime = (e, crime) => {
        e.preventDefault();
        let aux = [];
        let selectedAux = [];
        for(let current of crimes){
            let auxCrimes = [];
            for(let currentCrime of current.crimes){
                if(currentCrime.id === crime.id){
                    currentCrime.selected = !currentCrime.selected;
                }
                auxCrimes.push(currentCrime);
            }
            if(auxCrimes.filter(auxCrime => auxCrime.selected).length > 0){
                selectedAux.push(...auxCrimes.filter(auxCrime => auxCrime.selected));
            }
            aux.push({ law: current.law, crimes: auxCrimes });
        }
        setSelectedCrimes(selectedAux);
        setCrimes(aux);
    }

    const handleSelectInstitution = (e, institution) => {
        e.preventDefault();
        let aux = [];
        for(let current of institutions){
            if(current.code === institution.code){
                current.selected = !current.selected;
            }
            aux.push(current);
        }
        setInjuredInstitutions(aux.filter(current => current.selected).map((current => current.code)));
        setInstitutions(aux);
    }

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }     
    
    const checkPatrimonialDamageRule = ()  => {
        for(let selected of selectedCrimes){
            for(let rule of patrimonialDamageRule){
                if(rule.lawNumber == selected.law.number && rule.article == selected.article){
                    return true;
                }
            }
        }
        return false;
    }

    const groupBy = (list, keyGetter, identifierKey) => {
        const map = new Map();
        const formattedMap = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key[identifierKey]);
             const formattedCollection = formattedMap.get(JSON.stringify(key));
             if (!collection) {
                 map.set(key[identifierKey], [item]);
                 formattedMap.set(JSON.stringify(key), [item]);
             } 
             else {
                collection.push(item);
                formattedCollection.push(item);
             }
        });        
        return Object.fromEntries(formattedMap);
      };
      

    const mapCrimes = ( rawCrimes, selected ) => {
        const mappedCrimes = groupBy(rawCrimes, rawCrime => rawCrime.law, "id");
        let crimesFormatted = [];
        for(let key in mappedCrimes){
            crimesFormatted.push({
                law: JSON.parse(key),
                crimes: mappedCrimes[key].map((ctx) => { return {...ctx, selected: checkSelected( ctx, selected ) }; } )
            });
        }
        return crimesFormatted;
    }

    const checkSelected = ( crime, selected ) => {

        if(selected && selected.length > 0){
            for(let current of selected){
                if(current.id === crime.id){
                    return true;
                }
            }
        }
        return false;
    };

    const refreshInstitutions = ( updated ) => {
        let aux = [];
        for(let current of institutions){
            for(let currentUpdated of updated){
                if(current.code == currentUpdated){
                    current.selected = true;
                    break;
                }
            }
            aux.push(current);
        }
        setInstitutions(aux);
    }    
    
    const findCrimes = ( selected ) => {
        axios.get(process.env.REACT_APP_API_URL + "/crimes", prepareHeaders())        
        .then(res => {
            if(res.status == 200){
                setCrimes(mapCrimes(res.data, selected));
            }
        })
        .catch(error => {
            console.error(error);
        });
    };    

    const buildBody = () => {
        let elements = [];
        elements.push({
            action: "REPLACE",
            field: "CRIMES",
            value: selectedCrimes
        });
        elements.push({
            action: "REPLACE",
            field: "RELATED_INSTITUTION",
            value: injuredInstitutions
        });        
        if(possibleDate != data.complaint.statementOfFact.possibleDate){
            elements.push({
                action: "REPLACE",
                field: "POSSIBLE_DATE",
                value: possibleDate
            });
        }
        if(fact != data.complaint.statementOfFact.detailedAccountOfThePunishableAct){
            elements.push({
                action: "REPLACE",
                field: "FACT",
                value: fact
            });
        }      
        elements.push({
            action: "REPLACE",
            field: "ESTIMATED_AMOUNT_OF_DAMAGE",
            value: {
                value: amount,
                decimals: 0,
                currency: "PYG"
            }
        });
        return { data: elements };
    }    

    const patch = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/${data.id}/complaint`, buildBody(), prepareHeaders())
        .then(res => {
            toast.success("Datos actualizados", { position: "top-center" });
            setLoading(false);
            callback();
        })
        .catch(err => {
            toast.error("Ha ocurrido un error al actualizar los datos " + err.data ? (": " + err.data.message) : "", { position: "top-center"});
            setLoading(false);
        });
    }    

    useEffect(() => {
        if(data){
            setPossibleDate(data.complaint.statementOfFact.possibleDate);
            setSelectedCrimes(data.complaint.crimes);
            setAmount(data.complaint.estimatedAmountOfDamage.value);
            setInjuredInstitutions(data.complaint.relatedInstitutions);
            setFact(data.complaint.statementOfFact.detailedAccountOfThePunishableAct);
            refreshInstitutions(data.complaint.relatedInstitutions);
            findCrimes(data.complaint.crimes);
            setFormattedAmount(handleChangeIntern(new String(data.complaint.estimatedAmountOfDamage.value)));
        }
        else {
            findCrimes([]);
        }
    }, 
    [data]);
    
    return data ? (
        <>
            <Toaster />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Datos del hecho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mt-2 mb-4">                
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Posible fecha del hecho</Form.Label>
                                <Form.Group as={Col} md={3} controlId="possibleDateValidation">
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingPossibleDate"
                                            type="date" 
                                            required={!preCarga}
                                            value={possibleDate}
                                            onChange={e => setPossibleDate(e.target.value)}
                                            className={styles.input}
                                        />
                                        <Form.Label htmlFor="floatingPossibleDate" className={styles.label}>Fecha</Form.Label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Hechos punibles</Form.Label>
                                <Form.Group as={Col} controlId="hechosPunibles">
                                    {
                                        crimes.length > 0 ? (
                                            <Tab.Container defaultActiveKey={crimes[0].law.id}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <Nav variant="pills" className={`flex-column ${styles.pillsGroup}`}>
                                                            {
                                                                crimes.map(crime => (
                                                                    <Nav.Item className={styles.pill}>
                                                                        <Nav.Link className={styles.pillLink} eventKey={crime.law.id}>
                                                                            <span className={styles.lawNumberSpan}>{`Ley Nº ${crime.law.number}`}</span><br></br>
                                                                            <span className={styles.lawNameSpan}>{crime.law.name}</span>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))
                                                            }
                                                        </Nav>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <Tab.Content>
                                                            {                                          
                                                                crimes.map(crime => (
                                                                    <Tab.Pane eventKey={crime.law.id}>
                                                                        {
                                                                            crime.crimes.map((punishableFact) => (
                                                                                <Badge 
                                                                                    className={punishableFact.selected ? styles.badgeSelected : styles.badgeDefault}
                                                                                    style={{ marginRight: ".5rem", cursor: "pointer" }}
                                                                                    onClick={(e) => { handleSelectCrime(e, punishableFact); }}
                                                                                >
                                                                                {`Art. ${punishableFact.article} - ${punishableFact.name}`}
                                                                                </Badge>
                                                                            ))
                                                                        }
                                                                    </Tab.Pane>
                                                                ))
                                                            }
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>  
                                            </Tab.Container>
                                        )
                                        :
                                        <></>
                                    }
                                </Form.Group>
                            </Row>
                            {
                                checkPatrimonialDamageRule() ? (
                                    <Row className="mb-4">
                                        <Form.Label className={styles.groupLabel}>Daño estimado</Form.Label>
                                        <Form.Group as={Col} md={4} controlId="amountDamageValidation">
                                            <Form.Floating>
                                                <Form.Control 
                                                    id="floatingAmount"
                                                    type="text" 
                                                    placeholder="Ingrese el monto estimado del daño"
                                                    className={styles.input}
                                                    value={formattedAmount}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label htmlFor="floatingAmount" className={styles.label}>Monto</Form.Label>
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md="auto" controlId="relatedInstitutionsValidation">
                                            <Form.Label className={styles.label}>Instituciones afectadas</Form.Label>
                                            <Row>
                                                {
                                                    institutions.map(institution => (
                                                        <Col style={{ paddingRight: "0px" }}>
                                                            <Badge
                                                                style={{ cursor: "pointer" }}
                                                                className={institution.selected ? styles.badgeSelected : styles.badgeDefault}
                                                                onClick={(e) => { handleSelectInstitution(e, institution); }}
                                                            >
                                                            {institution.label}
                                                            </Badge>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Form.Group>
                                    </Row>                    
                                )
                                : 
                                <></>
                            }
                            <Row className="mb-4">
                                <Form.Label className={styles.groupLabel}>Relato conciso de los hechos</Form.Label>
                                <Form.Group as={Col} controlId="relatoValidation">
                                    <Form.Floating>
                                        <Form.Control
                                            id="floatingRelato"
                                            as="textarea"
                                            required={!preCarga}
                                            rows={10}
                                            className={styles.textarea}
                                            placeholder="Descripción concisa de los hechos"
                                            value={fact}
                                            onChange={e => setFact(e.target.value)}
                                        />
                                        <label htmlFor="floatingRelato" className={styles.label}>Descripción concisa de los hechos</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>                    
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-primary" onClick={handleClose}>Cerrar</Button>
                    <Button size="sm" variant="primary" onClick={patch} disabled={loading}>
                        {
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Actualizando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }                    
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    : 
    <></>;

}

export default StatementOfFactEdit;