import React, { useState } from "react";
import { Button, Form, Table, Col, Row } from "react-bootstrap";
import styles from "../../DenunciaForm.module.scss";
import { FiTrash } from "react-icons/fi";
import { AiOutlineFileSearch } from "react-icons/ai";
import WitnessModal from "../../../modals/WitnessModal";

const Testigos = ( { next, previous, witnesses, setWitnesses } ) => {

    const [ preCarga, setPreCarga ] = useState(true);

    const [ validated, setValidated ] = useState(false);
    const [ firstName, setFirstName ] = useState(undefined);
    const [ lastName, setLastName ] = useState(undefined);
    const [ birthDate, setBirthDate ] = useState(undefined);
    const [ documentType, setDocumentType ] = useState("CI");
    const [ documentValue, setDocumentValue ] = useState(undefined);    

    const [ showWitness, setShowWitness ] = useState(false);
    const [ currentWitness, setCurrentWitness ] = useState(undefined);    

    const handleNext = (e) => {
        e.preventDefault();
        next(witnesses);
    }    

    const skip = (e) => {
        e.preventDefault();
        next([]);
    }     

    const handlePrevious = (e) => {
        e.preventDefault();
        previous(witnesses);
    } 

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(true);
            setWitnesses([
                ...witnesses, 
                {
                    firstName,
                    lastName, 
                    birthDate,
                    document: {
                        type: documentType,
                        number: documentValue
                    }
                }
            ]);
            clear();
        }
    };        

    const removeWitness = (e, witness) => {
        e.preventDefault();
        let aux = [];
        for(let current of witnesses){
            if(current !== witness){
                aux.push(current);
            }
        }
        setWitnesses(aux);
    }    

    const clear = () => {
        setFirstName("");
        setLastName("");
        setBirthDate("");
        setDocumentType("CI");
        setDocumentValue("");
    };

    return (
        <>
            <WitnessModal data={currentWitness} show={showWitness} handleClose={() => setShowWitness(false)}/>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Row className="mt-4 mb-4">
                    <Form.Label className={styles.groupLabel}>Datos personales</Form.Label>
                    <Form.Group as={Col} controlId="nameValidation">
                        <Form.Floating>
                            <Form.Control
                                required
                                id="floatingName"
                                type="text" 
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value); }}
                                placeholder="Ingrese el nombre del testigo" 
                                className={styles.input}
                            />
                            <label className={styles.label} htmlFor="floatingName">Nombres</label>
                        </Form.Floating>
                        <Form.Control.Feedback type="invalid">Debes ingresar el nombre del testigo</Form.Control.Feedback>                    
                    </Form.Group>
                    <Form.Group as={Col} controlId="lastNameValidation">
                        <Form.Floating>
                            <Form.Control 
                                required
                                id="floatingLastName"
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value); }}                        
                                className={styles.input}
                                type="text" 
                                placeholder="Ingrese el nombre del testigo" 
                            />
                            <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                        </Form.Floating>                        
                        <Form.Control.Feedback type="invalid">Debes ingresar el apellido del testigo</Form.Control.Feedback>                    
                    </Form.Group>
                    <Form.Group as={Col} controlId="birthDateValidation">
                        <Form.Floating>
                            <Form.Control 
                                id="floatingBirthDate"
                                required={!preCarga}
                                value={birthDate}
                                onChange={(e) => { setBirthDate(e.target.value); }}                        
                                className={styles.input}
                                placeholder="Ingrese la fecha de nacimiento"
                                type="date" 
                            />
                            <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                        </Form.Floating>
                        <Form.Control.Feedback type="invalid">Debes ingresar la fecha de nacimiento</Form.Control.Feedback>                    
                    </Form.Group>
                </Row>
                <Row className="mb-4">
                    <Form.Label className={styles.groupLabel}>Documento de identidad</Form.Label>
                    <Form.Group as={Col} controlId="documentTypeValidation">
                        <Form.Floating>
                            <Form.Control 
                                id="floatingDocumentType" 
                                className={styles.input} 
                                required={!preCarga} 
                                as="select" 
                                value={documentType} 
                                onChange={(e) => {setDocumentType(e.target.value)}}
                            >
                                <option value="CI">Cédula de identidad</option>
                                <option value="PAS">Pasaporte</option>
                            </Form.Control>
                            <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col} controlId="documentValueValidation">
                        <Form.Floating>
                            <Form.Control
                                id="floatingDocumentNumber" 
                                required={!preCarga} 
                                type={documentType === "CI" ? "number" : "text"}
                                value={documentValue} 
                                placeholder="Ingrese el número de documento"
                                className={styles.input}
                                onChange={(e) => { setDocumentValue(e.target.value); }} 
                            />
                            <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                        </Form.Floating>
                    </Form.Group>
                </Row>
                <Row>
                    <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                        <Button bsPrefix={styles.next} type="submit">AGREGAR</Button>
                    </Col>                    
                </Row>
                {
                    (witnesses && witnesses.length > 0) ? (                    
                            <Table className={styles.table} style={{ marginTop: "1rem" }}>
                                <thead>
                                    <th>Nombres y apellidos</th>
                                    <th>Tipo de documento</th>
                                    <th>Número de documento</th>
                                    <th>Fecha de nacimiento</th>
                                    <th>Acciones</th>
                                </thead>
                                <tbody>
                                    {
                                        witnesses.map(witness => (
                                            <tr>
                                                <td>{`${witness.firstName} ${witness.lastName}`}</td>
                                                <td>{witness.document.type}</td>
                                                <td>{witness.document.number}</td>
                                                <td>{witness.birthDate}</td>
                                                <td>
                                                    <AiOutlineFileSearch onClick={(e) => { e.preventDefault(); setCurrentWitness(witness); setShowWitness(true); }} title="Ver" className={styles.icon}/>&nbsp;
                                                    <FiTrash onClick={e => removeWitness(e, witness) } title="Descartar" className={styles.icon}/>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                    )
                    : <></>
                }

                <Row className="mt-4" style={{ display: "flex", borderTop: "1px solid silver", paddingTop: ".7rem" }}>
                    <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                        <Button bsPrefix={styles.next} onClick={handlePrevious} >ANTERIOR</Button>
                    </Col>
                    {
                        witnesses.length > 0 ? (
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={handleNext}>SIGUIENTE</Button>
                            </Col>                    
                        )
                        : 
                        (
                            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={skip}>OMITIR</Button>
                            </Col>                    
                        )
                    }                
                </Row>
            </Form>            
        </>            
    );

}

export default Testigos;