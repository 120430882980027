import React from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import styles from "./WitnessModal.module.css";

const WitnessModal = ({ data, show, handleClose }) => {

    return data ? (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Datos del testigo</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="nameValidation">
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingName"
                                        type="text" 
                                        value={data.firstName}
                                        className={styles.input}
                                    />
                                    <label className={styles.label} htmlFor="floatingName">Nombres</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastNameValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingLastName"
                                        value={data.lastName}
                                        className={styles.input}
                                        type="text" 
                                    />
                                    <label className={styles.label} htmlFor="floatingLastName">Apellidos</label>
                                </Form.Floating>                        
                            </Form.Group>
                            <Form.Group as={Col} controlId="birthDateValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingBirthDate"
                                        value={data.birthDate}
                                        className={styles.input}
                                        type="date" 
                                    />
                                    <label htmlFor="floatingBirthDate" className={styles.label}>Fecha de nacimiento</label>
                                </Form.Floating>
                            </Form.Group>                       
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="documentTypeValidation">
                                <Form.Floating>
                                    <Form.Control 
                                        id="floatingDocumentType" 
                                        className={styles.input} 
                                        as="select" 
                                        value={data.document.type} 
                                    >
                                        <option value="CI">Cédula de identidad</option>
                                        <option value="PAS">Pasaporte</option>
                                        <option value="MAT">Matrícula de la Corte Suprema de Justicia</option>
                                    </Form.Control>
                                    <label htmlFor="floatingDocumentType" className={styles.label}>Tipo de documento</label>
                                </Form.Floating>
                            </Form.Group>
                            <Form.Group as={Col} controlId="documentValueValidation">
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingDocumentNumber" 
                                        type={data.document.type === "CI" ? "number" : "text"}
                                        value={data.document.number} 
                                        className={styles.input}
                                    />
                                    <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                                </Form.Floating>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
    : 
    <></>;

};

export default WitnessModal;