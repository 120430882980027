import React from "react";
import { Pagination, Row, Col } from "react-bootstrap";
import styles from "./CustomPagination.module.scss";

const CustomPagination = ({ page, pageSize, changePageSize, toPage, totalPages }) => {

    const validate = (e) => {
        e.preventDefault();
        changePageSize(e.target.value);
    }
    
    return (
        <div className={styles.pagination}>
            <Pagination style={{ marginBottom: "0" }}>
                <Pagination.First onClick={() => {toPage(1)}}>{"<<"}</Pagination.First>
                <Pagination.Prev disabled={page == 1} onClick={() => { toPage(page - 1) }}>{"<"}</Pagination.Prev>
                <Pagination.Item onClick={() => { toPage(1); }}>{1}</Pagination.Item>
                <Pagination.Item active={true}>{page}</Pagination.Item>
                <Pagination.Item onClick={() => { toPage(totalPages); }}>{totalPages}</Pagination.Item>
                <Pagination.Next onClick={() => { page < totalPages ? toPage(page + 1) : console.log("") }}>{">"}</Pagination.Next>
                <Pagination.Last onClick={() => {toPage(totalPages)}}>{">>"}</Pagination.Last>
            </Pagination>                          
        </div>
    )

}

export default CustomPagination;